import { Button, Form, Modal } from "antd";
import React, { Component } from "react";
import autoBind from "react-autobind";
import { withNamespaces } from "react-i18next";
import withStyles from "react-jss";
import { connect } from "react-redux";
import "../../assets/styles/css/components/preferensi-database.css";
import { styles } from "../../assets/styles/jss/components/modal";
import { redirect } from "../../state/action/auth";
import { openTenantActionn } from "../../state/action/tenant";
import { update } from "../../utility/api/crud";
import {
  ErrorMessage,
  SuccessMessage,
} from "../../utility/functions/publicFunctions";
import FiturDasar from "../preferensi-database/fitur-dasar";
import InfoPerusahaan from "../preferensi-database/info-perusahaan";
import Pembelian from "../preferensi-database/pembelian";
import Penjualan from "../preferensi-database/penjualan";
import Persediaan from "../preferensi-database/persediaan";
import Sidebar from "../../components/sidebar/PreparationSidebar";
import "../../assets/styles/css/vendors/antd.css";
import {
  LapPembelian,
  LapPenjualan,
  persediaan,
  PrefPerusahaan,
  PrefFitur,
} from "../../constants/icons";

class PersiapanAwal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      body: null,
      activeMenu: {
        name: "Info Perusahaan",
        id: 1,
      },
      comp: InfoPerusahaan,
      currentStep: 1,
      steps: [
        {
          tab: "Info Perusahaan",
          key: 1,
        },
        {
          tab: "Fitur Dasar",
          key: 2,
        },
        {
          tab: "Penjualan",
          key: 3,
        },
        {
          tab: "Pembelian",
          key: 4,
        },
        {
          tab: "Persediaan",
          key: 5,
        },
        // {
        //   tab: "Import",
        //   key: 6,
        // },
      ],
    };

    this.menuTabs = [
      {
        tab: props.t("Info Perusahaan"),
        key: 1,
        comp: InfoPerusahaan,
        ic: PrefPerusahaan,
      },
      {
        tab: props.t("Fitur Dasar"),
        key: 2,
        comp: FiturDasar,
        ic: PrefFitur,
      },
      {
        tab: props.t("Penjualan"),
        key: 3,
        comp: Penjualan,
        ic: LapPenjualan,
      },
      {
        tab: props.t("Pembelian"),
        key: 4,
        comp: Pembelian,
        ic: LapPembelian,
      },
      {
        tab: props.t("Persediaan"),
        key: 5,
        comp: Persediaan,
        ic: persediaan,
      },
      // {
      //   tab: "Import",
      //   key: 6,
      //   comp: Import,
      // },
    ];
    autoBind(this);
  }

  static getDerivedStateFromProps(props, state) {
    // if (props.dataCompany !== state.body && !props.visible) {
    // if (props.dataCompany !== state.body && props.visible) {
    //   return {
    //     body: props.dataCompany,
    //   };
    // }

    if (props.visible && state.body !== null) {
      if (props.dataCompany !== state.body) {
        return {
          body: state.body,
        };
      }
    } else {
      return {
        body: props.dataCompany,
      };
    }

    return null;
  }

  nextPrev = (event) => {
    const type = event.target.name;
    const { activeMenu } = this.state;
    let currentStep = activeMenu.id;
    if (type === "next") {
      let nextStep = currentStep;
      if (currentStep === 1) {
        const {
          form: { validateFields },
        } = this.props;
        validateFields(
          [
            "companyname",
            "startdate",
            "fiscalyear",
            "addressline1",
            "addressline2",
            "addressline3",
            "addressline4",
            "businesstype",
          ],
          (errors, values) => {
            if (errors) {
              return;
            }
            nextStep = currentStep + 1;
          }
        );
      } else {
        nextStep = currentStep + 1;
      }

      let activeTab = this.menuTabs.find((tab) => tab.key === nextStep);
      this.setState({
        comp: activeTab.comp,
        activeMenu: {
          name: activeTab.tab,
          id: activeTab.key,
        },
      });
    } else if (type === "prev") {
      let prevStep = currentStep - 1;
      let activeTab = this.menuTabs.find((tab) => tab.key === prevStep);
      this.setState({
        comp: activeTab.comp,
        activeMenu: {
          name: activeTab.tab,
          id: activeTab.key,
        },
      });
    }
  };

  component(e) {
    this.menuTabs.map((x) => {
      if (e === x.key) {
        this.setState({
          comp: x.comp,
          activeMenu: {
            name: x.tab,
            id: e,
          },
        });
      }
      return null;
    });
  }
  onClose = () => {
    this.setState({
      activeMenu: {
        name: "Info Perusahaan",
        id: 1,
      },
      comp: InfoPerusahaan,
    });
    this.props.onCancel();
  };
  onChange(e, parent) {
    const value = e.target.value;
    const name = e.target.name;
    const type = e.target.type;
    const modul = e.target.modul;
    let updated = {
      [name]: value,
    };
    if (type === "features") {
      const { features } = this.state.body.pcompany;
      features[modul] = {
        ...features[modul],
        [name]: value,
      };
      updated = {
        ...features,
      };
    }
    if (parent === "pcurrency") {
      const { pcurrency } = this.state.body;
      if (pcurrency) {
        const { action } = e.target;
        if (action === "delete") {
          let arr = pcurrency.filter((x) => x.currencyname === value[0]);
          updated = {
            pcurrency: [...arr],
          };
        } else {
          updated = {
            pcurrency: [...pcurrency, ...updated.pcurrency],
          };
        }
      }
      this.setState({
        body: {
          ...this.state.body,
          ...updated,
        },
      });
    } else {
      this.setState({
        body: {
          ...this.state.body,
          [parent]: {
            ...this.state.body[parent],
            ...updated,
          },
        },
      });
    }
  }
  onSubmit(e) {
    e.preventDefault();
    const { openTenant } = this.props;
    const { push } = this.props.history;
    const { tenant } = this.props;
    let { body } = this.state;

    let addOnsX = this.state.body.addOns.map((x) => ({
      ...x,
      itemforid: x.itemforid.itemforid,
    }));

    body = {
      ...body,
      addOns: addOnsX,
      pcompany: {
        ...body.pcompany,
        features: JSON.stringify(body.pcompany.features),
        preparation: true,
      },
      simpleDatabase: false,
    };
    const id = tenant.tenantId.dataSourceKey;
    update(id, body, "Preferensi")
      .then((res) => {
        push(`/open=${id}`);
        openTenant({
          id: tenant.tenantId.dataSourceKey,
          isOpened: true,
          isFillPreparation: false,
          data: tenant,
        });
        SuccessMessage();
        this.props.onCancel();
      })
      .catch((error) => {
        ErrorMessage(error);
      });
  }
  render() {
    const { classes, visible, tenant, t } = this.props;
    const { body, activeMenu } = this.state;
    const handle = {
      onChange: this.onChange,
    };
    return (
      <Modal
        // closable={false}
        maskStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
        closable={false}
        destroyOnClose
        centered={true}
        title={t("Persiapan Awal")}
        visible={visible}
        onCancel={this.onClose}
        maskClosable={false}
        className={classes.modal_preference_db}
        wrapClassName={classes.wrap_modal_preference_db}
        width={1000}
        footer={[
          <Button
            size="default"
            shape="round"
            className="btn-kembali"
            name="prev"
            disabled={activeMenu.id === 1 ? true : false}
            onClick={(e) => this.nextPrev(e)}
          >
            {t("Kembali")}
          </Button>,
          activeMenu.id === 5 ? (
            <Button
              size="default"
              shape="round"
              className="btn-selesai"
              onClick={this.onSubmit}
            >
              {t("Selesai")}
            </Button>
          ) : (
            <Button
              name="next"
              size="default"
              shape="round"
              className="btn-selanjutnya"
              onClick={(e) => this.nextPrev(e)}
            >
              {activeMenu.id === 6 ? t("Buka Database") : t("Selanjutnya")}
            </Button>
          ),
        ]}
      >
        <Sidebar
          menuItem={this.menuTabs}
          // onClick={this.component}
          active={this.state.activeMenu.id}
        />
        <main className={classes.content_persiapan_awal}>
          <this.state.comp
            parentState={body}
            handle={handle}
            tenant={tenant}
            form={this.props.form}
          />
        </main>
      </Modal>
    );
  }
}

const mapStateToProps = ({ Tenants }) => ({
  id: Tenants.id,
});
const mapDispatchToProps = (dispatch) => ({
  redirect: (link) => {
    dispatch(redirect(link));
  },
  openTenant: (body) => {
    dispatch(openTenantActionn(body));
  },
});

const connectRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(PersiapanAwal);
const ModalPreferenceDB = Form.create({ name: "persiapan_awal" })(connectRedux);
export default withNamespaces("translations")(
  withStyles(styles)(ModalPreferenceDB)
);
