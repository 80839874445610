/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Icon } from "antd";
import withStyles from "react-jss";
import { styles } from "../../assets/styles/jss/components/sidebar";
import MenuItem from "./item/itemPreparation";
import "../../assets/styles/css/components/preparation-sidebar.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }
  isMobile = () => window.innerWidth <= 768;

  toggleMenu = () =>
    this.setState((prevState) => ({ expanded: !prevState.expanded }));

  renderBurger = () => {
    const { classes } = this.props;
    return (
      <div onClick={this.toggleMenu} className={classes.burgerIcon}>
        <Icon type="double-right" />
      </div>
    );
  };

  onChange(id) {
    return this.props.onClick(id);
  }
  render() {
    const { classes, menuItem } = this.props;
    return (
      <div>
        <a className="s-sidebar__trigger_preparation">
          {/* <Icon type="menu" /> */}
        </a>
        <nav
          className={`${classes.sidebarNavigation} s-sidebar__nav_preparation`}
        >
          <ul>
            {menuItem.map((element) => (
              <MenuItem
                title={element.tab}
                key={element.key}
                active={this.props.active === element.key}
                // onClick={() => this.onChange(element.key)}
                icon={element.ic}
              />
            ))}
          </ul>
        </nav>
      </div>
    );
  }
}
export default withStyles(styles)(Sidebar);
