export const Barang = (startdate) => {
  return {
    body: {
      itemAdjustmentTransaction: [],
      details: [
        {
          itemid: 0,
          personid: 0,
          personname: "",
          personno: "",
          persontype: 0,
          price1: 0,
          priceid: 0,
          sprice1: 0,
        },
        {
          itemid: 0,
          personid: 0,
          personname: "",
          personno: "",
          persontype: 1,
          price1: 0,
          priceid: 0,
          sprice1: 0,
        },
      ],
      unitratio: [],
      master: {
        checkPreferensi: true,
        checkKategori: false,
        categoryid: null,
        cogsglaccnt: "",
        cost: 0,
        costmethod: "",
        defwhs: 1,
        deptid: 1,
        discpc: "",
        displaykah: 0,
        firstparentitem: "",
        forcesn: false,
        goodsintransglaccnt: "",
        iamparent: false,
        indentlevel: 0,
        inventoryglaccnt: "",
        itemdescription: "",
        itemdescription2: "",
        itemid: 0,
        itemno: "",
        itemno_indent: "",
        itemplu: "",
        itemseq: "",
        itemtype: 0,
        itemupc: "",
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
        itemdate: startdate,
        locked_by: "",
        locked_time: "",
        maxpurcprice: "",
        maxsellprice: "",
        minimumqty: 0,
        minpurcprice: "",
        minsellprice: "",
        multisaldoawal: "",
        notes: "",
        onorder: "",
        onsales: "",
        parentitem: "",
        preferedvendor: "",
        projectid: 1,
        purchaseretglaccnt: "",
        quantity: 0,
        ratio2: "",
        ratio3: "",
        reserved1: "",
        reserved10: "",
        reserved11: "",
        reserved12: "",
        reserved13: "",
        reserved14: "",
        reserved15: "",
        reserved16: "",
        reserved17: "",
        reserved18: "",
        reserved19: "",
        reserved2: "",
        reserved20: "",
        reserved3: "",
        reserved4: "",
        reserved5: "",
        reserved6: "",
        reserved7: "",
        reserved8: "",
        reserved9: "",
        salesdiscount: "",
        salesglaccnt: "",
        salesretglaccnt: "",
        snempty: false,
        sntype: 0,
        stdsellprice: "",
        subitem: false,
        suspended: false,
        taxcodes: "",
        tipepersediaan: 0,
        unbilledglaccnt: "",
        unit1: "",
        unit2: "",
        unit3: "",
        unitprice: 0,
        unitprice2: "",
        unitprice2_l2: "",
        unitprice2_l3: "",
        unitprice3: "",
        unitprice3_l2: "",
        unitprice3_l3: "",
        unitprice4: "",
        unitprice4_l2: "",
        unitprice4_l3: "",
        unitprice5: "",
        unitprice5_l2: "",
        unitprice5_l3: "",
        unitprice_l2: "",
        unitprice_l3: "",
        useexp: false,
        usesn: false,
        allusers: true,
        detailuser: [],
        detailbranch: [],
      },
    },
    insert: true,
    judul: "Data Baru",
    dataKategoriBarang: [],
    dataGudang: [],
    dataDepartemen: [],
    dataProject: [],
    dataBarang: [],
    dataPemasok: [],
    dataAkun: [],
    validation: [],
    saldoSaatIni: {
      kts: 0,
      hargaSatuan: 0,
      hargaPokok: 0,
    },
  };
};
