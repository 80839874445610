import * as types from '../../constants';

const initialState = {
  language : "id",
};

export default function Language(state = initialState, action) {
    switch (action.type) {
        case types.LANGUAGE:
            return {
                language: action.language            
            }   
        // case types.RESET:
        //     return initialState   
        default:
            return state;
    }
}