export const initialState = (formatDate, moment) => {
  return {
    visible: false,
    tabPane: "1",
    modalExchangerate: false,
    modalSimpanTransaksi: false,
    modalSalinTransaksi: false,
    errorPage: null,
    validation: [],
    modalPelanggan: false,
    insert: true,
    loadingFetching_pelanggan: false,
    loadingTable_salinTransaksi: false,
    loading: {
      saveExit: false,
      next: false,
      previous: false,
      loadingFetching_pelanggan: false,
      pratinjau: false,
      so: false,
    },
    daftar: {
      pelanggan: [],
    },
    personData: {},
    total: {
      subtotal: 0,
      diskon: 0,
      ppn: 0.1,
      pajak2: 0,
      freight: 0,
      jumlah: 0,
    },
    body: {
      details: [
        {
          brutounitprice: 0,
          closed: false,
          deptid: 0,
          detquoteno: "",
          groupno: "",
          groupseq: 0,
          itemdiscpc: "",
          itemno: "",
          itemtype: "",
          itemovdesc: "",
          itemreserved1: "",
          itemreserved10: "",
          itemreserved11: "",
          itemreserved12: "",
          itemreserved13: "",
          itemreserved14: "",
          itemreserved15: "",
          itemreserved16: "",
          itemreserved17: "",
          itemreserved18: "",
          itemreserved19: "",
          itemreserved2: "",
          itemreserved20: "",
          itemreserved3: "",
          itemreserved4: "",
          itemreserved5: "",
          itemreserved6: "",
          itemreserved7: "",
          itemreserved8: "",
          itemreserved9: "",
          itemunit: "",
          projectid: 0,
          qtyordered: 0,
          qtyship: 0,
          quantity: "",
          quoteid: 0,
          seq: 1,
          taxableamount1: 0,
          taxableamount2: 0,
          taxcodes: "",
          unitprice: 0,
          unitratio: 1,
        },
      ],
      master: {
        approved: false,
        approvedesc: null,
        cashdiscount: 0,
        cashdiscpc: 0,
        checknote: false,
        closed: false,
        customerid: null,
        customeristaxable: false,
        description: "",
        fiscalrate: 0,
        follow: "",
        followup: false,
        urgent: false,
        freight: 0,
        idnext: 0,
        idprev: 0,
        invamount: 0,
        noteid: 0,
        notes: "",
        proceed: "",
        quotedate: moment().format(formatDate),
        quoteid: 0,
        quoteno: "",
        quoteto1: "",
        quoteto2: "",
        quoteto3: "",
        quoteto4: "",
        quoteto5: "",
        rate: 0,
        ratetype: 0,
        roundedtax1amount: 0,
        roundedtax2amount: 0,
        salesmanid: null,
        status: "",
        subtotal: 0,
        tax1amount: 0,
        tax1code: "",
        tax1id: 0,
        tax1rate: 0,
        tax2amount: 0,
        tax2code: "",
        tax2id: 0,
        tax2rate: 0,
        taxableamount1: 0,
        taxableamount2: 0,
        taxableamount3: 0,
        taxableamtcode1: "",
        taxableamtcode2: "",
        taxableamtcode3: "",
        inclusivetax: false,
        templateid: 15,
        userid: 0,
        lastid: 0,
        currencyname: "",
      },
      templatedetails: [],
      attachment: [],
    },
    dataTemplate: [],
    hasChange: false,
  };
};
