import * as types from "../../constants";
const initialState = {
  countInvoice_alert: 0,
  countReqActivation: 0,
};
export default function Admin(state = initialState, action) {
  switch (action.type) {
    case types.INVOICE_ALERT:
      return {
        ...state,
        countInvoice_alert: action.payload,
      };
    case types.REQ_INV_ALERT:
      return {
        ...state,
        countReqActivation: action.payload,
      };
    default:
      return state;
  }
}
