import React, { Component } from "react";
import { styles } from "../../assets/styles/jss/components/preferensi-database";
import withStyles from "react-jss";
import { Checkbox, Radio } from "antd";
import { withNamespaces } from "react-i18next";

class Persediaan extends Component {
  render() {
    const { parentState, handle, t } = this.props;
    return (
      <div>
        <header>
          <desc className="welcome_desc">
            {t(
              "Centang pernyataan berikut agar Easy Cloud menyesuaikan kebutuhan perusahaan Anda."
            )}
          </desc>
        </header>
        <div className="content-preference">
          <form className="form__fitur-dasar">
            <div>
              <div>
                <Checkbox
                  className="field"
                  name="usemultiwhs"
                  checked={parentState.pcompany.features.persediaan.usemultiwhs}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "persediaan",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t("Perusahaan Anda memiliki lebih dari satu gudang.")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  className="field"
                  name="usemultiunit"
                  checked={
                    parentState.pcompany.features.persediaan.usemultiunit
                  }
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "persediaan",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t(
                    "Persediaan yang Anda miliki mempunyai satuan/unit lebih dari 1 (1 Lsn = 12 Pcs, 1 Box = 48 pcs)"
                  )}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  className="field"
                  name="usesn"
                  checked={parentState.pcompany.features.persediaan.usesn}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "persediaan",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t(
                    "Persediaan Anda menggunakan serial number/batch dalam kegiatan transaksi keluar masuk barang."
                  )}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  className="field"
                  name="usejobcost"
                  checked={parentState.pcompany.features.persediaan.usejobcost}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "persediaan",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t("Perusahaan Anda berkaitan dengan Pabrikasi.")}
                </Checkbox>
              </div>
              <div>
                <div>
                  {t("Metode perhitungan biaya persediaan yang digunakan?")}
                </div>
                <div className="layout-2-col">
                  <Radio.Group
                    className="field"
                    name="reserved3"
                    value={parentState.pcompany.reserved3}
                    defaultValue={parentState.pcompany.reserved3}
                    onChange={(e) => handle.onChange(e, "pcompany")}
                  >
                    <Radio key={0} value={"AVERAGE"} disabled>
                      {t("Rata-rata")}
                    </Radio>
                    <Radio key={1} value={"FIFO"}>
                      FIFO (First In First Out)
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withNamespaces("translations")(withStyles(styles)(Persediaan));
