import React, { Component } from "react";
import { styles } from "../../assets/styles/jss/components/preferensi-database";
import withStyles from "react-jss";
import { Checkbox } from "antd";
import { withNamespaces } from "react-i18next";

class Penjualan extends Component {
  render() {
    const { t, parentState, handle } = this.props;
    return (
      <div>
        <header>
          <desc className="welcome_desc">
            {t(
              "Centang pernyataan berikut agar Easy Cloud menyesuaikan kebutuhan perusahaan Anda."
            )}
          </desc>
        </header>
        <div className="content-preference">
          <form className="form__fitur-dasar">
            <div>
              <div>
                <Checkbox
                  className="field"
                  name="commission"
                  checked={parentState.pcompany.features.penjualan.commission}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "penjualan",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t("Perusahaan menghitung dan membayar komisi salesman.")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  className="field"
                  name="useminmaxsellprice"
                  checked={
                    parentState.pcompany.features.penjualan.useminmaxsellprice
                  }
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "penjualan",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t(
                    "Perusahaan anda menggunakan minimun dan maksimum harga penjualan"
                  )}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  className="field"
                  name="showcm"
                  checked={parentState.pcompany.showcm === 1}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked === true ? 1 : null,
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t(
                    "Perusahaan anda meretur berdasarkan Faktur Penjualan yang masih beredar saja"
                  )}
                </Checkbox>
              </div>
              {/* <div>
                <Checkbox className="field" disabled>
                  {t(
                    "Perusahaan Anda mengirimkan barang melalui jasa pengiriman."
                  )}
                </Checkbox>
              </div> */}
              {/* <div>
                <Checkbox
                  className="field"
                  name="uselimit"
                  checked={parentState.pcompany.features.penjualan.uselimit}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "penjualan",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t(
                    "Perusahaan Anda mempunyai syarat dan batas waktu piutang pelanggan."
                  )}
                </Checkbox>
              </div> */}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withNamespaces("translations")(withStyles(styles)(Penjualan));
