import * as types from "../../constants";

const initialState = {
  isvalidlogin: false,
  token: null,
  currentUser: null,
  isAuthenticated: false,
  isLoading: false,
  redirectTo: null,
  isSession: false,
};

export default function Auths(state = initialState, action) {
  let cur;
  switch (action.type) {
    case types.CHANGE_AUTH:
      return {
        ...state,
        isvalidlogin: action.isvalidlogin,
        token: action.token,
      };
    case types.ADD_CURRENT_AUTH:
      return {
        ...state,
        currentUser: action.currentUser,
        isAuthenticated: action.isAuthenticated,
      };
    case types.REDIRECT:
      return {
        ...state,
        redirectTo: action.link,
      };
    case types.SESSION_ENDED:
      return {
        ...state,
        isSession: action.isSession,
      };
    case types.CHANGE_THEME:
      cur = state.currentUser;
      cur.theme = action.theme;
      return {
        ...state,
        currentUser: { ...cur },
      };
    case types.CHANGE_COLOR:
      cur = state.currentUser;
      cur.colormode = action.payload;
      return {
        ...state,
        currentUser: { ...cur },
      };
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}
