export const initialState = (formatDate, moment) => {
  return {
    details: [
      {
        id: 0,
        idpricestandard: 0,
        itemdiscpc: 0,
        olditemdiscpc: "",
        itemno: "",
        itemdescription: "",
        maxpurcprice: 0,
        maxsellprice: 0,
        minpurcprice: 0,
        minsellprice: 0,
        oldminsellprice: 0,
        oldmaxsellprice: 0,
        oldminpurcprice: 0,
        oldmaxpurcprice: 0,
        price1: 0,
        price2: 0,
        price3: 0,
        price4: 0,
        price5: 0,
        seq: 0,
        standardcost: 0,
      },
    ],
    master: {
      description: "",
      effectivedate: moment().format(formatDate),
      id: 0,
      standarddate: moment().format(formatDate),
      standardno: "",
      status: false,
      templateid: 0,
      unittype: 1,
      userid: 0,
      lastid: 0,
    },
    templatedetails: [],
  };
};
