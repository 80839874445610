import * as types from "../../constants";

const initialState = {
  isMobile: false,
};

export default function IsMobile(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_WIDTH:
      return {
        isMobile: action.width <= 1000,
      };
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}
