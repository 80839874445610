export const initialState = (formatDate, moment) => {
	return {
		modalSimpanTransaksi: false,
		modalSalinTransaksi: false,
		hasChange: false,
		dataTemplate: [],
		dataTarikan: [],
		insert: true,
		errorPage: null,
		validation: [],
		personData: {},
		loadingTable_salinTransaksi: false,
		daftar: {
			pelanggan: [],
			akunPenjualan: [],
			akunBiaya: [],
		},
		total: {
			subtotal: 0,
			diskon: 0,
			ppn: 0.1,
			pajak2: 0,
			freight: 0,
			jumlah: 0,
		},
		body: {
			sndata: [],
			details: [
				{
					arinvoiceid: 0,
					salesInvoice: {},
					paymentid: 0,
					seq: 0,
					invoiceno: "",
					invoicedate: "",
					duedate: "",
					discount: 0,
					pph23amount: 0,
					invoiceamount: 0,
					currencyid: 1,
					currencyname: "",
					owing: 0,
					owingdc: 0,
					strowing: "",
					paymentamount: 0,
					description: "",
					taxpph23: 0,
					pph23rate: 0,
					pph23number: "",
					pph23fiscalrate: 0,
					termname: "",
					recdisc: 0,
					discdate: null,
					disctakenamount: null,
					pay: false,
					purchaseorderno: "",
					key: 0,
				},
			],
			master: {
				resi: "",
				overbudget: true,
				approved: false,
				approvedesc: null,
				araccount: null,
				consid: 0,
				cashdiscount: 0,
				cashdiscpc: "",
				cashsales: true,
				checknote: true,
				chequedate: "",
				chequeno: "",
				createddate: moment().format(formatDate),
				currencyid: 1,
				currencyname: "",
				customerid: null,
				customeristaxable: false,
				customername: "",
				customerno: "",
				deliveryorder: false,
				depositto: 0,
				description: "",
				dp: 0,
				dpaccount: "",
				dpused: 0,
				duedate: moment().format(formatDate),
				fiscalrate: 0,
				fob: "",
				follow: "",
				followup: false,
				urgent: false,
				freight: 0,
				freightaccnt: "",
				getfromdo: false,
				getfromother: true,
				getfromquote: true,
				getfromso: false,
				glhistid: 0,
				glperiod: 0,
				glyear: 0,
				idnext: 0,
				idprev: 0,
				imported: false,
				inclusivetax: false,
				invfromsr: true,
				consamount: 0,
				consdate: moment().format(formatDate),
				consno: "",
				invoicetype: 0,
				isdp: 0,
				istaxpayment: true,
				link01: "",
				link02: "",
				link03: "",
				link04: "",
				link05: "",
				link06: "",
				link07: "",
				link08: "",
				link09: "",
				link10: "",
				link11: "",
				link12: "",
				link13: "",
				link14: "",
				link15: "",
				link16: "",
				link17: "",
				link18: "",
				link19: "",
				link20: "",
				locked_by: "",
				locked_time: "",
				noteid: 0,
				owing: 0,
				owingdc: 0,
				paidamount: 0,
				parentarinvid: 0,
				payment: 0,
				posted: true,
				printed: false,
				purchaseorderno: "",
				rate: 0,
				reconciled: true,
				reconcileid: 0,
				reportedtax1: true,
				reportedtax2: true,
				returnamount: 0,
				roundedtax1amount: 0,
				roundedtax2amount: 0,
				salesmanid: "",
				shipdate: null,
				shipid: "",
				shipped: true,
				shipto1: "",
				shipto2: "",
				shipto3: "",
				shipto4: "",
				shipto5: "",
				status: "",
				subtotal: "",
				tax1amount: 0,
				tax1code: "",
				tax1id: 0,
				tax1rate: 0,
				tax2amount: 0,
				tax2code: "",
				tax2id: 0,
				tax2rate: 0,
				taxableamount1: 0,
				taxableamount2: 0,
				taxableamount3: 0,
				taxableamtcode1: "",
				taxableamtcode2: "",
				taxableamtcode3: "",
				taxdate: null,
				taxformcode: "",
				taxformnumber: "",
				templateid: 23,
				termdiscount: 0,
				termid: "",
				transactionid: 0,
				userid: 0,
				usesn: true,
				warehouseid: 1,
				lastid: 0,
				shiptoData: "",
				recdetid: 0,
			},
			templatedetails: [],
			attachment: [],
		},
	};
};
