import { request } from "./api";
import { API_BASE_URL } from "../../constants";
import { getApi } from "../../utility/functions/publicFunctions";
// import { ListTipePelanggan } from "constants/comps";

export function getGLByNo(body) {
  const { tenantId, id, form } = body;
  let tipe = getApi(form, "readNo");
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
    method: "GET",
  });
}

export function getJumlahDet(body) {
  const { tenantId, form, bodyRequest } = body;
  let tipe = getApi(form, "getJumItem");
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}`,
    method: "POST",
    body: JSON.stringify(bodyRequest),
  });
}
export function getProvince() {
  return request({
    url: `${API_BASE_URL}/province`,
    method: "GET",
  });
}
export function getCity(idProvince) {
  return request({
    url: `${API_BASE_URL}/city?idprovinsi=${idProvince}`,
    method: "GET",
  });
}
export function grafikHutangTeratas(params) {
  const { tenantId } = params;
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/dashboard-graphic-arowing`,
    method: "GET",
  });
}
export function grafikPiutangTeratas(params) {
  const { tenantId } = params;
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/dashboard-graphic-apowing`,
    method: "GET",
  });
}

export function grafikBarangTerlaris(params) {
  const { tenantId } = params;
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/dashboard-graphic-items`,

    method: "GET",
  });
}
export function grafikCashFlow(params) {
  const { tenantId } = params;
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/dashboard-graphic-cash-flow`,
    method: "GET",
  });
}

export function grafikLossGain(params) {
  const { tenantId } = params;
  return request({
    url:
      API_BASE_URL +
      `/tenant/${tenantId}/master/dashboard-graphic-gain-and-loss`,
    method: "GET",
  });
}

export function grafikSalesman(params) {
  const { tenantId } = params;
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/dashboard-graphic-salesman`,
    method: "GET",
  });
}

export function paramGrafik(params) {
  const { tenantId, path } = params;
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/${path}`,
    method: "GET",
  });
}
export function filterGrafik(params) {
  const { tenantId, path, body } = params;
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/${path}`,
    method: "PUT",
    body: JSON.stringify(body),
  });
}
export function getReminder(params) {
  const { tenantId } = params;
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/dashboard-reminder`,
    method: "GET",
  });
}
export function getListChart(params) {
  const { tenantId } = params;
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/dashboard-all-graphic`,
    method: "GET",
  });
}
export function delChart(params) {
  const { body, tenantId } = params;
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/dashboard-graphic-showed`,
    method: "PUT",
    body: JSON.stringify(body),
  });
}
export function addChart(params) {
  const { tenantId, body } = params;
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/dashboard-graphic-added`,
    method: "PUT",
    body: JSON.stringify(body),
  });
}

export function getGlType({ tenantId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/glaccount-type`,
    method: "GET",
  });
}

export function getBranchUser({ tenantId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/user-transcode`,
    method: "GET",
  });
}

export function getUserByBranch({ tenantId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/user-by-branch`,
    method: "GET",
  });
}

export function BranchUser({ tenantId, body }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/user-transcode`,
    method: "PUT",
    body: JSON.stringify(body),
  });
}
export function CheckingNoteSearch({ tenantId, tipe }) {
  return request({
    url:
      API_BASE_URL +
      `/tenant/${tenantId}/master/checking-note-search?type=${tipe}
    `,
    method: "GET",
  });
}
export function CheckingNoteType({ tenantId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/checking-note-type-trans`,
    method: "GET",
  });
}

export function addApproval({ tenantId, type, id }) {
  return request({
    url:
      API_BASE_URL +
      `/tenant/${tenantId}/master/checking-note-detail/${id}?type=${type}`,
    method: "GET",
  });
}
export function Approved({ tenantId, id }) {
  return request({
    url:
      API_BASE_URL +
      `/tenant/${tenantId}/master/checking-note-app-processed/${id}`,
    method: "GET",
  });
}
export function getFormAuthorization({ tenantId, id }) {
  return request({
    url:
      API_BASE_URL +
      `/tenant/${tenantId}/master/authorization-by-transtype/${id}`,
  });
}

export function getFormulaProduk({ tenantId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/bom-choose-wo`,
    method: "GET",
  });
}
export function getDetailWo({ tenantId, woId, woDate }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/work-order-by-bom/${woId}?wodate=${woDate}`,
    method: "GET",
  });
}
export function getSubDetailWo({ tenantId, id, woDate, tipe }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/bom-itemmat-dlabordet?date=${woDate}&tipe=${tipe}&nomor=${id}`,
    method: "GET",
  });
}

export function refreshWoCost({ tenantId, wodate, iditem, idbiaya, quantity }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/work-order-refresh-cost?wodate=${wodate}&iditem=${iditem}&idbiaya=${idbiaya}&quantity=${quantity}`,
    method: "GET",
  });
}
export function refreshWoCostId({ tenantId, id, wodate, tipe, quantity }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/work-order-refresh-cost/${id}?wodate=${wodate}&tipe=${tipe}&quantity=${quantity}`,
    method: "GET",
  });
}
export function WoDiprosesOleh({ tenantId, id }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/work-order-processed-by/${id}`,
    method: "GET",
  });
}

export function getWObyPO({
  tenantId,
  id,
  qtytaken,
  qty,
  unitratio,
  wono,
  wodesc,
}) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/work-order-by-po/${id}?qtytaken=${qtytaken}&qty=${qty}&unitratio=${unitratio}&wono=${wono}&wodesc=${wodesc}`,
    method: "GET",
  });
}

export function getPesananWO({ tenantId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/work-order-btn-sales-order`,
    method: "GET",
  });
}

export function chooseSOfromWO({ soid, tenantId, sono, wodate }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/work-order-choose-sales-order/${soid}?sono=${sono}&wodate=${wodate}`,
    method: "GET",
  });
}

export function getProductResult({ tenantId, id }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/product-result-by-product/${id}`,
    method: "GET",
  });
}
export function getProductReversal({ tenantId, id }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/product-result-by-reversal/${id}`,
    method: "GET",
  });
}
export function getPeriodEndProduction({ tenantId, month, year }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/period-end-prod?bulan=${month}&tahun=${year}`,
    method: "GET",
  });
}
export function checkEndPeriod({ tenantId, month, year }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/check-period-end-prod?bulan=${month}&tahun=${year}`,
    method: "GET",
  });
}
export function uploadLogoPreference({ tenantId, body }) {
  let datas = new FormData();
  for (const name in body) {
    datas.append(name, body[name]);
  }
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/storage/images`,
    method: "POST",
    body: datas,
    type: "multipart/form-data",
  });
}
export function getLogoPreference({ tenantId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/storage/images`,
    method: "GET",
  });
}
export function deleteLogoPreference({ tenantId, id }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/storage/images/${id}`,
    method: "DELETE",
  });
}
export function RequestCNT({ tenantId, body }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/connect-cnt`,
    method: "POST",
    body: JSON.stringify(body),
  });
}

export function GetStatusCNT({ tenantId, personid }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/connect-cnt?personid=${personid}`,
    method: "GET",
  });
}

export function UpdateReqCNT({ tenantId, body }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/connect-cnt`,
    method: "PUT",
    body: JSON.stringify(body),
  });
}
export function GetNotification(source) {
  return request({
    url: `${API_BASE_URL}/notification-cnt?dsSource=${source}`,
    method: "GET",
  });
}
export function GetDetailConfirmation({ tenantId, id }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/confirmation-cnt/${id}`,
    method: "GET",
  });
}
export function ConfirmReqCNT({ tenantId, body }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/confirmation-cnt`,
    method: "PUT",
    body: JSON.stringify(body),
  });
}
export function DisconecCNT({ tenantId, personId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/connect-cnt/${personId}`,
    method: "DELETE",
  });
}
export function TransactionCNT({ tenantId, idCNT }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/cnt/${idCNT}`,
    method: "GET",
  });
}
export function MasterTransactionCNT({ tenantId, idPerson }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/cnt?customerId=${idPerson}`,
    method: "GET",
  });
}
export function MappingCNTItem({ tenantId, body }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/cnt-item`,
    method: "POST",
    body: JSON.stringify(body),
  });
}
export function getListCNT({ tenantId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/cnt/vendor`,
    method: "GET",
  });
}
export function getDetailStatusCNT({ tenantId, cntno }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/cnt-detail?CNTNo=${cntno}`,
    method: "GET",
  });
}
export function getItemDetailCNT({ tenantId, id, dsSource, typeTransaction }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/cnt-detail/items?id=${id}&dsSource=${dsSource}&typeTransaction=${typeTransaction}`,
    method: "GET",
  });
}
export function getListCourier({ tenantId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/courier`,
    method: "GET",
  });
}
export function getStatusTracking({ tenantId, noresi, shipid }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/resi?shipid=${shipid}&noresi=${noresi}`,
    method: "GET",
  });
}
export function getTypeFormEmail({ tenantId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/email-type`,
    method: "GET",
  });
}
export function getVariablebyType({ tenantId, id }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/email-variable/${id}`,
    method: "GET",
  });
}
export function CancelProcessCNT({ tenantId, codeCNT }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/cancel-cnt?codeCNT=${codeCNT}`,
    method: "DELETE",
  });
}

export function GetHeaderEmail({ tenantId, id }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/email-format?typeid=${id}`,
    method: "GET",
  });
}

export function ResendTokenCNT({ tenantId, id }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/resend-token-cnt?rowId=${id}`,
    method: "GET",
  });
}

export function RefreshTokenFCM({ body }) {
  return request({
    url: `${API_BASE_URL}/user/update-token-fcm`,
    method: "POST",
    body: JSON.stringify(body),
  });
}

export function RevokeFCM(id) {
  return request({
    url: `${API_BASE_URL}/user/revoke-token-fcm/${id}`,
    method: "DELETE",
  });
}

export function GetContact({ tenantId, page, size, search }) {
  let path = search ? `personName:${search}` : "";
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/person-data-cnt?page=${page}&size=${size}&search=${path}`,
    method: "GET",
  });
}

export function SendMessage({ tenantId, body }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/send-message`,
    method: "POST",
    body: JSON.stringify(body),
  });
}

export function getChatRoom({ tenantId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/chat-room`,
    method: "GET",
  });
}
export function getRoom({ tenantId, group }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/chat?groupMessage=${group}`,
    method: "GET",
  });
}
export function findChatRoom({ tenantId, personId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/find-chat-room?personId=${personId}`,
    method: "GET",
  });
}
export function uploadAttachChat({ tenantId, body }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/send-image`,
    method: "POST",
    body: body,
    type: "multipart/form-data",
  });
}

export function getCNTAttach({ tenantId, dsTarget, offset, limit }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/cnt/cnt-data?dsTarget=${dsTarget}&page=${offset}&size=${limit}`,
    method: "GET",
  });
}

export function getHandleCNTAttach({ tenantId, cntcode }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/last-process?CNTNo=${cntcode}`,
    method: "GET",
  });
}

export function getDirectAttachCNT({ tenantId, source, cntno, personId }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/insert-cnt?codeCNT=${cntno}&dsTarget=${source}&personId=${personId}`,
    method: "GET",
  });
}
export function getDetailItemPriceStandart({
  tenantId,
  date,
  itemid,
  unitType,
}) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/price-standard-detail-items?effectivedate=${date}&itemid=${itemid}&unitlevel=${unitType}`,
    method: "GET",
  });
}
// new
export function getPrizeItem({ tenantId, date, itemid, personid, unit }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/price-standard-item?effectivedate=${date}&itemid=${itemid}&personid=${personid}&unit=${unit}`,
    method: "GET",
  });
}
// new
export function getPrizeItems({ tenantId, date, itemid, personid }) {
  return request({
    url: `${API_BASE_URL}/tenant/${tenantId}/master/price-standard-items?effectivedate=${date}&itemid=${itemid}&personid=${personid}`,
    method: "GET",
  });
}
