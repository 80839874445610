export const initialState = (formatDate, moment) => {
  return {
    NotifAlert: {
      visible: false,
      title: null,
      description: null,
      cancelText: null,
      okText: null,
      onSubmit: null,
    },
    errorPage: null,
    visible: false,
    modalSimpanTransaksi: false,
    modalSalinTransaksi: false,
    loadingTable_salinTransaksi: false,
    loading: {
      previous: false,
      saveExit: false,
      next: false,
      pratinjau: false,
    },
    tabPane: "1",
    validation: [],
    daftar: {
      wo: [],
    },
    body: {
      details: [
        {
          id: 0,
          itemhistid: 0,
          itemno: "",
          itemreserved1: "",
          itemreserved10: "",
          itemreserved2: "",
          itemreserved3: "",
          itemreserved4: "",
          itemreserved5: "",
          itemreserved6: "",
          itemreserved7: "",
          itemreserved8: "",
          temreserved9: "",
          matrlsid: 0,
          notes: "",
          qtycontrol: 0,
          quantity: 0,
          seq: 0,
          unit: "",
          unitratio: 1,
          warehouseid: 0,
          wodetid: 0,
        },
      ],
      master: {
        approved: false,
        branchcodeid: 0,
        checknote: false,
        description: "",
        follow: "",
        followup: false,
        glhistid: 0,
        id: 0,
        imported: 0,
        importedtransactionid: 0,
        noteid: 0,
        releasedate: moment().format(formatDate),
        releaseno: "",
        idnext: 0,
        idprev: 0,
        reserved1: "",
        reserved2: "",
        reserved3: "",
        reserved4: "",
        templateid: 0,
        transactionid: 0,
        userid: 0,
        warehouseid: 0,
        wipaccount: "",
        woid: null,
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
      },
      wodetails: [
        {
          nojob: "",
          itemno: "",
          jobdescription: "",
          quantity: "",
          unit: "",
        },
      ],
      templatedetails: [],
      attachment: [],
    },
    insert: true,
    judul: "Data Baru",
    dataTemplate: [],
    formatDate,
    hasChange: false,
    cellSelected: { onidx: 0, onrowIdx: 0 },
  };
};
