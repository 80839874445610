/* eslint-disable eqeqeq */
import { notification, Modal, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import * as menu from "../../constants/menu";
import { confirmAlert as alertDialog } from "react-confirm-alert";
import Alert from "../../components/modal/notifikasi/alert";
import Moment from "moment";
import { extendMoment } from "moment-range";
import store from "../../store";
import { Checkbox } from "antd";
import {
  ACCESS_TOKEN,
  BULAN,
  NILAI_ASAL,
  PENYESUAIAN_HARGA,
  TOOLS_PEMBULATAN,
} from "../../constants/index";
import { transform, isEqual, isObject } from "lodash";
const momentRange = extendMoment(Moment);
const { confirm } = Modal;

export function debounce(callback, delay) {
  let timeout;
  return function () {
    {
      clearTimeout(timeout);
      timeout = setTimeout(callback, delay);
    }
  };
}

export function searchByKey(value, object) {
  let obj = Object.keys(object);
  let result = false;

  for (let x = 0; x < obj.length; x++) {
    if (value === obj[x]) {
      result = true;
      break;
    }
  }

  return result;
}

export function calc(keys) {
  let rslt = 0;

  keys.forEach((yy, idxkeys) => {
    if (!isNaN(Number(yy))) {
      keys[idxkeys] = Number(keys[idxkeys]);
    }
  });

  const perkalian = (keys) => {
    keys.forEach((xx, idx) => {
      let tempIdx = idx - 1;

      if (xx === "*") {
        rslt = 0;
        rslt = keys[idx - 1] * keys[idx + 1];
        keys.splice(idx - 1, 3);
        keys.splice(tempIdx, 0, rslt);
      } else if (xx === "/") {
        rslt = 0;
        rslt = keys[idx - 1] / keys[idx + 1];
        keys.splice(idx - 1, 3);
        keys.splice(tempIdx, 0, rslt);
      }
    });
  };

  keys.forEach((xx, idx) => {
    if (xx === "*" || "/") {
      perkalian(keys);
    }
  });

  keys.forEach((xx, idx) => {
    let tempIdx = idx - 1;

    if (xx === "+") {
      rslt = 0;
      rslt = keys[idx - 1] + keys[idx + 1];
      keys.splice(idx - 1, 3);
      keys.splice(tempIdx, 0, rslt);
    } else if (xx === "-") {
      rslt = 0;
      rslt = keys[idx - 1] - keys[idx + 1];
      keys.splice(idx - 1, 3);
      keys.splice(tempIdx, 0, rslt);
    }
  });

  if (keys.length !== 1) calc(keys);

  return keys;
}

export function checkIf(condValue, xrow) {
  let value = false;
  let ifBracket1 = -1;
  let ifBracket2 = -1;
  let ifValue = [...condValue];
  let cond = "";

  ifValue[ifValue.length - 1] = ifValue[ifValue.length - 1].substring(
    0,
    ifValue[ifValue.length - 1].length - 1
  );
  ifValue.splice(0, 2);
  ifValue.forEach((yy, idxkeys) => {
    if (!isNaN(Number(yy))) {
      ifValue[idxkeys] = Number(ifValue[idxkeys]);
    }
    if (yy === "(") {
      ifBracket1 = idxkeys;
    }
    if (yy === ")") {
      ifBracket2 = idxkeys;
    }
    if (ifBracket1 > -1 && ifBracket2 > -1) {
      ifValue = ifValue.splice(ifBracket1, ifBracket2);
    }
  });

  ifValue.forEach((xx, idx) => {
    if (xx === ">") {
      if (ifValue[idx - 1] > ifValue[idx + 1]) value = true;
    } else if (xx === "<") {
      if (ifValue[idx - 1] < ifValue[idx + 1]) value = true;
    } else if (xx === "=") {
      if (ifValue[idx - 1] === ifValue[idx + 1]) value = true;
    } else if (ifValue[idx + 1] === "=") {
      if (xx === "!") {
        if (ifValue[idx - 1] !== ifValue[idx + 1]) value = true;
      } else if (xx === ">") {
        if (ifValue[idx - 1] <= ifValue[idx + 1]) value = true;
      } else if (xx === "<") {
        if (ifValue[idx - 1] >= ifValue[idx + 1]) value = true;
      }
    }
  });

  return value;
}

export function ifFunction(keys, xrow) {
  let tempKeys = [...keys];
  let indexKoma = [-1];
  let tempCond = [];
  let result;

  for (let xx = 0; xx < tempKeys.length; xx++) {
    if (tempKeys[xx].length > 1) {
      let help = tempKeys[xx].split("");
      if (help[help.length - 1] === ",") {
        indexKoma.push(xx);
      }
    }
  }
  indexKoma.sort((a, b) => {
    return b - a;
  });
  indexKoma.forEach((xx) => {
    tempCond.push(tempKeys.splice(xx + 1));
  });
  tempCond.reverse();
  let i = 0;
  let tc = tempCond.length;
  for (; i < tc; ) {
    if (tempCond[i][0] === "if") {
      if (checkIf(tempCond[i], xrow)) {
        i += 1;
      } else {
        if (tempCond[i + 1][0] === "if") {
          i += 4;
        } else {
          i += 2;
        }
      }
    } else {
      result = tempCond[i];
      break;
    }
  }

  let help = 0;
  result.forEach((rs, idx) => {
    if (rs === ")") {
      help += 1;
    }
    if (rs[rs.length - 1] === ",") {
      result[idx] = rs.substring(0, rs.length - 1);
    }
  });

  if (help > 0) {
    result.splice(1, help);
  }

  return result;
}

export function bracketCount(bracketIdx1, bracketIdx2, keys, xrow) {
  bracketIdx1.sort(function (a, b) {
    return b - a;
  });

  let inBracket = [];
  let inBracketRslt = "";
  for (let xx = 0; xx < bracketIdx1.length; xx++) {
    for (let yy = bracketIdx1[xx]; yy <= bracketIdx2[xx]; yy++) {
      if (keys[yy] !== undefined) inBracket.push(keys[yy]);
    }
    inBracketRslt = calc(inBracket.slice(1, -1));
    /** hasil inBracketRslt masih array */
    keys.splice(bracketIdx1[xx], inBracket.length, inBracketRslt);
    inBracket = [];
  }

  return keys;
}

export function columnCount(rows, details, useddetails) {
  let { filters, filterSelect } = details;
  let row = [...rows];

  row.forEach((xrow, idxrow) => {
    if (filters) {
      let keys = filters.split(" ");
      let hasil = "";

      useddetails.forEach((det) => {
        keys.forEach((xx, idx) => {
          if (xx[xx.length - 1] === ",") {
            let tess = keys[idx];
            tess = tess.substring(0, tess.length - 1);
            if (det.field_alias_ind.replace(" ", "") === tess && isNaN(xx)) {
              keys[idx] = det.key;
              if (searchByKey(keys[idx], xrow)) {
                keys[idx] = xrow[keys[idx]];
              }
              keys[idx] = `${keys[idx]},`;
            }
          }

          if (det.field_alias_ind.replace(" ", "") === xx && isNaN(xx)) {
            keys[idx] = det.key;
            if (searchByKey(keys[idx], xrow)) {
              keys[idx] = xrow[keys[idx]];
            }
          }
        });
      });

      let bracketIdx1 = [];
      let bracketIdx2 = [];

      if (keys[0] === "if") {
        keys = ifFunction(keys, xrow);
        // keys[keys.length - 1] = keys[keys.length - 1].substring(
        //   0,
        //   keys[keys.length - 1].length - 1
        // );
      }

      keys.forEach((xx, idx) => {
        if (xx === "(") {
          bracketIdx1.push(idx);
        } else if (xx === ")") {
          bracketIdx2.push(idx);
        }

        if (bracketIdx1.length > 0 && bracketIdx2.length > 0) {
          keys = bracketCount(bracketIdx1, bracketIdx2, keys, xrow);
        }
      });

      hasil = calc(keys);

      /** Filter */
      const filterFunction = (a, cond, b) => {
        let val1 = Number(a);
        let val2 = Number(b);
        let hasilFilter = "filtered";

        if (cond === "=") {
          if (val1 === val2) hasilFilter = val1;
        } else if (cond === "<>") {
          if (val1 !== val2) hasilFilter = val1;
        } else if (cond === "<") {
          if (val1 < val2) hasilFilter = val1;
        } else if (cond === "<=") {
          if (val1 <= val2) hasilFilter = val1;
        } else if (cond === ">") {
          if (val1 > val2) hasilFilter = val1;
        } else if (cond === ">=") {
          if (val1 >= val2) hasilFilter = val1;
        }

        return hasilFilter;
      };

      if (filterSelect && filterSelect !== "all") {
        let filterSelected = filterSelect.split("|");
        if (filterSelected[0] === "between") {
          if (
            hasil >= Number(filterSelected[1]) &&
            hasil <= Number(filterSelected[2])
          ) {
            hasil = hasil;
          } else {
            hasil = "filtered";
          }
        } else {
          hasil = filterFunction(hasil, filterSelected[0], filterSelected[1]);
        }
      }

      if (hasil === "filtered") {
        row[idxrow] = "";
      } else {
        row[idxrow] = {
          ...row[idxrow],
          [details.key]: Array.isArray(hasil) ? hasil[0] : hasil,
        };
      }
    } else {
      row[idxrow] = {
        ...row[idxrow],
        [details.key]: "",
      };
    }
  });

  return row;
}

export function pathSearch(state, formatDate) {
  const { search, sidebar } = state;
  const {
    filterTgl,
    startDate,
    endDate,
    filterTglEfektif,
    endDateEfektif,
    startDateEfektif,
  } = sidebar;
  let path = "";
  for (let param in search) {
    if (
      ["transtype", "status", "reconcile", "cekKosong"].some(
        (type) => type === param
      ) &&
      search[param] !== "" &&
      search[param].length === 0
    ) {
      search[param] = "";
    }
  }

  for (let param in search) {
    if (
      ([
        "standarddate",
        "transdate",
        "quotedate",
        "sodate",
        "invoicedate",
        "paymentdate",
        "reqdate",
        "podate",
        "consdate",
      ].some((type) => type === param) &&
        filterTgl) ||
      (["effectivedate"].some((type) => type === param) && filterTglEfektif)
    ) {
      let firstDate = startDate;
      let secondDate = endDate;
      if (param === "effectivedate") {
        firstDate = startDateEfektif;
        secondDate = endDateEfektif;
      }
      path += `${param}$${
        firstDate ? moment(firstDate, formatDate).format("YYYY-MM-DD") : ""
      }_${
        secondDate ? moment(secondDate, formatDate).format("YYYY-MM-DD") : ""
      },`;
    } else if (
      ["transtype", "status", "reconcile", "cekKosong"].some(
        (type) => type === param
      ) &&
      search[param]
    ) {
      path += `${param}@${search[param].join("_")},`;
    } else if (
      ["noteid", "checknote", "approved", "followup", "urgent"].some(
        (type) => type === param
      ) &&
      search[param] !== null
    ) {
      path += `${param}:${search[param]},`;
    } else if (["branchid", "userid", "id"].some((type) => type === param)) {
      if (search[param] !== undefined && search[param] !== null)
        path += `${param}:_${search[param]},`;
    } else if (
      ["currencyid", "customerid", "vendorid", "customertypeid"].some(
        (type) => type === param
      )
    ) {
      if (search[param] !== undefined && search[param] !== null)
        path += `${param}:${search[param]},`;
    } else if (search[param]) {
      path += `${param}:${search[param]},`;
    }
  }
  path = (path.length > 0 && path.substring(0, path.length - 1)) || "";
  return path;
}

export function AuditChange(e, sidebar, bukuBesar) {
  let value = null;
  const { name, selfValue, type } = e.target;
  const { auditType } = sidebar;
  const parrentObj = sidebar[type];

  let data = auditType.find((item) => item.name === type); // data parrent
  let positifName = data.child.find((opt) => opt.value === 1).name; // nama atas
  let negatifName = data.child.find((opt) => opt.value === 0).name; // nama bawah

  let positifValue = parrentObj[positifName]; // nilai disetujiu
  let negatifValue = parrentObj[negatifName]; // nilai belum di setujui

  if (name === positifName && selfValue) value = "1";
  if (name === negatifName && selfValue) value = "0";

  if (name === positifName && negatifValue) value = null;
  if (name === negatifName && positifValue) value = null;

  if (positifValue && negatifValue) {
    if (name === positifName) {
      value = "0";
    } else {
      value = "1";
    }
  }

  if (bukuBesar && value !== null) {
    return value === "1" ? true : false;
  } else {
    return value;
  }
}

export function makeComponent(comp1, comp2, listOnly = false) {
  if (comp2.type === undefined || listOnly === true) {
    return [
      {
        title: "Daftar",
        content: comp1,
        key: comp1.props.idkey,
        closable: false,
      },
    ];
  }
  return [
    {
      title: "Daftar",
      content: comp1,
      key: comp1.props.idkey,
      closable: false,
    },
    {
      title: comp2.props.title ? comp2.props.title : "Data Baru",
      content: comp2,
      key: comp2.props.idkey,
    },
  ];
}

export function GetComponent(anak) {
  let AllMenu = [
    ...menu.AllMenu,
    ...menu.MasterData,
    ...menu.BukuBesarNavbar,
    ...menu.Laporan,
    menu.BukuBank,
    menu.RiwayatAkun,
    menu.ReportView,
    menu.TransaksiBerulang,
  ];
  let hasil = [];
  for (let i = 0; i < AllMenu.length; i++) {
    if (AllMenu[i].caption === anak) {
      hasil = { General: AllMenu[i].general, List: AllMenu[i].list };
    }
  }
  return hasil;
}

export function searchArray(nameKey, myArray) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i].key === nameKey) {
      return myArray[i];
    }
  }
}

export function findParent(item, Draft) {
  let form;
  let array;
  let hasil = "";
  let parent = false;
  let anak = 0;

  Object.size = (obj) => {
    let size = 0;
    let key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  if (Draft[item] !== undefined) {
    let xxx = Draft[item];
    let cntHasChange = 0;

    for (const key in xxx) {
      const obj = xxx[key];
      if (obj.hasChange === true) {
        cntHasChange += 1;
      }
    }

    if (cntHasChange > 0) {
      parent = true;
    }

    // parent = true;

    anak = Object.size(Draft[item]);
  }
  for (form in Draft) {
    array = Draft[form];
    if (array[item] !== undefined && array[item].hasChange === true) {
      hasil = form;
    }
  }
  return { parent, anak, hasil };
}

export function getApi(induk, tipe) {
  return menu[induk][tipe];
}

export function makekey() {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 15; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function search(nameKey, valueKey, myArray) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i][nameKey] === valueKey) {
      return { ...myArray[i], urut: i };
    }
  }
}

export function searchDuplicate(nameKey, valueKey, myArray) {
  let count = 0;
  let result = [];
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i][nameKey] === valueKey) {
      count += 1;
      result.push({ ...myArray[i], urut: i });
    }
  }

  return count > 1 ? result : undefined;
}

export function searchDuplicate2(nameKey, valueKey, myArray) {
  let result = [];
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i][nameKey] === valueKey) {
      result.push({ ...myArray[i], urut: i });
    }
  }

  return result;
}

export function getSpan(data, index, forspan) {
  let rtrn;
  data.forEach((xx) => {
    if (index === xx.firstIndex && !forspan) {
      rtrn = xx.firstIndex;
    }

    if (index === xx.firstIndex && forspan) {
      rtrn = xx.length;
    }
  });

  return rtrn;
}

export function addChildren(length, idx, arr) {
  let start = length * idx;
  let dataChild = [];
  let count = 0;

  for (let i = start; i < arr.length; i++) {
    dataChild.push({
      ...arr[i],
    });
    count += 1;
    if (count === length) {
      break;
    }
  }
  return dataChild;
}

export function getValueField(
  nameKey,
  valueKey,
  myArray,
  showField,
  extraKey = null
) {
  let arrClean = myArray.filter(
    (x) => x.__metaData === undefined && x.isFooter === undefined
  );
  for (var i = 0; i < arrClean.length; i++) {
    if (
      extraKey &&
      arrClean[i][nameKey] == valueKey &&
      arrClean[i][extraKey.key] === extraKey[extraKey.key]
    ) {
      let xx = [];
      if (showField)
        // eslint-disable-next-line no-loop-func
        showField.map((x) => {
          if (x !== "") xx.push(<span>{arrClean[i][x]}</span>);
          return null;
        });
      return xx;
    }
    //jgn diganti compare sengaja pakai ==
    // eslint-disable-next-line eqeqeq
    if (arrClean[i][nameKey] == valueKey && extraKey === null) {
      let xx = [];
      if (showField)
        // eslint-disable-next-line no-loop-func
        showField.map((x) => {
          if (x !== "") xx.push(<span>{arrClean[i][x]}</span>);
          return null;
        });
      return xx;
    }
  }
  return [];
}

export function getValueFieldFooter(
  nameKey,
  valueKey,
  myArray,
  showField,
  formatNumber
) {
  for (let i = 0; i < myArray.length; i++) {
    let ii = i;
    if (myArray[ii][nameKey] === valueKey) {
      let xx = [];
      if (showField)
        showField.map((x) => {
          if (x !== "") {
            xx.push(
              <span style={{ borderTop: "1px solid" }}>
                {formatNumber(
                  myArray
                    .filter((x) => x[nameKey] === valueKey)
                    .reduce((prev, val) => prev + (Number(val[x]) || 1), 0)
                )}
              </span>
            );
          }
          return null;
        });
      return xx;
    }
  }
  return [];
}

export function searchName(nameKey, valueKey, myArray) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i].tenantId[nameKey] === valueKey) {
      return { ...myArray[i], urut: i };
    }
  }
  // return {}
}

export function search2(nameKey, valueKey, nameKey2, valueKey2, myArray) {
  for (let i = 0; i < myArray.length; i++) {
    if (
      myArray[i][nameKey] === valueKey &&
      myArray[i][nameKey2] === valueKey2
    ) {
      return { ...myArray[i], urut: i };
    }
  }
  // return {}
}

export function getUnitRatio(valueKey, myArray) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i].unit === valueKey) {
      return myArray[i].ratio;
    }
  }
}

export function getDeptName(valueKey, myArray) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i].deptid === valueKey) {
      return myArray[i].deptname;
    }
  }
}

export function getProjectName(valueKey, myArray) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i].projectid === valueKey) {
      return myArray[i].projectname;
    }
  }
}

export function ErrorMessage(error) {
  if (error.status === 409 || error.status === 404) {
    notification.warning({
      message: "Easy Cloud",
      description: error.message,
    });
  } else if (error.status === 403 || error.status === 401) {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem("state");
    let state = store.getState();
    state.Auths.isSession = false;
    window.open("/", "_self");
  } else {
    const { message } = error;
    if (message.constructor === Array) {
      notification.warning({
        message: "Easy Cloud",
        description: error.message[0],
      });
    } else if (message)
      notification.error({
        message: "Easy Cloud",
        // description: 'Sorry! Something went wrong. Please try again'
        description: error.message,
      });
  }
}

export function ModalErrorMessage(error) {
  alertDialog({
    customUI: ({ onClose }) => (
      <Alert description={error.message} onClose={onClose} mask={error.mask} />
    ),
  });
  // if (error.status === 409 || error.status === 404) {
  //   Modal.warning({
  //     title: "Easy Cloud",
  //     content: error.message,
  //   });
  // } else {
  //   Modal.error({
  //     title: "Easy Cloud",
  //     content: error.message,
  //   });
  // }
}

export function IsEmptyOrNull(value) {
  if (
    value.length === 0 ||
    value === "" ||
    !value ||
    value === undefined ||
    value === "0" ||
    typeof value === "symbol"
  ) {
    return true;
  }
  return false;
}

export function SuccessMessage(tipe = null) {
  if (tipe !== null) {
    notification.success({
      message: "Easy Cloud",
      description: `Successfully ${tipe}`,
    });
  } else {
    notification.success({
      message: "Easy Cloud",
      description: "Successfully",
    });
  }
}

export function ValidateQty(nameKey, detail) {
  let cnt = 0;
  for (let i = 0; i < detail.length; i++) {
    // if (detail[i][nameKey] <= 0) {
    //   cnt = cnt + 1;
    // }

    if (isNaN(detail[i][nameKey])) {
      cnt += 1;
    }
  }
  if (cnt > 0) {
    return false;
  }
  return true;
}

export function validateSubLedger(nameKey, subledger, detail) {
  let hasil = 0;
  for (let i = 0; i < detail.length; i++) {
    if (
      (detail[i][nameKey] === 13 || detail[i][nameKey] === 8) &&
      detail[i][subledger] === 0
    ) {
      hasil += 1;
    }
  }
  if (hasil > 0) {
    return false;
  }
  return true;
}

export function formatNumber(value) {
  value += "";
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
}

export function formatDatePref(value, format) {
  const someday = moment(value);
  const dat = moment(someday).format(format);
  return dat === "Invalid date" ? "" : dat;
}

export function formatDate(date, format, utc) {
  const MMMM = [
    "\x00",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const MMM = [
    "\x01",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dddd = [
    "\x02",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  function ii(i, len = 2) {
    let s = `${i}`;
    while (s.length < len) s = `0${s}`;
    return s;
  }

  const y = utc ? date.getUTCFullYear() : date.getFullYear();
  format = format.replace(/(^|[^\\])yyyy+/g, `$1${y}`);
  format = format.replace(/(^|[^\\])yy/g, `$1${y.toString().substr(2, 2)}`);
  format = format.replace(/(^|[^\\])y/g, `$1${y}`);

  const M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
  format = format.replace(/(^|[^\\])MMMM+/g, `$1${MMMM[0]}`);
  format = format.replace(/(^|[^\\])MMM/g, `$1${MMM[0]}`);
  format = format.replace(/(^|[^\\])MM/g, `$1${ii(M)}`);
  format = format.replace(/(^|[^\\])M/g, `$1${M}`);

  const d = utc ? date.getUTCDate() : date.getDate();
  format = format.replace(/(^|[^\\])dddd+/g, `$1${dddd[0]}`);
  format = format.replace(/(^|[^\\])ddd/g, `$1${ddd[0]}`);
  format = format.replace(/(^|[^\\])dd/g, `$1${ii(d)}`);
  format = format.replace(/(^|[^\\])d/g, `$1${d}`);

  let H = utc ? date.getUTCHours() : date.getHours();
  format = format.replace(/(^|[^\\])HH+/g, `$1${ii(H)}`);
  format = format.replace(/(^|[^\\])H/g, `$1${H}`);

  const h = H > 12 ? H - 12 : H === 0 ? 12 : H;
  format = format.replace(/(^|[^\\])hh+/g, `$1${ii(h)}`);
  format = format.replace(/(^|[^\\])h/g, `$1${h}`);

  const m = utc ? date.getUTCMinutes() : date.getMinutes();
  format = format.replace(/(^|[^\\])mm+/g, `$1${ii(m)}`);
  format = format.replace(/(^|[^\\])m/g, `$1${m}`);

  const s = utc ? date.getUTCSeconds() : date.getSeconds();
  format = format.replace(/(^|[^\\])ss+/g, `$1${ii(s)}`);
  format = format.replace(/(^|[^\\])s/g, `$1${s}`);

  let f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
  format = format.replace(/(^|[^\\])fff+/g, `$1${ii(f, 3)}`);
  f = Math.round(f / 10);
  format = format.replace(/(^|[^\\])ff/g, `$1${ii(f)}`);
  f = Math.round(f / 10);
  format = format.replace(/(^|[^\\])f/g, `$1${f}`);

  const T = H < 12 ? "AM" : "PM";
  format = format.replace(/(^|[^\\])TT+/g, `$1${T}`);
  format = format.replace(/(^|[^\\])T/g, `$1${T.charAt(0)}`);

  const t = T.toLowerCase();
  format = format.replace(/(^|[^\\])tt+/g, `$1${t}`);
  format = format.replace(/(^|[^\\])t/g, `$1${t.charAt(0)}`);

  let tz = -date.getTimezoneOffset();
  let K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
  if (!utc) {
    tz = Math.abs(tz);
    const tzHrs = Math.floor(tz / 60);
    const tzMin = tz % 60;
    K += `${ii(tzHrs)}:${ii(tzMin)}`;
  }
  format = format.replace(/(^|[^\\])K/g, `$1${K}`);

  const day = (utc ? date.getUTCDay() : date.getDay()) + 1;
  format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
  format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

  format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
  format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

  return format.replace(/\\(.)/g, "$1");
}

export function compare(a, b) {
  const seqA = a.seq;
  const seqB = b.seq;

  if (seqA > seqB) {
    return 1;
  }
  if (seqA < seqB) {
    return -1;
  }
  return;
}

export function validateKeyPress(e, regex) {
  // "[0-9.']"
  const specialCharRegex = new RegExp(regex);
  const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (!specialCharRegex.test(pressedKey)) {
    e.preventDefault();
    return false;
  }
}

export function formatCurrency(value, format = null) {
  let values = Number(value);
  if (isNaN(values)) {
    return "";
  }
  let style = "decimal";
  let currency = "IDR";
  let maximumFractionDigits = 6;
  if (format !== null) {
    style = format.style;
    currency = format.currency;
    maximumFractionDigits = format.maximumFractionDigits;
  }
  return new Intl.NumberFormat("id-ID", {
    style,
    currency,
    maximumFractionDigits,
  }).format(values);

  // if (value === undefined || value == null) {
  //   return "";
  // } else {
  //   value += "";
  //   const list = value.split(".");
  //   const prefix = list[0].charAt(0) === "-" ? "-" : "";
  //   let num = prefix ? list[0].slice(1) : list[0];
  //   let result = "";
  //   while (num.length > 3) {
  //     result = `.${num.slice(-3)}${result}`;
  //     num = num.slice(0, num.length - 3);
  //   }
  //   if (num) {
  //     result = num + result;
  //   }
  //   return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
  // }
  // return num;
  // }
}

export function decreaseDecimalNumWithoutChangeFormat(num, fixed) {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return Number(num.toString().match(re)[0]);
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function removeDuplicates(array) {
  return array.filter((a, b) => array.indexOf(a) === b);
}

export function ConfirmDelete({ title_del, ok_submit }) {
  confirm({
    title: `Apakah Anda yakin akan menghapus ${title_del}`,
    content: "Anda tidak bisa mengembalikan data yang sudah terhapus",
    okText: "Ya",
    okType: "danger",
    cancelText: "Batal",
    okButtonProps: {
      type: "danger",
    },
    onOk: () => {
      ok_submit();
    },
    onCancel() {},
  });
}

export function space(jml) {
  let result = "";
  for (let i = 0; i < jml; i++) {
    result += ".";
  }
  return result;
}

export function makeReserved(jml) {
  const result = [];
  for (let i = 1; i <= jml; i++) {
    let xx = {
      name: "Catatan",
      key: `itemreserved${i}`,
      width: 200,
      resizable: true,
      editable: true,
      visible: true,
    };
    result.push({ ...xx });
  }
  return result;
}

export function makeBranch(props) {
  const result = [];
  result.push({
    title: props.t("Pengguna"),
    dataIndex: "user",
    ellipsis: true,
    width: 100,
    render: (text, row) => (
      <TooltipRender
        value={<span style={{ marginLeft: 5 }}>{text ? text.name : ""}</span>}
      />
    ),
    sorter: (a, b) => {
      a = a.user ? a.user.name : "";
      b = b.user ? b.user.name : "";
      return a.localeCompare(b);
    },
  });

  if (props.isCabang === false) {
    return result;
  }

  //selain user
  // if (props.roleId.id !== 1) {

  // }

  //hanya admin
  // if (props.roleId.id === 3) {
  result.push({
    title: props.t("Cabang"),
    dataIndex: "branch",
    ellipsis: true,
    width: 100,
    render: (text, row) => (
      <TooltipRender
        value={
          <span style={{ marginLeft: 5 }}>{text ? text.branchname : ""}</span>
        }
      />
    ),
    sorter: (a, b) => {
      a = a.branch ? a.branch.branchname : "";
      b = b.branch ? b.branch.branchname : "";
      return a.localeCompare(b);
    },
  });
  // }

  return result;
}

export function columnApproval(props, form) {
  let newColumn = [
    {
      title: props.t("No. Persetujuan"),
      dataIndex: "approveno",
      width: 100,
      ellipsis: true,
      render: (text, row) => <TooltipRender value={text} />,
    },
    {
      title: props.t("Catatan Pemeriksaan"),
      dataIndex: "checknote",
      width: 100,
      ellipsis: true,
      align: "center",
      render: (text, row) => <Checkbox checked={text} />,
    },
    {
      title: props.t("Tindak Lanjut"),
      dataIndex: "followup",
      width: 100,
      ellipsis: true,
      align: "center",
      render: (text, row) => <Checkbox checked={text} />,
    },
    {
      title: props.t("Disetujui"),
      dataIndex: "approved",
      width: 100,
      ellipsis: true,
      align: "center",
      render: (text, row) => <Checkbox checked={text} />,
    },
    {
      title: props.t("Urgensi"),
      dataIndex: "urgent",
      width: 100,
      ellipsis: true,
      align: "center",
      render: (text, row) => <Checkbox checked={text} />,
    },
  ];
  if (form === "Penyesuaian Barang") {
    if (
      props.preferensiFitur.perusahaan.approval &&
      // eslint-disable-next-line eqeqeq
      props.preferensiFitur.perusahaan.typeapproval == 1
    ) {
      return newColumn;
    }
  } else if (props.preferensiFitur.perusahaan.approval) {
    return newColumn;
  }
  return newColumn;
}

export function getValue(dataSource, tablename, index, fieldname) {
  const key = fieldname.split(".");
  if (index === -1) {
    switch (key.length) {
      case 1:
        return dataSource[tablename] ? dataSource[tablename][key[0]] : "";
      case 2:
        return dataSource[tablename][key[0]]
          ? dataSource[tablename][key[0]][key[1]]
          : "";
      case 3:
        return dataSource[tablename][key[0]][key[1]]
          ? dataSource[tablename][key[0]][key[1]][key[2]]
          : "";
      case 4:
        return dataSource[tablename][key[0]][key[1]][key[2]]
          ? dataSource[tablename][key[0]][key[1]][key[2]][key[3]]
          : "";
      case 5:
        return dataSource[tablename][key[0]][key[1]][key[2]][key[3]]
          ? dataSource[tablename][key[0]][key[1]][key[2]][key[3]][key[4]]
          : "";
      case 6:
        return dataSource[tablename][key[0]][key[1]][key[2]][key[3]][key[4]]
          ? dataSource[tablename][key[0]][key[1]][key[2]][key[3]][key[4]][
              key[5]
            ]
          : "";
      default:
        return dataSource[tablename] ? dataSource[tablename][key[0]] : "";
    }
  } else {
    switch (key.length) {
      case 1:
        return dataSource[tablename][index]
          ? dataSource[tablename][index][key[0]]
          : "";
      case 2:
        return dataSource[tablename][index][key[0]]
          ? dataSource[tablename][index][key[0]][key[1]]
          : "";
      case 3:
        return dataSource[tablename][index][key[0]][key[1]]
          ? dataSource[tablename][index][key[0]][key[1]][key[2]]
          : "";
      case 4:
        return dataSource[tablename][index][key[0]][key[1]][key[2]]
          ? dataSource[tablename][index][key[0]][key[1]][key[2]][key[3]]
          : "";
      case 5:
        return dataSource[tablename][index][key[0]][key[1]][key[2]][key[3]]
          ? dataSource[tablename][index][key[0]][key[1]][key[2]][key[3]][key[4]]
          : "";
      case 6:
        return dataSource[tablename][index][key[0]][key[1]][key[2]][key[3]][
          key[4]
        ]
          ? dataSource[tablename][index][key[0]][key[1]][key[2]][key[3]][
              key[4]
            ][key[5]]
          : "";
      default:
        return dataSource[tablename][index]
          ? dataSource[tablename][index][key[0]]
          : "";
    }
  }
}
export function getName(ids, arrays, key, name) {
  let result = [];
  arrays.map((array) => {
    if (ids.includes(array[key])) {
      result.push(array[name]);
    }
    return null;
  });
  if (result.length > 5) {
    let xx = [];
    xx.push(result[0]);
    xx.push(result[1]);
    xx.push(result[2]);
    xx.push(result[3]);
    xx.push(result[4]);
    xx.push(" dst.");
    return xx;
  }

  return result;
}

export function getDate(ids, arrays, key, name) {
  let result = [];
  arrays.map((array) => {
    if (ids.includes(array[key])) {
      result.push(array);
    }
    return null;
  });

  return result;
}

export function formatterReactGrid({
  value,
  style,
  Element,
  className,
  suffix,
}) {
  return (
    <div
      style={{
        padding: "0 8px",
        ...style,
      }}
    >
      {Element ? (
        <Element className={className} checked={value} />
      ) : suffix ? (
        `${value} ${suffix}`
      ) : (
        value
      )}
    </div>
  );
}
export const TooltipRender = ({ value, placement = "top" }) => (
  <Tooltip placement={placement} title={value}>
    {value}
  </Tooltip>
);

export function getDefaultTemplateID(list, typeTemplate) {
  let result = 0;
  list.map((temp) => {
    if (temp.templatetype === typeTemplate) {
      result = temp.deftemplateid;
    }
    return null;
  });
  return result;
}

export function getCaption(lng, key, data) {
  let caption = "caption_id";
  if (lng !== "id") {
    caption = "caption";
  }

  let filter = data.filter((element) => element.defaultcaption === key);
  if (filter.length > 0) {
    return filter[0][caption];
  }
  return "";
}

function getForm(form) {
  let str = form;
  if (form === "Desain Email") {
    str = "Izinkan Desain E-mail CnT";
  } else if (form === "Status CnT") {
    str = "Izinkan Akses Status CnT.";
  } else if (form === "Invitation") {
    str = "Izinkan Akses Pengajuan dan Pembatalan Integrasi.";
  }
  return str;
}
export function cekAkses(form, jenis, modul, data) {
  if (modul) {
    if (modul === "Aktiva Tetap") {
      modul = "Aktiva";
    } else if (modul === "persetujuan") {
      return true;
    }
    let hakModul = search("modul", modul, data);
    form = getForm(form);
    let hak = search("deskripsi1", form, hakModul.data);
    if (hak !== undefined) {
      return hak[jenis];
    }
    return true;
  }
  return true;
}
export function getFormHakAkses(form, data) {
  let obj;
  data.find((modul) => {
    form = getForm(form);
    obj = modul.data.find((x) => x.deskripsi1 === form);
    return obj;
  });
  return obj;
}

export function setHakAksesForm(hakAkses, fitur, typeReturn) {
  const { name, condition } = fitur;
  if (name === "ubah") {
    if (typeReturn === Boolean) {
      return !hakAkses.ubah && !condition.insert;
    }
    if (typeReturn === Object) {
      return {
        pointerEvents: !hakAkses.ubah && !condition.insert ? "none" : "unset",
      };
    }
  } else if (name === "tambah") {
    return !hakAkses.tambah;
  }
  return;
}

export const DisallowedMessage = (e) => {
  ModalErrorMessage({
    status: 409,
    message: "Anda tidak punya akses ubah data.",
  });
  e.preventDefault();
};

export function getAddonsEnable(data) {
  let result = {};
  data.map((x) => {
    result = { ...result, [x.itemname]: x.checked };
    return null;
  });
  return result;
}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export function splitArray(arr, len) {
  const chunks = [];
  let i = 0;
  const n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
}

// start pivot function
export function splitArrayPivot(arr, length, arr2) {
  const hasil = [];
  let zz = Math.ceil(arr2.length / length);
  for (let i = 0; i < zz; i++) {
    arr.map((x) => {
      hasil.push(x);
      return null;
    });
  }
  return hasil;
}

export function sumPivot(arr, col, val) {
  // menjumlah firstkey yg sama
  let helper = {};
  let rslt = [
    ...arr.reduce((xx, yy) => {
      let key = "";
      let dash = "-";

      col.map((cc, idx) => {
        if (idx === col.length - 1) {
          dash = "";
        }
        key = key += `${yy[cc.key]}${dash}`;
        return null;
      });

      if (!helper[key]) {
        helper[key] = Object.assign({}, yy);
        xx.push(helper[key]);
      } else {
        val.map((vv) => {
          helper[key][vv.key] += yy[vv.key];
          return null;
        });
      }

      return xx;
    }, []),
  ];

  rslt.sort((a, b) => {
    return (a[col[0].key] > b[col[0].key]) - (a[col[0].key] < b[col[0].key]);
  });

  return rslt;
}

export function confirmAlert({
  type,
  title,
  description,
  onSubmit,
  okText,
  cancelText,
  ...props
}) {
  props = {
    ...props,
    visible: !props.visible,
  };
  return {
    cancelText,
    okText,
    type,
    title,
    description,
    onSubmit,
    ...props,
  };
}

export function compareArray(a, b) {
  if (a.grouporder < b.grouporder) {
    return -1;
  }
  if (a.grouporder > b.grouporder) {
    return 1;
  }
  return 0;
}

export function getFormName(x) {
  switch (x) {
    case "AP ORD":
      return "PesananPembelian";
    case "AR INV":
      return "FakturPenjualan";
    case "AR DLV":
      return "PengirimanBarang";
    case "AR ORD":
      return "PesananPenjualan";
    case "AP INV":
      return "FakturPembelian";
    case "AP RCV":
      return "PenerimaanBarang";
    case "GL JV":
      return "JurnalUmum";
    case "GL PMT":
      return "PembayaranLainnya";
    case "AR PMT":
      return "PenerimaanPenjualan";
    case "GL ODT":
      return "PenerimaanLainnya";
    case "PR JOB":
      return "PembiayaanPesanan";
    case "AP RTR":
      return "ReturPembelian";
    case "AR RTR":
      return "ReturPenjualan";
    case "AP CHQ":
      return "PembayaranPembelian";
    case "PD ADJ":
      return "PengeluaranBahan";
    case "PD FIN":
      return "HasilProduksi";
    case "IT ADJ":
      return "PenyesuaianBarang";
    case "IT TRA":
      return "PindahBarang";
    case "AR QUO":
      return "PenawaranPenjualan";
    case "AP REQ":
      return "PermintaanPembelian";
    case "BM BOM":
      return "FormulaProduk";
    case "BM MTRL":
      return "PengeluaranBahanBaku";
    case "BM WO":
      return "PerintahKerja";
    case "AR TMP":
      return "FakturSementara";
    case "FA ACQ":
    case "FA DIS":
      return "AktivaTetap";
    default:
      return "SemuaLaporan";
  }
}

export function getColor(color) {
  const delphiColor = {
    clBlack: "#000000",
    clMaroon: "#800000",
    clGreen: "#008000",
    clOlive: "#808000",
    clNavy: "#000080",
    clPurple: "#800080",
    clTeal: "#008080",
    clGray: "#808080",
    clSilver: "#C0C0C0",
    clRed: "#ff0000",
    clLime: "#00FF00",
    clYellow: "#FFFF00",
    clBlue: "#0000FF",
    clFuchsia: "#FF00FF",
    clAqua: "#00FFFF",
    clLtGray: "#C0C0C0",
    clDkGray: "#808080",
    clWhite: "#FFFFFF",
  };

  if (delphiColor[color] !== undefined) {
    return delphiColor[color];
  }
  return color;
}

export function ValidateQtyLebih(nameKey, detail, nameKey2) {
  let cnt = 0;
  let nama = [];
  for (let i = 0; i < detail.length; i++) {
    if (detail[i].itemtype === 0 && detail[i][nameKey] > detail[i][nameKey2]) {
      cnt += 1;
      nama.push(detail[i].itemno);
    }
    if (
      detail[i].itemtype === undefined &&
      detail[i].item.itemtype === 0 &&
      detail[i][nameKey] > detail[i][nameKey2]
    ) {
      cnt += 1;
      nama.push(detail[i].itemno);
    }
  }
  if (cnt > 0) {
    return { status: false, data: nama };
  }
  return { status: true, data: [] };
}

export function ValidateQtyByGudang(details, detailGudang) {
  let cnt = 0;
  let nama = [];
  for (let i = 0, len = details.length; i < len; i++) {
    const res = detailGudang.find(
      (el) =>
        el.ritemno === details[i].itemno &&
        el.warehouse.warehouseid === details[i].warehouseid
    );
    if (res && Number(details[i].quantity) > res.quantity) {
      cnt += 1;
      nama.push(details[i].itemno);
    }
  }
  if (cnt > 0) {
    return { status: false, data: nama };
  }
  return { status: true, data: [] };
}

export function HitungUnitPrice(updated, rowdata) {
  let newUnitRatio = updated.unitratio;
  // const { unitprice = 0, unitratio } = rowdata;
  const { unitprice = 0, cost = 0, unitratio } = rowdata;
  let hargaUnit = unitprice ? unitprice : cost;
  let hargaSatuan = (price, unit, ratio) => (price / unit) * ratio;
  return {
    ...updated,
    unitprice: hargaSatuan(hargaUnit, unitratio, newUnitRatio),
    cost: hargaSatuan(hargaUnit, unitratio, newUnitRatio),
    unitratio: newUnitRatio,
  };
}

export function FilterDate(startDate, endDate, valDate, formatDate) {
  let sDate = moment(startDate, formatDate).toDate();
  let eDate = moment(endDate, formatDate).toDate();
  let rDate = moment(valDate, formatDate).toDate();
  const range = momentRange().range(sDate, eDate);
  let x = range.contains(rDate);
  return x;
}

export function formatTerhutang(props, form) {
  let owing = `${props.row.currencyname} ${formatCurrency(props.row.owing)}`;

  // let owingdc = formatCurrency(props.row.owingdc);
  let owingdc = "";
  if (
    (!props.row.bayar && form === "pembelian") ||
    (!props.row.pay && form === "penjualan")
  ) {
    owingdc = props.row.owingdcAsal
      ? formatCurrency(props.row.owingdcAsal)
      : formatCurrency(props.row.owingdc);
  }

  /**
   * @author Abie Mei Rizky
   * @description IDR (belum dinamis sesuai dengan mata uang yg digunakan jika selain IDR.)
   */
  if (owingdc !== "0" && owingdc !== "" && props.row.currencyname !== "IDR") {
    owingdc = "; IDR " + owingdc;
  } else {
    owingdc = "";
  }

  if (owing === "undefined ") {
    owing = "";
  }

  return `${owing}${owingdc}`;
}

export function getNested(obj, ...args) {
  return args.reduce((obj, level) => obj && obj[level], obj);
}

export function updateFormRecurring(
  reccuringKeyForm,
  reccuringid,
  addDraft,
  get,
  idTenant
) {
  const initForm = "TransaksiBerulang";
  const initDraft = "Transaksi Berulang";
  let getData = get({
    tenantId: idTenant,
    id: reccuringid,
    form: initForm,
  });

  getData
    .then((response) => {
      const xxx = store.getState();

      let stateDraft = getNested(xxx, "Draft", initDraft, reccuringKeyForm);

      if (stateDraft !== undefined) {
        let bodyState = {
          ...stateDraft,
          body: {
            ...stateDraft.body,
            ...response.data,
          },
        };

        addDraft({
          parent: initDraft,
          key: reccuringKeyForm,
          value: bodyState,
        });
      }
    })
    .catch((error) => {
      ErrorMessage(error);
    });
}

export function isUseSN(row) {
  if (row.item && row.item.usesn) {
    return true;
  }
  if (row && row.usesn) {
    return true;
  }
  return false;
}

export function validateQtySN(dataItems, dataSN, fieldname = "quantity") {
  let result = { success: true, data: [] };
  let itemsUseSN = dataItems.filter(
    (x) => x.item !== undefined && x.item.forcesn === true
  );
  let data = result.data;
  if (itemsUseSN.length > 0) {
    itemsUseSN.map((x) => {
      let qtySelect = dataSN
        .filter((y) => y.seq === x.seq)
        .reduce((prev, cur) => prev + Number(cur.quantity), 0);

      if (qtySelect < Number(x[fieldname]) * Number(x.unitratio)) {
        let title = x.sntype === 0 ? "No. Serial" : "No. Batch";

        data.push(`Kuantitas ${title} ${x.itemno} Barang tidak sesuai.`);
        result = { ...result, success: false, data };
      }
      return null;
    });
  }

  return result;
}

export function validateSNMasterBarang(
  body,
  detItems,
  dataSN,
  fieldname = "newQty"
) {
  let result = { success: true, data: [] };
  let itemsUseSN = [];
  if (body.master.forcesn) {
    itemsUseSN = detItems;
  }
  let data = result.data;
  if (itemsUseSN.length > 0) {
    itemsUseSN.map((x) => {
      let qtySelect = x.snData.reduce(
        (prev, cur) => prev + Number(cur.quantity),
        0
      );

      if (qtySelect < Number(x[fieldname]) * 1) {
        let title = body.master.sntype === 0 ? "No. Serial" : "No. Batch";

        data.push(`Kuantitas ${title} ${x.adjNo} Barang tidak sesuai.`);
        result = { ...result, success: false, data };
      }
      return null;
    });
  }

  return result;
}

export function isWhIdAvail(nameKey, whid, availwh) {
  let xx = 0;
  for (let i = 0; i < availwh.length; i++) {
    if (availwh[i][nameKey] === whid) {
      xx = xx + 1;
    }
  }
  if (xx > 0) {
    return true;
  }
  return false;
}

export function getArray(ids, arrays, key, name) {
  let result = [];
  arrays.map((array) => {
    if (ids.includes(array[key])) {
      result.push(array[name]);
    }
    return null;
  });

  return result;
}

export function canEditNumber(e, name, branch, roleid) {
  if (roleid !== 3) {
    return (
      e.target.name === name &&
      branch &&
      e.target.value.substr(0, branch.length) !== branch
    );
  } else return false;
}

export function CekCetakApproval(user, body, insert, preferensi) {
  const { approvalprint, approval } = preferensi;
  const { roleId, creator } = user;
  const idUser = roleId.id;
  const { master } = body;
  let akses = {
    msg: null,
    akses: true,
  };

  //kalau approvalprint, langsung aja di allow
  if (approvalprint) {
    return akses;
  }

  if (!approval) {
    return akses;
  }
  if (insert && !creator) {
    /**
     * @description tiap transaksi baru tidak bisa cetak
     */
    akses.akses = false;
  } else if (idUser === 1) {
    if (approvalprint && master.approved) {
      akses.akses = true;
    } else if (approvalprint && !master.approved) {
      /**
       * @description role user tidak dapat cetak karena transaksi belum disetujui
       */
      akses.msg = "Notifikasi cetak approval";
      akses.akses = true;
    } else {
      akses.akses = false;
    }
  } else if (creator || approvalprint) {
    akses.akses = true;
  } else if (approvalprint && master.approved) {
    akses.akses = true;
  } else if (approvalprint && !master.approved) {
    /**
     * @description role user tidak dapat cetak karena transaksi belum disetujui
     */
    akses.msg = "Notifikasi cetak approval";
    akses.akses = true;
  } else {
    akses.akses = false;
  }
  return akses;
}

export function canNextStep(user, body, hakakses, preferensi) {
  const { approval, typeapproval } = preferensi;
  const { creator } = user;
  const { master } = body;
  let akses = {
    msg: null,
    akses: true,
  };

  if (
    approval &&
    hakakses.approval &&
    !creator &&
    !master.approved &&
    typeapproval !== "1"
  ) {
    akses.akses = false;
    akses.msg = "Notifikasi cetak approval";
  }

  return akses;
}

export function titleCase(str) {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function calculateValue(detail, option, type) {
  let value = 0;
  let masukan = Number(option.masukan);
  let pembulatan = Number(TOOLS_PEMBULATAN[option.pembulatan]);
  switch (option.nilaiAsal) {
    case 0:
      value = type === "biaya" ? detail.dlabor.cost : detail.item.cost;
      break;
    case 1:
      value = type === "biaya" ? detail.oldcost : detail.oldcost;
      break;
    case 2:
      value = type === "biaya" ? detail.newcost : detail.newcost;
      break;
    default:
      value = type === "biaya" ? detail.dlabor.cost : detail.item.cost;
      break;
  }

  value = Number(value);

  //calculate
  switch (option.metode) {
    case 0:
    case 1:
    case 2:
      value =
        type === "biaya"
          ? (value * (100 + masukan)) / 100
          : (value * (100 + masukan)) / 100;
      break;
    case 3:
    case 4:
    case 5:
      value = type === "biaya" ? value + masukan : value + masukan;
      break;
    case 6:
    case 7:
    case 8:
      value = type === "biaya" ? value - masukan : value - masukan;
      break;
    default:
      // eslint-disable-next-line no-self-assign
      value = value;
      break;
  }

  //pembulatan
  switch (option.metode) {
    case 1:
    case 4:
    case 7:
      var div = Math.trunc(value / pembulatan);
      var mod = value % pembulatan;

      value = mod > 0 ? (div + 1) * pembulatan : value;
      break;
    case 2:
    case 5:
    case 8:
      // eslint-disable-next-line no-redeclare
      var div = Math.trunc(value / pembulatan);
      // eslint-disable-next-line no-redeclare
      var mod = value % pembulatan;

      value = mod > 0 ? div * pembulatan : value;
      break;
    default:
      // eslint-disable-next-line no-self-assign
      value = value;
      break;
  }

  return value;
}
export function calculatePenyesuaian(detail, option, type) {
  const { nilaiasal, methode, penyesuaiantype, value, pembulatan } = option;
  let masukan = Number(value);
  let result = [];
  let reNewValue = {};
  // init field yang akan di ubah nilainya
  switch (penyesuaiantype) {
    case 0:
      reNewValue = {
        price1: 0,
        price2: 0,
        price3: 0,
        price4: 0,
        price5: 0,
        itemdiscpc: 0,
        minsellprice: 0,
        maxsellprice: 0,
        minpurcprice: 0,
        maxpurcprice: 0,
      };
      break;
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
      let obj = PENYESUAIAN_HARGA.find((x) => x.value === penyesuaiantype);
      const { field } = obj;
      reNewValue = {
        [field]: 0,
      };
      break;
    default:
      break;
  }
  result = detail.map((data) => {
    // set nilai asal dari pilihan penyesuaian
    switch (nilaiasal) {
      case 1:
        //semua nilai sekarang
        for (let key in reNewValue) {
          let value = data[key];
          reNewValue[key] = value ? Number(value) : 0;
        }
        break;
      case 2:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
        //nilai yang ada fieldnya
        const { field } = NILAI_ASAL.find((x) => x.value === nilaiasal);
        for (let key in reNewValue) {
          let value = data[field];
          reNewValue[key] = value ? Number(value) : 0;
        }
        break;
      case 3:
        //TODO semua nilai terakhir
        for (let key in reNewValue) {
          if (key === "price1") {
            reNewValue[key] = data.oldprice1;
          } else if (key === "price2") {
            reNewValue[key] = data.oldprice2;
          } else if (key === "price3") {
            reNewValue[key] = data.oldprice3;
          } else if (key === "price4") {
            reNewValue[key] = data.oldprice4;
          } else if (key === "price5") {
            reNewValue[key] = data.oldprice5;
          } else if (key === "itemdiscpc") {
            reNewValue[key] = data.olditemdiscpc;
          } else if (key === "minsellprice") {
            reNewValue[key] = data.oldminsellprice;
          } else if (key === "maxsellprice") {
            reNewValue[key] = data.oldmaxsellprice;
          } else if (key === "minpurcprice") {
            reNewValue[key] = data.oldminpurcprice;
          } else if (key === "maxpurcprice") {
            reNewValue[key] = data.oldmaxpurcprice;
          }
        }
        break;
      default:
        for (let key in reNewValue) {
          let value = reNewValue[key];
          reNewValue[key] = value ? Number(value) : 0;
        }
        break;
    }
    //end

    //penyesuaian metode
    switch (methode) {
      case 0:
      case 1:
      case 2:
        for (let key in reNewValue) {
          let value = Number(reNewValue[key]);
          reNewValue[key] = (value * (100 + masukan)) / 100;
        }
        break;
      case 3:
      case 4:
      case 5:
        for (let key in reNewValue) {
          let value = Number(reNewValue[key]);
          reNewValue[key] = value + masukan;
        }
        break;
      case 6:
      case 7:
      case 8:
        for (let key in reNewValue) {
          let value = Number(reNewValue[key]);
          reNewValue[key] = value - masukan;
        }
        break;
      default:
        for (let key in reNewValue) {
          let value = Number(reNewValue[key]);
          reNewValue[key] = value;
        }
        break;
    }

    switch (methode) {
      case 1:
      case 4:
      case 7:
        for (let key in reNewValue) {
          let value = Number(reNewValue[key]);
          let div = Math.trunc(value / pembulatan);
          let mod = value % pembulatan;
          value = mod > 0 ? (div + 1) * pembulatan : value;
          reNewValue[key] = value;
        }
        break;
      case 2:
      case 5:
      case 8:
        for (let key in reNewValue) {
          let value = Number(reNewValue[key]);
          let div = Math.trunc(value / pembulatan);
          let mod = value % pembulatan;

          value = mod > 0 ? div * pembulatan : value;
          reNewValue[key] = value;
        }
        break;
      default:
        for (let key in reNewValue) {
          let value = Number(reNewValue[key]);
          reNewValue[key] = value;
        }
        break;
    }
    return {
      ...data,
      ...reNewValue,
    };
  });
  return result;
}

export function differenceObject(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? differenceObject(value, base[key])
          : value;
    }
  });
}

export function getBase64Image(imgUrl, callback) {
  const img = new Image();

  // onload fires when the image is fully loadded, and has width and height

  img.onload = () => {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

    callback(dataURL); // the base64 string
  };

  // set attributes and src
  img.setAttribute("crossOrigin", "anonymous"); //
  img.src = imgUrl;
}

export function createFieldReport(
  defaultField,
  isOpenLaporan,
  targetKeys,
  transferDataSource
) {
  let id_details = defaultField.details.field_id;
  let id_useddetails = defaultField.useddetails.field_id;
  let id_transferDataSource = defaultField.transferDataSource.field_id;
  let def_details = defaultField.details;
  let def_useddetails = defaultField.useddetails;
  let def_transferDataSource = defaultField.transferDataSource;
  let paramperiod1 = Number(isOpenLaporan.data.parameters.paramperiod1);
  let paramperiod2 = Number(isOpenLaporan.data.parameters.paramperiod2);
  let paramyear1 = Number(isOpenLaporan.data.parameters.paramyear1);
  let paramyear2 = Number(isOpenLaporan.data.parameters.paramyear2);

  let invalid = Math.abs(paramyear2 - paramyear1) > 5;
  if (
    paramyear2 < paramyear1 ||
    (paramyear2 === paramyear1 && paramperiod2 < paramperiod1) ||
    invalid
  ) {
    return { isOpenLaporan, targetKeys, transferDataSource };
  } else if (
    (paramyear2 === paramyear1 && paramperiod2 >= paramperiod1) ||
    paramyear2 > paramyear1
  ) {
    let data = isOpenLaporan.data;
    let detailsIndex = data.details.findIndex(
      (x) => x.field_name === defaultField.details.field_name
    );
    let useddetailsIndex = data.useddetails.findIndex(
      (x) => x.field_name === defaultField.useddetails.field_name
    );
    let transferdatasourceIndex = transferDataSource.findIndex(
      (x) => x.field_name === defaultField.transferDataSource.field_name
    );
    let newDetails = [];
    let newUsedDetails = [];
    let newTransferDataSource = [];
    let newTargetKeys = [];
    let yy = 0;
    for (let x = paramyear1; x <= paramyear2; x++) {
      let aa = x === paramyear1 ? paramperiod1 : 1;
      let bb = x === paramyear2 ? paramperiod2 : 12;
      for (let i = aa; i <= bb; i++) {
        let field_alias = `${BULAN[i - 1]} ${x}`;
        let field_alias_ind = `${BULAN[i - 1]} ${x}`;
        let title = `${BULAN[i - 1]} ${x}`;
        let key =
          yy === 0
            ? defaultField.details.field_name
            : `${defaultField.details.field_name}_${yy}`;
        let table_alias =
          yy === 0
            ? defaultField.details.table_alias
            : `${defaultField.details.table_alias.slice(0, -1)}${yy + 1}`;
        newDetails.push({
          ...def_details,
          field_alias,
          field_alias_ind,
          title,
          key,
          table_alias,
        });
        newUsedDetails.push({
          ...def_useddetails,
          field_alias,
          field_alias_ind,
          key,
          table_alias,
        });
        yy += 1;
      }
    }
    if (data.master.report_id === 87 || data.master.report_id === 279) {
      let jumlah = {
        ...def_useddetails,
        field_alias: "Summary",
        field_alias_ind: "Jumlah",
        title: "Summary",
        key: `${defaultField.details.field_name}_${yy}`,
        table_alias: `${defaultField.details.table_alias.slice(0, -1)}${
          yy + 1
        }`,
      };
      newUsedDetails.push(jumlah);
    }
    data.details = data.details.filter((x) => x.field_id !== id_details);
    data.useddetails = data.useddetails.filter(
      (x) => x.field_id !== id_useddetails
    );
    data.details.splice(detailsIndex, 0, ...newDetails);
    data.useddetails.splice(useddetailsIndex, 0, ...newUsedDetails);

    let transferDataSourceKeys = transferDataSource
      .filter((x) => x.field_id === id_transferDataSource)
      .map((x) => x.key);
    targetKeys = targetKeys.filter((x) => !transferDataSourceKeys.includes(x));
    transferDataSource = transferDataSource.filter(
      (x) => x.field_id !== id_transferDataSource
    );
    // eslint-disable-next-line array-callback-return
    newDetails.map((x) => {
      let field_alias = x.field_alias;
      let field_alias_ind = x.field_alias_ind;
      let title = x.title;
      let key = x.key;
      newTransferDataSource.push({
        ...def_transferDataSource,
        field_alias,
        field_alias_ind,
        title,
        key,
      });
      newTargetKeys.push(key);
    });

    transferDataSource.splice(
      transferdatasourceIndex,
      0,
      ...newTransferDataSource
    );
    return {
      isOpenLaporan: { ...isOpenLaporan, data },
      targetKeys: [...targetKeys, ...newTargetKeys],
      transferDataSource,
    };
  }
}

export function createFieldReportPerbangindan(
  defaultField,
  isOpenLaporan,
  targetKeys,
  transferDataSource
) {
  let id_details = defaultField.details.field_id;
  let id_useddetails = defaultField.useddetails.field_id;
  let id_transferDataSource = defaultField.transferDataSource.field_id;
  let def_details = defaultField.details;
  let def_useddetails = defaultField.useddetails;
  let def_transferDataSource = defaultField.transferDataSource;

  let paramperiod1 = Number(isOpenLaporan.data.parameters.paramperiod1);
  let paramperiod2 = Number(isOpenLaporan.data.parameters.paramperiod2);
  let paramyear1 = Number(isOpenLaporan.data.parameters.paramyear1);
  let paramyear2 = Number(isOpenLaporan.data.parameters.paramyear2);

  if (
    paramyear2 < paramyear1 ||
    (paramyear2 === paramyear1 && paramperiod2 < paramperiod1)
  ) {
    return { isOpenLaporan, targetKeys, transferDataSource };
  } else if (
    (paramyear2 === paramyear1 && paramperiod2 >= paramperiod1) ||
    paramyear2 > paramyear1
  ) {
    let data = isOpenLaporan.data;
    let detailsIndex = data.details.findIndex(
      (x) => x.field_name === defaultField.details.field_name
    );
    let useddetailsIndex = data.useddetails.findIndex(
      (x) => x.field_name === defaultField.useddetails.field_name
    );
    let transferdatasourceIndex = transferDataSource.findIndex(
      (x) => x.field_name === defaultField.transferDataSource.field_name
    );

    let newDetails = [];
    let newUsedDetails = [];
    let newTransferDataSource = [];
    let newTargetKeys = [];
    let yy = 0;

    const condition = (yy, defaultField) => {
      let hasil = "";
      if (
        // isOpenLaporan.name === "Neraca (Perbandingan Bulan)" ||
        // isOpenLaporan.name === "Laba Rugi (Perbandingan Bulan)"
        isOpenLaporan.data.master.rptparent === 82 ||
        isOpenLaporan.data.master.rptparent === 88
      ) {
        hasil = defaultField.useddetails.key;
      } else {
        hasil =
          yy === 0
            ? defaultField.details.field_name
            : `${defaultField.details.field_name}_${yy}`;
      }

      return hasil;
    };

    for (let i = 0; i <= 1; i++) {
      let period = i === 0 ? paramperiod1 : paramperiod2;
      let year = i === 0 ? paramyear1 : paramyear2;
      let field_alias = `${BULAN[period - 1]} ${year}`;
      let field_alias_ind = `${BULAN[period - 1]} ${year}`;
      let title = `${BULAN[period - 1]} ${year}`;
      let key = condition(yy, defaultField);
      // yy === 0
      //   ? defaultField.details.field_name
      //   : `${defaultField.details.field_name}_${yy}`;
      let table_alias =
        i === 0
          ? defaultField.details.table_alias
          : `${defaultField.details.table_alias.slice(0, -1)}${i + 1}`;
      newDetails.push({
        ...def_details,
        field_alias,
        field_alias_ind,
        title,
        key,
        table_alias,
      });
      newUsedDetails.push({
        ...def_useddetails,
        field_alias,
        field_alias_ind,
        key,
        table_alias,
      });
      yy += 1;
    }

    data.details = data.details.filter((x) => x.field_id !== id_details);
    data.useddetails = data.useddetails.filter(
      (x) => x.field_id !== id_useddetails
    );
    data.details.splice(detailsIndex, 0, ...newDetails);
    data.useddetails.splice(useddetailsIndex, 0, ...newUsedDetails);

    let transferDataSourceKeys = transferDataSource
      .filter((x) => x.field_id === id_transferDataSource)
      .map((x) => x.key);
    targetKeys = targetKeys.filter((x) => !transferDataSourceKeys.includes(x));
    transferDataSource = transferDataSource.filter(
      (x) => x.field_id !== id_transferDataSource
    );
    // eslint-disable-next-line array-callback-return
    newDetails.map((x) => {
      let field_alias = x.field_alias;
      let field_alias_ind = x.field_alias_ind;
      let title = x.title;
      let key = x.key;
      newTransferDataSource.push({
        ...def_transferDataSource,
        field_alias,
        field_alias_ind,
        title,
        key,
      });
      newTargetKeys.push(key);
    });

    transferDataSource.splice(
      transferdatasourceIndex,
      0,
      ...newTransferDataSource
    );
    return {
      isOpenLaporan: { ...isOpenLaporan, data },
      targetKeys: [...targetKeys, ...newTargetKeys],
      transferDataSource,
    };
  }
}
export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return { size: Math.round(bytes / Math.pow(1024, i), 2), unit: sizes[i] };
}

export function betweenTemplate(obj, a, b) {
  let min = Math.min.apply(Math, [a, b]),
    max = Math.max.apply(Math, [a, b]);

  return obj > min && obj < max;
}

export function cekLockTransaction(pCompany, date, formatDate) {
  const {
    wperiodfrompp,
    wperiodfromyyyy,
    wperiodtopp,
    wperiodtoyyyy,
    eperiodfrompp,
    eperiodfromyyyy,
    eperiodtopp,
    eperiodtoyyyy,
  } = pCompany;
  let result = {
    hasil: false,
  };

  if (
    wperiodfrompp > 0 ||
    wperiodfromyyyy > 0 ||
    wperiodtopp > 0 ||
    wperiodtoyyyy > 0 ||
    eperiodfrompp > 0 ||
    eperiodfromyyyy > 0 ||
    eperiodtopp > 0 ||
    eperiodtoyyyy > 0
  ) {
    const dataDate = moment(date, formatDate);
    const dateNow = moment(dataDate._d).format("MM/YYYY");

    let fromPeriod = "",
      toPeriod = "",
      errFromPeriod = "",
      errToPeriod = "";

    if (wperiodfrompp > 0 && wperiodfromyyyy > 0) {
      fromPeriod = `${
        wperiodfrompp < 10 ? "0" : ""
      }${wperiodfrompp}/${wperiodfromyyyy}`;
    }
    if (wperiodtopp > 0 && wperiodtoyyyy > 0) {
      toPeriod = `${
        wperiodtopp < 10 ? "0" : ""
      }${wperiodtopp}/${wperiodtoyyyy}`;
    }
    if (eperiodfrompp > 0 && eperiodfromyyyy) {
      errFromPeriod = `${
        eperiodfrompp < 10 ? "0" : ""
      }${eperiodfrompp}/${eperiodfromyyyy}`;
    }
    if (eperiodtopp > 0 && eperiodtoyyyy) {
      errToPeriod = `${
        eperiodtopp < 10 ? "0" : ""
      }${eperiodtopp}/${eperiodtoyyyy}`;
    }

    result = {
      ...result,
      hasil: true,
      pWarning: funcPeriod(fromPeriod, toPeriod, dateNow),
      errWarning: funcPeriod(errFromPeriod, errToPeriod, dateNow),
    };
  }

  return result;
}

export function funcPeriod(from, to, now) {
  let warning = false;
  const tempFrom = from.split("/");
  const tempTo = to.split("/");
  const tempNow = now.split("/");
  const yearNow = Number(tempNow[1]);
  const monthNow = Number(tempNow[0]);
  const yearTo = Number(tempTo[1]);
  const monthTo = Number(tempTo[0]);
  const yearFrom = Number(tempFrom[1]);
  const monthFrom = Number(tempFrom[0]);

  if (from !== "" && to === "") {
    if (yearNow < yearFrom) {
      warning = true;
    } else if (yearNow === yearFrom) {
      if (monthNow < monthFrom) {
        warning = true;
      }
    }
  } else if (from === "" && to !== "") {
    if (yearNow > yearTo) {
      warning = true;
    } else if (yearNow === yearTo) {
      if (monthNow > monthTo) {
        warning = true;
      }
    }
  } else if (from !== "" && to !== "") {
    if (yearNow > yearTo || yearNow < yearFrom) {
      warning = true;
    } else if (yearNow === yearFrom || yearNow === yearTo) {
      if (
        (monthNow > monthTo && yearNow === yearTo) ||
        (monthNow < monthFrom && yearNow === yearFrom)
      ) {
        warning = true;
      }
    }
  }

  return warning;
}

export function personAddres(personData) {
  let hasil = "";

  if (personData.addressline1 !== "" && personData.addressline1 !== undefined) {
    hasil = `${personData.addressline1}\n${personData.addressline2}`;
  }

  return hasil;
}

export const sortRows = (_initialRows, sortColumn, sortDirection) => {
  // eslint-disable-next-line consistent-return
  const comparer = (a, b) => {
    const data_1 = a[sortColumn] || "";
    const data_2 = b[sortColumn] || "";
    if (typeof data_1 === "number") {
      if (sortDirection === "ASC") {
        return data_1 > data_2 ? 1 : -1;
      }
      if (sortDirection === "DESC") {
        return data_1 < data_2 ? 1 : -1;
      }
    } else {
      const compareRes = data_1.localeCompare(data_2);
      // if(a.pay || b.pay) return 1;
      return sortDirection === "ASC" ? compareRes * 1 : compareRes * -1;
    }
  };
  return sortDirection === "NONE"
    ? _initialRows
    : [..._initialRows].sort(comparer);
};

export function debounceWithDefault(cb, delay = 500) {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

export function getTextWidth(txt, font) {
  let element = document.createElement("canvas");
  let context = element.getContext("2d");
  context.font = font;
  return context.measureText(txt).width;
}
