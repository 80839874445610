import React, { Component } from "react";
import { styles } from "../../assets/styles/jss/components/preferensi-database";
import withStyles from "react-jss";
import { Input, DatePicker, Form } from "antd";
import moment from "moment";
import { getAll } from "../../utility/api/crud";
import { ErrorMessage } from "../../utility/functions/publicFunctions";
import { connect } from "react-redux";
import autoBind from "react-autobind";
import { withNamespaces } from "react-i18next";

class FormPerusahaan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      daftar: {
        mataUang: [],
      },
    };
    autoBind(this);
  }

  loadDataMataUang() {
    const { tenant } = this.props;
    let id = tenant.tenantId.dataSourceKey;
    let getCurrency = getAll(id, "MataUang");
    getCurrency
      .then((ress) => {
        this.setState({
          fetching: true,
          daftar: {
            ...this.state.daftar,
            mataUang: ress.listdata,
          },
        });
      })
      .catch((error) => {
        ErrorMessage(error);
      });
  }
  render() {
    const { parentState, handle, tenant, t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <header className="header-info-perusahaan">
          <h3 className="welcome_title">
            {t("Selamat datang ")} {tenant.userId.name} {t(" di Easy Cloud")}
          </h3>
          <desc className="welcome_desc">
            {t("Lengkapi informasi perusahaan Anda dibawah ini!")}
          </desc>
        </header>
        <div className="content-preference">
          <form className="form__info-perusahaan">
            <div>
              <div className="form-item-prep">
                <label aria-required="true">{t("Nama Perusahaan")}</label>
                <div>
                  {getFieldDecorator("companyname", {
                    rules: [
                      {
                        required: true,
                        message: t("Nama Perusahaan harus diisi."),
                      },
                    ],
                  })(
                    <Input
                      required
                      size="small"
                      name="companyname"
                      onChange={(e) => handle.onChange(e, "pcompany")}
                      value={
                        parentState ? parentState.pcompany.companyname : null
                      }
                      defaultValue={
                        parentState ? parentState.pcompany.companyname : null
                      }
                    />
                  )}
                </div>
              </div>
              <div className="form-item-prep">
                <label aria-required="true">{t("Bidang Usaha")}</label>
                <div>
                  {getFieldDecorator("businesstype", {
                    rules: [
                      {
                        required: true,
                        // message: "Please input your Business type",
                        message: "Bidang Usaha Harus Diisi",
                      },
                    ],
                  })(
                    <Input
                      size="small"
                      name="businesstype"
                      onChange={(e) => handle.onChange(e, "pcompany")}
                      value={
                        parentState ? parentState.pcompany.businesstype : null
                      }
                      defaultValue={
                        parentState ? parentState.pcompany.businesstype : null
                      }
                    />
                  )}
                </div>
              </div>
              <div className="form-item-prep">
                <label aria-required="true">{t("Alamat")}</label>
                <div>
                  {getFieldDecorator("addressline1", {
                    rules: [
                      {
                        required: true,
                        // message: "Please input Address",
                        message: t("Alamat harus diisi."),
                      },
                    ],
                  })(
                    <Input.TextArea
                      required
                      size="small"
                      name="addressline1"
                      maxlength="100"
                      onChange={(e) => handle.onChange(e, "pcompany")}
                      value={
                        parentState ? parentState.pcompany.addressline1 : null
                      }
                    />
                  )}
                  {getFieldDecorator("addressline2", {
                    rules: [
                      {
                        required: true,
                        // message: "Please input Address",
                        message: t("Alamat harus diisi."),
                      },
                    ],
                  })(
                    <Input
                      size="small"
                      placeholder={t("Kabupaten/Kota")}
                      name="addressline2"
                      maxlength="100"
                      onChange={(e) => handle.onChange(e, "pcompany")}
                      value={
                        parentState ? parentState.pcompany.addressline2 : null
                      }
                    />
                  )}

                  <div className="separated-field">
                    {getFieldDecorator("addressline3", {
                      rules: [
                        {
                          required: true,
                          message: t("Provinsi harus diisi."),
                        },
                      ],
                    })(
                      <Input
                        size="small"
                        className="field"
                        placeholder={t("Provinsi")}
                        name="addressline3"
                        onChange={(e) => handle.onChange(e, "pcompany")}
                        value={
                          parentState ? parentState.pcompany.addressline3 : null
                        }
                      />
                    )}
                    {getFieldDecorator("  ", {
                      rules: [
                        {
                          required: true,
                          message: t("Kode Pos harus diisi."),
                        },
                      ],
                    })(
                      <Input
                        size="small"
                        className="field"
                        placeholder={t("Kode Pos")}
                        name="addressline4"
                        onChange={(e) => handle.onChange(e, "pcompany")}
                        value={
                          parentState ? parentState.pcompany.addressline4 : null
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="form-item-prep">
                <label>{t("Telepon")}</label>
                <div>
                  <Input
                    size="small"
                    className="field"
                    name="notelp"
                    onChange={(e) => handle.onChange(e, "pcompany")}
                    // value={parentState ? parentState.pcompany.phoneno : null}
                    // value={tenant.userId.notelp}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-item-prep">
                <label>Fax</label>
                <div>
                  <Input
                    size="small"
                    className="field"
                    name="faxno"
                    onChange={(e) => handle.onChange(e, "pcompany")}
                    value={parentState ? parentState.pcompany.faxno : null}
                  />
                </div>
              </div>
              <div className="form-item-prep">
                <label>Email</label>
                <div>
                  <Input
                    readOnly
                    value={tenant.userId.email}
                    size="small"
                    className="field"
                  />
                </div>
              </div>
              <div className="form-item-prep">
                <label aria-required="true">{t("Tgl Awal Data")}</label>
                <div>
                  {getFieldDecorator("startdate", {
                    rules: [
                      {
                        required: true,
                        // message: "Please input start date",
                        message: t("Tgl Awal harus diisi."),
                      },
                    ],
                  })(
                    <DatePicker
                      className="field"
                      size="small"
                      name="startdate"
                      onChange={(date, dateString) =>
                        handle.onChange(
                          {
                            target: {
                              name: "startdate",
                              value: dateString,
                            },
                          },
                          "pcompany"
                        )
                      }
                      format={this.props.formatDate}
                      value={
                        parentState.pcompany.startdate
                          ? moment(
                              parentState.pcompany.startdate,
                              this.props.formatDate
                            )
                          : null
                      }
                    />
                  )}
                </div>
              </div>
              <div className="form-item-prep">
                <label aria-required="true">{t("Tahun Fiskal")}</label>
                <div>
                  {getFieldDecorator("fiscalyear", {
                    rules: [
                      {
                        required: true,
                        // message: "Please input your fiscal year",
                        message: t("Tahun Fiskal harus diisi."),
                      },
                    ],
                  })(
                    <Input
                      size="small"
                      className="field"
                      name="fiscalyear"
                      onChange={(e) => handle.onChange(e, "pcompany")}
                      value={
                        parentState ? parentState.pcompany.fiscalyear : null
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Global, Tenants }) => ({
  formatDate: Global.formatDate,
});
const InfoPerusahaan = Form.create({ name: "info_perusahaan" })(FormPerusahaan);
export default withNamespaces("translations")(
  connect(mapStateToProps, null)(withStyles(styles)(InfoPerusahaan))
);
