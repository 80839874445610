import * as types from '../../constants';

const initialState = {
    status: false,
    form: []
};

export default function daftar_akun (state = initialState, action) {
    switch (action.type) {
        case types.ADD_AKUN:
            return {
                ...state,
                [action.key]: action.value            
            }  
        case types.ON_LOAD_PAGE:
            return {
                ...state,
                submit: action.payload
            }
        case types.RESET:
            return initialState     
        default:
            return state;
    }
}