export const initialState = (formatDate, moment) => {
  return {
		cellSelected: { onidx: 0, onrowIdx: 0 },
		body: {
			details: [
				{
					cost: 0,
					id: 0,
					itemhistid: 0,
					itemno: "",
					itemreserved1: "",
					itemreserved10: "",
					itemreserved2: "",
					itemreserved3: "",
					itemreserved4: "",
					itemreserved5: "",
					itemreserved6: "",
					itemreserved7: "",
					itemreserved8: "",
					itemreserved9: "",
					notes: "",
					portion: 0,
					prodresultid: 0,
					qtycontrol: 0,
					quantity: 0,
					seq: 0,
					totalcost: 0,
					unit: "",
					unitratio: 1,
					warehouseid: 0,
					wodetid: 0,
				},
			],
			master: {
				approved: false,
				branchcodeid: 0,
				checknote: false,
				description: "",
				follow: "",
				followup: false,
				glhistid: 0,
				id: 0,
				idnext: 0,
				idprev: 0,
				importedtransactionid: 0,
				noteid: 0,
				notes: "",
				reserved1: "",
				reserved2: "",
				reserved3: "",
				reserved4: "",
				resultdate: moment().format(formatDate),
				resultno: "",
				templateid: 0,
				transactionid: 0,
				userid: 0,
				warehouseid: 0,
				wipaccount: "",
				woid: null,
				link01: "",
				link02: "",
				link03: "",
				link04: "",
				link05: "",
				link06: "",
				link07: "",
				link08: "",
				link09: "",
				link10: "",
				link11: "",
				link12: "",
				link13: "",
				link14: "",
				link15: "",
				link16: "",
				link17: "",
				link18: "",
				link19: "",
				link20: "",
			},
			templatedetails: [],
		},
		insert: true,
		hasChange: false,
		judul: "Data Baru",
		dataTemplate: [],
	};
};
