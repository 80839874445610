import { arrayMove } from "react-sortable-hoc";
import {
  createFieldReport,
  createFieldReportPerbangindan,
} from "utility/functions/publicFunctions";
import * as types from "../../constants";

let executed = [];
function addRemoveField(state, key, targetKeys) {
  let currentState = state[key].isOpenLaporan;

  let lamaUseddetails = currentState.data.useddetails.filter((x) =>
    targetKeys.includes(x.key)
  );

  let lastGrouporder =
    lamaUseddetails?.filter((el) => el.grouped === true) || 0;
  let tersediaTargetkey = lamaUseddetails.map((x) => x.key);
  let sisaTargetKeys = targetKeys.filter((x) => !tersediaTargetkey.includes(x));
  const reportRes = executed.find((el) => el.key === key);

  const recursion = (arr, parentAlias = null) => {
    const res = [];
    arr.forEach((el) => {
      if (!reportRes) {
        el.field_alias_ind = `${el.field_alias_ind}${
          parentAlias ? " " + parentAlias : ""
        }`;
        // el.title = `${el.title}${parentTitle ? " " + parentTitle : ""}`;
      }

      if (el.children.length > 0) {
        return res.push({
          ...el,
          children: recursion(el.children, el.field_alias_ind),
        });
      }

      return res.push({ ...el });
    });
    return res;
  };

  let detailsWithParent = recursion(currentState.data.details);

  if (reportRes) {
    detailsWithParent = reportRes.data;
  }

  if (!reportRes) {
    executed.push({
      key,
      data: detailsWithParent,
    });
  }

  const findItemNested = (arr, itemKey, nestingKey) =>
    arr.reduce((a, item) => {
      if (a) return a;
      if (item.key === itemKey) return item;
      if (item[nestingKey])
        return findItemNested(item[nestingKey], itemKey, nestingKey);
    }, null);

  const newUseddetails = [];

  sisaTargetKeys.forEach((el) => {
    const newUsedDetail = findItemNested(detailsWithParent, el, "children");
    if (!newUsedDetail.groupsort) {
      newUsedDetail.groupsort = "ASC";
    }
    if (newUsedDetail.options === null) {
      newUsedDetail.options = "||||";
    }
    if (!newUsedDetail.grouporder) {
      lastGrouporder = lastGrouporder.sort(
        (a, b) => a.grouporder - b.grouporder
      );
      newUsedDetail.grouporder =
        lastGrouporder.length > 0
          ? lastGrouporder[lastGrouporder.length - 1].grouporder + 1
          : 1;
    }
    newUseddetails.push(newUsedDetail);
  });

  let isOpenLaporan = {
    ...currentState,
    data: {
      ...currentState.data,
      // useddetails: [...lamaUseddetails, ...baruUseddetails],
      useddetails: [...lamaUseddetails, ...newUseddetails],
    },
  };
  return {
    ...state,
    [key]: {
      ...state[key],
      isOpenLaporan,
      targetKeys,
    },
  };
}

function changeField(state, key, e) {
  let currentState = state[key].isOpenLaporan;

  let selectColumn = currentState.data.useddetails;

  selectColumn[e.target.index][e.target.type] = e.target.value;
  // if (e.target.name === "calcfield") {
  //   selectColumn[e.target.index].keyCol = [...e.target.keyCol];
  // }

  if (e.target.type === "grouped" && e.target.value === true) {
    selectColumn[e.target.index].grouporder = e.target.lastLength;
  }

  if (e.target.type === "grouporder") {
    selectColumn[e.target.index].grouporder = e.target.value;
    selectColumn[e.target.index2].grouporder = e.target.value2;
  }

  let stateFocus = e.target.stateFocus !== undefined ? e.target.stateFocus : -1;

  let isOpenLaporan = {
    ...currentState,
    data: {
      ...currentState.data,
      useddetails: selectColumn,
    },
    stateFocus,
  };

  return {
    ...state,
    [key]: {
      ...state[key],
      isOpenLaporan,
    },
  };
}

function changeFieldPivot(state, key, e) {
  let currentState = state[key].isOpenLaporan;
  let selectColumn = currentState.data.useddetails;
  let newSelectColumn = [];

  selectColumn.map((xx) => {
    if (xx.field_id === e.target.fieldid) {
      xx[e.target.type] = e.target.value;
    }
    newSelectColumn.push(xx);
    return null;
  });

  let isOpenLaporan = {
    ...currentState,
    data: {
      ...currentState.data,
      useddetails: newSelectColumn,
    },
  };

  return {
    ...state,
    [key]: {
      ...state[key],
      isOpenLaporan,
    },
  };
}

function onSortEnd(state, key, { oldIndex, newIndex }, pivot, row) {
  let currentState = state[key].isOpenLaporan;
  let selectColumn = currentState.data.useddetails;
  let newSelectColumn = [];

  if (pivot) {
    let columns = [];
    let rows = [];
    let unVisible = [];
    selectColumn.map((xx) => {
      if (xx.visible) {
        if (xx.field_type === "integer" || xx.field_type === "currency") {
          columns.push(xx);
        } else {
          rows.push(xx);
        }
      } else {
        unVisible.push(xx);
      }
      return null;
    });

    if (row) {
      rows = arrayMove(rows, oldIndex, newIndex);
    } else {
      columns = arrayMove(columns, oldIndex, newIndex);
    }

    rows.push(...columns);
    unVisible.unshift(...rows);
    newSelectColumn.push(...unVisible);
  } else {
    selectColumn = arrayMove(selectColumn, oldIndex, newIndex);
  }

  let isOpenLaporan = {
    ...currentState,
    data: {
      ...currentState.data,
      useddetails: pivot ? newSelectColumn : selectColumn,
    },
  };

  return {
    ...state,
    [key]: {
      ...state[key],
      isOpenLaporan,
    },
  };
}

function changeIdFilter(state, key, e) {
  return {
    ...state,
    [key]: {
      ...state[key],
      idFilter: e,
      isOpenLaporan: {
        ...state[key].isOpenLaporan,
        stateFocus: -1,
      },
    },
  };
}

function changeParamater(state, key, e) {
  let currentState = state[key].isOpenLaporan;

  let parameters = {
    ...currentState.data.parameters,
    [e.target.name]: e.target.value,
  };

  let isOpenLaporan = {
    ...currentState,
    data: {
      ...currentState.data,
      parameters,
    },
  };

  // add field Report Multi
  let multiReportIDs = [81, 87, 279];
  let dataGenerate = {};
  if (multiReportIDs.includes(currentState.data.master.report_id)) {
    let { defaultField, targetKeys, transferDataSource } = state[key];
    dataGenerate = createFieldReport(
      defaultField,
      isOpenLaporan,
      targetKeys,
      transferDataSource
    );
  }
  //end add field Report Multi

  //add field Report Perbandingan
  let perbandinganReportIDs = [82, 88];
  if (perbandinganReportIDs.includes(currentState.data.master.report_id)) {
    let { defaultField, targetKeys, transferDataSource } = state[key];
    dataGenerate = createFieldReportPerbangindan(
      defaultField,
      isOpenLaporan,
      targetKeys,
      transferDataSource
    );
  }
  //end field Report Perbandingan

  return {
    ...state,
    [key]: {
      ...state[key],
      isOpenLaporan,
      ...dataGenerate,
    },
  };
}

function changeOtherParamater(state, key, e) {
  let currentState = state[key].isOpenLaporan;

  let otherparam = currentState.data.otherparam;

  otherparam[e.target.index] = {
    ...otherparam[e.target.index],
    [e.target.name]: e.target.value,
  };
  let isOpenLaporan = {
    ...currentState,
    data: {
      ...currentState.data,
      otherparam,
    },
  };

  return {
    ...state,
    [key]: {
      ...state[key],
      isOpenLaporan,
    },
  };
}

function changeMaster(state, key, e) {
  let currentState = state[key].isOpenLaporan;

  let master = {
    ...currentState.data.master,
    [e.target.name]: e.target.value,
  };

  let isOpenLaporan = {
    ...currentState,
    data: {
      ...currentState.data,
      master,
    },
  };

  return {
    ...state,
    [key]: {
      ...state[key],
      isOpenLaporan,
    },
  };
}

function generateUsedField(state) {
  return state;
}

export default function Laporan(state = [], action) {
  switch (action.type) {
    case types.ADD_MODIF_LAPORAN:
      return {
        ...state,
        ...action.body,
      };
    case types.ADD_REMOVE_FIELD:
      return addRemoveField(state, action.key, action.payload);
    case types.CHANGE_FIELD:
      return changeField(state, action.key, action.payload);
    case types.SORT_FIELD:
      return onSortEnd(
        state,
        action.key,
        action.payload,
        action.pivot,
        action.row
      );
    case types.CHANGE_ID_FILTER:
      return changeIdFilter(state, action.key, action.payload);
    case types.CHANGE_PARAMETER:
      return changeParamater(state, action.key, action.payload);
    case types.CHANGE_OTHERPARAMETER:
      return changeOtherParamater(state, action.key, action.payload);
    case types.GENERATE_USED_FIELD:
      return generateUsedField(state);
    case types.CHANGE_MASTER:
      return changeMaster(state, action.key, action.payload);
    case types.DELETE_MODIF_LAPORAN:
      delete state[action.key];
      return { ...state };
    case types.RESET:
      return [];
    case types.CHANGE_FIELD_PIVOT:
      return changeFieldPivot(state, action.key, action.payload);
    default:
      return state;
  }
}
