export const Pemasok = (startDate) => {
  return {
    body: {
      multiOpeningBalances: [],
      useCnt: false,
      dsRequest: "",
      activefrom: "",
      addressline1: "",
      addressline2: "",
      allowbackorders: false,
      balance: 0,
      barcodecust: "",
      billtono: "",
      billtoonly: true,
      city: "",
      contact: "",
      country: "",
      creditlimit: 0,
      creditlimitday: 0,
      currencyid: 1,
      customertypeid: 0,
      defaultdisc: "",
      defaultinvdescription: "",
      email: "",
      fax: "",
      id: 0,
      link01: "",
      link02: "",
      link03: "",
      link04: "",
      link05: "",
      link06: "",
      link07: "",
      link08: "",
      link09: "",
      link10: "",
      link11: "",
      link12: "",
      link13: "",
      link14: "",
      link15: "",
      link16: "",
      link17: "",
      link18: "",
      link19: "",
      link20: "",
      multisaldoawal: 0,
      name: "",
      nik: "",
      notes: "",
      personmessage: "",
      personno: "",
      persontype: 1,
      phone: "",
      pricelevel: 0,
      printstatement: false,
      salesmanid: 0,
      stateprov: "",
      statid: 0,
      suspended: false,
      tax1exemptionno: "",
      tax1id: 0,
      tax2exemptionno: "",
      tax2id: 0,
      taxaddress1: "",
      taxaddress2: "",
      termsid: 1,
      webpage: "",
      zipcode: "",
      obdate: startDate,
      openingbal: 0,
      detailbranch: [],
      detailuser: [],
      allusers: true,
    },
    judul: "Data Baru",
    dataStatusPerson: [],
    dataSyarat: [],
    dataMataUang: [],
    insert: true,
    hasChange: false,
  };
};
