import { PrivateKey_FCM } from "../../constants";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import configFirebase from "./config.json";
const app = initializeApp(configFirebase);

export const getTokenFCM = async (setToken) => {
  try {
    const support = await isSupported();
    if (support) {
      const messaging = getMessaging(app);
      getToken(messaging, {
        vapidKey: PrivateKey_FCM,
        // serviceWorkerRegistration: sw,
      })
        .then((currentToken) => {
          setToken(currentToken);
        })
        .catch((err) => {
          setToken(null);
          //gagal mendapatkan token
        });
    } else {
      setToken(null);
    }
  } catch (error) {
    //gagal fetching token
    setToken(null);
  }
};
