import React from "react";
import "../../../assets/styles/css/components/modal.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as ic from "../../../constants/icons";
function Alert({ okText, onClose, description, mask }) {
  return (
    <div className={mask ? "modal" : "modal-alert"}>
      <div className="modal-main-alert">
        <div className={`header-alert`}>
          <div className={`modal-title `}>Easy Cloud</div>
        </div>
        <div className="body-modal notification-modal">
          <img src={ic.RoboWarning} alt="robo" className="icon_notification" />
          <div className="content-notification">
            <desc>{description}</desc>
            <div className="footer-modal">
              <button onClick={onClose} className="btn-modal btn-modal-submit">
                {okText ? okText : "Ok"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Alert.propTypes = {};

export default Alert;
