export const initialState = (formatDate, moment, key) => {
  return {
    loading: false,
    loadingTable_salinTransaksi: false,
    validation: [],
    body: {
      details: [
        {
          accountname: "",
          accountname2: "",
          acctype: 0,
          deptid: 1,
          description: "",
          glaccount: "",
          glamount: "",
          jvid: "",
          personname: "",
          personno: "",
          primeamount: "",
          projectid: 1,
          rate: 1,
          reconcileid: 0,
          seq: 0,
          seqgl: 0,
          subsidiary: 0,
        },
      ],
      master: {
        approved: false,
        approvedesc: null,
        autoreversed: 0,
        checknote: false,
        cheqno: "",
        follow: "",
        followup: false,
        urgent: false,
        glhistid: 0,
        glperiod: 0,
        glyear: 0,
        idnext: 0,
        idprev: 0,
        imported: 0,
        isob: false,
        jvamount: 0,
        jvid: 0,
        jvnumber: "",
        lastid: 0,
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
        locked_by: "",
        locked_time: "",
        noteid: 0,
        openingbal: false,
        overbudget: true,
        paye: "",
        posted: 0,
        reconcileid: 0,
        source: "GL",
        templateid: 26,
        transdate: moment().format(formatDate),
        transdescription: "",
        transtype: "JV",
        userid: 0,
        recdetid: 0,
      },
      templatedetails: [],
    },
    dataDepartment: [],
    dataProject: [],
    insert: true,
    judul: "Data Baru",
    tabPane: "1",
    modal: {
      akun: false,
    },
    dataTemplate: [],
    hasChange: false,
  };
};
