const appFrame = {
  width: "100%",
  // minHeight: "100%",
  // height: "100vh",
};
export const styles = (theme) => ({
  body: {
    ...appFrame,
    backgroundSize: "cover",
    background: "#fff",
    height: "100%",
  },
  root: {
    width: "100%",
    minHeight: "100%",
    zIndex: 1,
  },
  container: {
    ...appFrame,
    zIndex: "10",
    height: "100%",
  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    body: {
      ...appFrame,
      background: "#fff",
      backgroundSize: "cover",
    },
  },
});
