const root = {
  color_white: "#fff",
};
const modalDef = (theme) => {
  return {
    "& .ant-table-thead": {
      padding: "5px",
    },
    "& .ant-modal-header": {
      background: theme.headerColor,
    },
    "& .ant-modal-header > .ant-modal-title": {
      fontWeight: 600,
    },
  };
};
export const styles = (theme) => ({
  camQR: {
    "& section": {
      position: "unset !important",
    },
  },
  body_modal: {
    padding: "10px 35px",
  },
  header_modal: {
    background: theme.labelColor,
  },
  footer_modal: {
    background: theme.labelColor,
    height: 35,
    borderRadius: "0 0 4px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  titleTemplate: {
    display: "none",
  },
  header_billing: {
    background: theme.headerColor,
  },
  modal: {
    ...modalDef(theme),
    ".ant-modal": {
      width: 800,
    },
  },
  modalPreview: {
    ...modalDef(theme),
    width: "800px !important",
    "& .ant-modal-body": {
      padding: 0,
    },
  },
  modalListGrafik: {
    ...modalDef(theme),
    width: "400px !important",
  },
  footerPreview: {
    background: theme.labelColor,
  },
  modalDashBoardMobile: {
    ...modalDef(theme),
    "& .ant-modal-body": {
      overflow: "auto",
    },
  },
  modal_filter: {
    ...modalDef(theme),
  },
  modalProfilePengguna: {
    ...modalDef(theme),
    width: "650px !important",
    "& .ant-modal-body": {
      height: 500,
      padding: 0,
    },
  },
  flex: {
    display: "flex",
    "& > * + *": {
      margin: "0 0 0 5px",
    },
  },
  searchContain: {
    display: "flex",
    marginBottom: 8,
    "& > * + * ": {
      margin: "0 0 0 5px",
    },
  },
  ModalItemGrouping: {
    "&.ant-modal": {
      // width: "auto !important",
    },
    "& .ant-modal-header": {
      background: theme.headerColor,
    },
    "& .react-grid-Container": {
      width: "100% !important",
    },
    "& .react-grid-Canvas": {
      width: "500px !important",
    },
  },
  modalPreviewTemplate: {
    "& .ant-table-thead": {
      padding: "5px",
    },
    "& .ant-modal-body": {
      height: 500,
      overflow: "auto",
    },
    "& .ant-modal-header": {
      background: theme.headerColor,
    },
  },
  innerBodyModalBtn: {
    height: 80,
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    "& > * + *": {
      margin: "0 0 0 5px",
    },
  },
  listGrafik: {
    display: "flex",
    flexDirection: "column",
    "& > .ant-checkbox-wrapper": {
      margin: 0,
    },
  },
  body_profilPengguna: {
    display: "grid",
    gridTemplateColumns: "250px 1fr",
    height: "100%",
  },
  sidebarProfile: {
    background: "#f5f5f5 ",
    position: "relative",
  },
  iconSidebar: {
    width: 20,
    height: 20,
  },
  sidebarMenu: {
    listStyleType: "unset",
    padding: 0,
    "& > li": {
      padding: "8px 15px",
      transition: "0.5s ease-in",
      "&:hover": {
        background: "#dcdcdc",
      },
    },
    "& > li > a": {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridGap: "15px",
      color: "#000",
      fontSize: "12pt",
    },
  },
  innerContainProfile: {
    padding: 30,
    height: "100%",
    "& > img": {
      width: 45,
      height: 45,
    },
  },
  activeSiebar: {
    background: "#dcdcdc",
  },
  photoProfile: {
    marginTop: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > img": {
      width: "120px",
      height: "120px",
    },
  },
  profile: {
    height: 280,
    position: "relative",
    padding: 20,
  },
  headerProfile: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
    "& > img": {
      width: "24px",
      height: "24px",
    },
    "& > label": {
      fontSize: "14pt",
      letterSpacing: "1.5px",
      fontWeight: 600,
    },
  },
  name: {
    marginTop: 15,
    fontWeight: 600,
    fontSize: "12pt",
    textAlign: "center",
  },
  form_myProfile: {
    height: "100%",
    position: "relative",
    "& .ant-form-item": {
      marginBottom: 0,
    },
    "& .ant-form-item-label": {
      lineHeight: "unset",
    },
  },
  btn_addTransaction: {
    display: "none",
    backgroundColor: theme.labelColor,
    color: root.color_white,
    "&:hover": {
      backgroundColor: theme.labelColor,
      borderRadius: "50%",
      color: root.color_white,
    },
    "&:focus": {
      backgroundColor: theme.labelColor,
      borderRadius: "50%",
      color: root.color_white,
    },
    "&:active": {
      backgroundColor: theme.labelColor,
      borderRadius: "50%",
      color: root.color_white,
    },
  },
  btn_ubah: {
    background: "#00AED6",
    color: "#fff",
    borderColor: "#00AED6",
    "&:hover": {
      background: "#4facbd",
      borderColor: "#4facbd",
      color: "#fff",
    },
    "&:focus": {
      background: "#4facbd",
      borderColor: "#4facbd",
      color: "#fff",
    },
  },
  btn_simpan: {
    color: "#fff",
    background: "#00AB14",
    borderColor: "#00AB14",
    "&:hover": {
      background: "#227d2d",
      borderColor: "#227d2d",
      color: "#fff",
    },
    "&:focus": {
      background: "#227d2d",
      borderColor: "#227d2d",
      color: "#fff",
    },
  },
  btn_batal: {
    color: "#fff",
    background: "#EE2737",
    borderColor: "#EE2737",
    "&:hover": {
      background: "#ba1a27",
      borderColor: "#ba1a27",
      color: "#fff",
    },
    "&:focus": {
      background: "#ba1a27",
      borderColor: "#ba1a27",
      color: "#fff",
    },
  },
  btn_wrapProfile: {
    position: "absolute",
    bottom: "30px",
    right: 0,
    display: "flex",
    "& > * + *": {
      margin: "0 0 0 15px",
    },
  },
  form: {
    "& .ant-form-item": {
      marginBottom: "5px",
    },
    "& .ant-form-item:nth-last-child(1)": {
      marginBottom: 0,
    },
    "& .ant-form-vertical .ant-form-item": {
      paddingBottom: "0px",
    },
    "& ant-form-item-control": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  menu_card: {
    width: "100%",
    height: "140px",
    borderRadius: "15px",
  },
  img_card: {
    width: "100%",
    textAlign: "center",
    "& img": {
      width: 65,
      height: 65,
    },
  },
  title_card: {
    textAlign: "center",
    width: "100%",
    fontSize: "1em",
    color: "#fff",
    fontWeight: 550,
  },
  pratinjau: {
    height: 300,
    padding: "1%",
    marginTop: 10,
    background: "#f5f5f5",
  },
  tableWrap: {
    "& .ant-table-body": {
      height: 400,
      maxHeight: "unset !important",
    },
    ...tableWrapMain(theme),
  },
  tableCNT: {
    ...tableWrapMain(theme),
    "& .ant-table-body": {
      minHeight: 400,
      maxHeight: "unset !important",
    },
  },
  tableModalBarang: {
    "& .ant-table-body": {
      height: 300,
      maxHeight: "unset !important",
    },
    ...tableWrapMain(theme),
  },
  tableWrap_tarikTransaksi: {
    "& .ant-table-body": {
      height: 400,
      maxHeight: "unset !important",
    },
    ...tableWrapMain(theme),
  },
  tableWrap_pemasok: {
    "& .ant-table-body": {
      height: 400,
      maxHeight: "unset !important",
    },
    ...tableWrapMain(theme),
  },
  section_penjualan_aktiva: {
    marginTop: 15,
  },
  ml_2rem: {
    marginLeft: "1rem",
  },
  mb_5: {
    marginBottom: 5,
  },
  modal_exchangerate: {
    padding: "15px 20px",
  },
  modal_pph: {
    padding: "10px 35px ",
  },
  title: {
    fontWeight: "bold",
  },
  itemInline: {
    display: "inline-block",
    margin: 3,
  },
  borderGroup: {
    padding: "10px 35px",
  },
  groupWrap: {
    padding: "3px 0px",
    display: "flex",
    fontSize: 15,
    fontWeight: 500,
  },
  label: {
    width: "200px",
  },
  field: {
    padding: "0px 10px",
    width: 200,
    color: "#000",
  },
  fieldLabel: {
    "& label": {
      color: "#000",
    },
  },
  border: {
    border: "1px solid",
  },
  fieldElement200: {
    width: 200,
  },
  fieldElement400: {
    width: 400,
  },
  grid: {
    display: "grid",
  },
  fieldError: {
    transition: "0.4s ease-in",
    padding: "3px",
    "& > span": {
      color: "red",
    },
  },
  gap_5: {
    gridGap: 5,
  },
  padding10: {
    padding: 10,
  },
  radius8: {
    borderRadius: 8,
  },
  template_50: {
    gridTemplateColumns: "1fr 1fr",
  },
  field_header_hakakses: {
    gridTemplateColumns: "1fr 1fr",
  },
  template_1fr_auto: {
    gridTemplateColumns: "1fr auto",
  },
  template_150_1fr: {
    gridTemplateColumns: "150px 1fr",
    gridGap: 10,
  },
  template_200_1fr: {
    gridTemplateColumns: "200px 1fr",
    gridGap: 10,
  },
  GroupAkunJobCost: {
    gridTemplateColumns: "200px 1fr",
    gridGap: 10,
  },
  table_control: {
    marginTop: 10,
    width: "100%",
    "& .react-grid-HeaderCell": {
      background: theme.columnColor,
      // background: theme.labelColor,
      color: "#fff",
      padding: "0px 8px",
    },
    "& .react-grid-Grid": {
      borderRadius: "5px",
    },
    "&  .drag-handle": {
      display: "none",
    },
    "& .widget-HeaderCell__value": {
      fontWeight: "400",
      fontSize: "10.5pt",
    },
    "& .react-grid-Cell__value": {
      fontSize: "9pt",
      color: "#000000",
    },
    "& .react-grid-Cell": {
      cursor: "pointer",
    },
  },
  salin_hak_akses: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: "10px",
  },
  modal_preference_db: {
    "& .ant-modal-body": {
      height: 500,
      overflow: "auto",
    },
    "& .ant-modal-header": {
      textAlign: "center",
      background: theme.headerColor,
    },
  },
  wrap_modal_preference_db: {},
  modal_preference: {
    width: "1000px !important",
    "& .ant-modal-body": {
      height: 500,
      overflow: "auto",
    },
    "& .ant-modal-header": {
      textAlign: "center",
      background: theme.headerColor,
    },
    "& .ant-modal-header > .ant-modal-title": {
      fontWeight: 600,
    },
    "& .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active": {
      color: theme.labelColor,
      fontWeight: 600,
    },
  },
  modal_riwayatUpdated: {
    ...modalDef(theme),
    width: "800px !important",
    "& .ant-modal-body": {
      overflowY: "auto",
      overflowX: "hidden",
      height: 600,
      padding: 0,
    },
  },
  modal_registration: {
    width: "800px !important",
    "& .ant-tabs-bar": {
      margin: 0,
    },
    "& .ant-modal-body": {
      overflow: "auto",
      padding: 0,
    },
    "& .ant-modal-header": {
      textAlign: "center",
      background: theme.headerColor,
    },
    "& .ant-modal-header > .ant-modal-title": {
      fontWeight: 600,
    },
    "& .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active": {
      color: theme.labelColor,
      fontWeight: 600,
    },
  },
  modal_statusHpp: {
    width: "300px !important",
    "& .ant-tabs-bar": {
      margin: 0,
    },
    "& .ant-modal-body": {
      overflow: "auto",
      padding: 0,
    },
    "& .ant-modal-header": {
      textAlign: "center",
      background: theme.headerColor,
    },
    "& .ant-modal-header > .ant-modal-title": {
      fontWeight: 600,
    },
    "& .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active": {
      color: theme.labelColor,
      fontWeight: 600,
    },
  },
  wrap_modal_preference: {},
  modal_plugin: {
    width: "900px !important",
    "& .ant-modal-body": {
      height: 550,
      overflow: "auto",
    },
    "& .ant-modal-header": {
      textAlign: "center",
      background: theme.headerColor,
    },
    "& .ant-modal-header > .ant-modal-title": {
      fontWeight: 600,
    },
    "& .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active": {
      color: theme.labelColor,
      fontWeight: 600,
    },
    "& .ant-tabs-bar": {
      margin: 0,
    },
  },
  containerCabang: {},
  tableCabang: {
    "& .ant-table-thead > tr > th, .ant-table-tbody > tr > td": {
      padding: "1px 8px !important",
      fontSize: "10pt",
    },
    "& .ant-table-tbody > tr > td": {
      // color: "#000000",
    },
    "& tr.ant-table-row": {
      cursor: "pointer",
    },
    "& .ant-table colgroup > col.ant-table-selection-col": {
      width: "30px",
    },
    "& .ant-table-thead": {
      backgroundColor: theme.columnColor,
      padding: 5,
    },
    "& .ant-table-small > .ant-table-content > .ant-table-body": {
      margin: 0,
      padding: "2px 2px",
    },
    "& .ant-table-thead > tr > th": {
      color: root.color_white,
      padding: "2px 2px",
      textAlign: "center !important",
    },
    "& .clickRowStyl": {
      backgroundColor: theme.labelColor + " !important",
      color: "white !important",
    },
    "& .ant-table-tbody>.clickRowStyl:hover>td": {
      backgroundColor: theme.labelColor + " !important",
      color: "white !important",
    },
  },
  content_persiapan_awal: {
    width: "calc(100% - 15em)",
    float: "right",
    overflow: "auto",
  },
  content_Preference: {
    width: "calc(100% - 20em)",
    float: "right",
    marginTop: 10,
    // height: 530,
  },
  layout_2_col: `
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    height: 32px;
  `,
  matauang_modal: `
    gap: 5px;
    display: grid;
    grid-gap: 10px;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
    margin-bottom: 5px;
    grid-template-columns: 80px 120px 200px 50px;
    align-items: center;
  `,
  header_sekilasEasy: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& > img": {
      height: "55px",
    },
  },
  versionNote: {
    width: "100%",
    "& > h3": {
      textAlign: "center",
      marginBottom: "0.2em",
      fontWeight: "bold",
    },
    "& > h4": {
      textAlign: "center",
      fontWeight: "bold",
    },
  },
  credit: {
    marginTop: 30,
    width: "100%",
    "& > h4": {
      textAlign: "center",
      color: "#000",
    },
    "& > p": `
      text-align: center;
      width: 590px;
      margin: 0 auto;
      color: #000;
    `,
  },
  contact: {
    marginTop: 50,
    "& > h4": {
      textAlign: "center",
      color: "#000",
      marginBottom: "0.1em",
    },
    "& > h5": {
      textAlign: "center",
      color: "#000",
      marginBottom: "0.1em",
    },
  },
  copyright: {
    "& > h4": {
      textAlign: "right",
      color: "#000",
    },
  },
  web: {
    textAlign: "center",
    marginBottom: 0,
    color: "#fff",
    fontWeight: 600,
  },
  sectionSekilasEasy: {
    padding: "15px",
  },
  formItemProfil: {
    boxSizing: "border-box",
  },
  ic_copy: {
    display: "none",
  },
  btn_salin_transaksi: {
    display: "block",
    "& i": {
      display: "none",
    },
  },
  positionOne: {
    display: "block",
  },
  positionTwo: {
    display: "flex",
    flexWrap: "wrap",
    gridGap: "10px",
    minHeight: 500,
    alignItems: "center",
    justifyContent: "center",
  },
  searchBarCNT: {
    display: "flex",
    marginBottom: "1em",
  },
  requestTable: {
    width: 400,
  },
  mappingTable: {
    width: 400,
  },
  CNT_content: {
    minHeight: 500,
  },
  personMapping: {
    width: "100%",
  },
  dropdownPerson: {
    border: "1px solid rgba(142, 142, 142, 0.65)",
    "& .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled), .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled)":
      {
        backgroundColor: theme.labelColor,
        color: root.color_white,
      },
    "& .ant-select-dropdown-menu-item": {
      color: "#000",
    },
  },
  select_item_3_col: {
    width: "100%",
    display: "grid",
    "& span": {
      // textOverflow: "ellipsis",
      // overflow: "hidden",
      width: "100%",
    },
    gridTemplateColumns: "100px 20px 1fr",
    "& > span": {
      fontSize: "9.5pt",
    },
  },
  fromCompany: {
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    alignItems: "flex-start",
    fontSize: "12pt",
    fontWeight: "700",
    marginTop: 8,
    "& > label": {
      color: "#000000",
    },
  },
  tokenField: {
    display: "block",
    textAlign: "right",
  },
  wrapButtonToken: {
    marginTop: 8,
  },
  mappingData: {
    marginTop: "18px",
    "& h3": {
      display: "block",
      borderBottom: "1px solid black",
      fontWeight: "bold",
    },
  },
  bodyStatusCNT: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    height: "500px",
    overflow: "auto",
  },
  statusProduct: {
    display: "block",
    padding: 5,
    position: "relative",
  },
  product: {
    display: "block",
    padding: 5,
  },
  headerProduct: {
    marginBottom: 5,
    "& h3": {
      color: "#000000",
      fontSize: "12pt",
    },
  },
  contentStatus: {
    height: "300px",
    border: "1px solid #c6c6c6",
    backgroundColor: "#f0f0f0",
    borderRadius: 8,
    overflow: "hidden",
    padding: 8,
    "& > ul": {
      overflow: "auto",
      height: "100%",
    },
  },
  keteranganStatus: {
    display: "block",
    height: 80,
    width: "100%",
    borderTop: "1px dashed #c6c6c6",
    paddingTop: 5,
    marginTop: 10,
  },
  fieldKeterangan: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "30px",
  },
  invoiceStatus: {
    fontWeight: "700",
    color: theme.hoverColor,
  },
  innerDetailBarang: {
    height: "450px",
    overflow: "hidden",
    "& > ul": {
      height: "100%",
      overflow: "auto",
    },
  },
  innerListContact: {
    marginTop: "20px",
    height: "400px",
    overflowY: "auto",
  },
  fetchingLoading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "10px",
  },
  /**
   * @description mobile version
   */
  [`@media (max-width: 480px)`]: {
    modal: {
      "& .ant-modal-body": {
        padding: "15px 10px !important",
      },
    },
    credit: {
      "& > p": `
        width: 300px;
      `,
    },
    copyright: {
      marginTop: 10,
    },
    titleTemplate: `
      width: 100%;
      display: block;
      padding: 10px;
      font-size: 14pt;
      font-weight: bold;
      background: white;
      margin-bottom: 5px;
      box-shadow: 0 4px 2px -2px #d2d2d2;
    `,
    body_profilPengguna: {
      display: "block",
    },
    sidebarProfile: {
      height: "min-content",
    },
    photoProfile: {
      marginTop: 0,
      "& > img": {
        height: "75px",
        width: "75px",
      },
    },
    profile: {
      height: "auto",
    },
    name: {
      margin: "5px 0",
      fontSize: "11pt",
    },
    modalProfilePengguna: {
      "& .ant-modal-body": {
        height: "550px",
        padding: 0,
      },
    },
    form_myProfile: {
      "& .ant-row::before, .ant-row::after": {
        content: "unset",
      },
    },
    formItemProfil: {
      display: "grid",
      gridTemplateColumns: "100px 1fr",
      alignItems: "center",
    },
    headerProfile: {
      display: "none",
    },
    innerContainProfile: {
      padding: "15px 10px",
      height: "280px",
    },
    btn_wrapProfile: {
      bottom: 0,
    },
    fieldElement200: {
      width: "100%",
    },
    fieldElement400: {
      width: "100%",
    },
    GroupAkunJobCost: {
      gridTemplateColumns: "1fr",
    },
    btn_addTransaction: {
      display: "block",
    },
    field_header_hakakses: {
      gridTemplateColumns: "1fr",
    },
    ic_copy: {
      display: "block",
    },
    label_btn_salin: {
      display: "none",
    },
    btn_salin_transaksi: {
      height: 32,
      width: 32,
      padding: 2,
      "& span": {
        display: "none !important",
      },
      "& i": {
        display: "block",
      },
    },
  },
  titleMapping: {
    fontWeight: 700,
    marginBottom: 8,
    color: "#000000",
  },
  totalFaktur: {
    display: "flex",
    fontWeight: 700,
    color: "#000000",
  },
  [`@media screen and (min-width: 321px) and (max-width: 768px) `]: {
    content_Preference: {
      width: "calc(100% - 4em)",
      float: "right",
      marginTop: 10,
    },
    content_persiapan_awal: {
      width: "calc(100% - 4em)",
    },
    bodyStatusCNT: {
      display: "block",
    },
    keteranganStatus: {
      height: "auto",
    },
  },
  modal_simpanTransaksi: {
    display: "flex",
    paddingTop: 10,
  },
  input_noMemorize: {
    padding: 5,
  },
  input_descMemorize: {
    padding: 5,
  },
});
const tableWrapMain = (theme) => {
  return {
    "& .ant-table-thead > tr > th, .ant-table-tbody > tr > td": {
      padding: "1px 3px !important",
      fontSize: "10pt",
    },
    "& .ant-table-thead > tr > th": {
      backgroundColor: `${theme.columnColor} !important`,
      // fontWeight: 600,
      color: "#fff",
    },
    "& tr.ant-table-row": {
      cursor: "pointer",
    },
    "& .ant-table-small > .ant-table-content > .ant-table-body": {
      margin: "unset",
    },
    "& .ant-table colgroup > col.ant-table-selection-col": {
      width: 30,
    },
    "& .ant-table-tbody > tr > td": {
      fontWeight: 600,
    },
    "& .clickRowStyl": {
      backgroundColor: theme.labelColor + " !important",
      color: "white !important",
    },
    "& .ant-table-tbody > .clickRowStyl:hover > td": {
      backgroundColor: theme.labelColor + " !important",
      color: "white !important",
    },
  };
};
