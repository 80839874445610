import * as types from "../../constants";

const initialState = {
  theme: "old",
  mode: "light",
  color: "themeGreen"
};


export default function Theme(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_THEME:
      return {
        ...state,
        theme: action.theme,
      };
    case types.CHANGE_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case types.CHANGE_COLOR:
      return {
        ...state,
        color: action.payload,
      };
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}
