import * as types from "../../constants";
const initialState = {
  databaseOpened: null,
  countBill_unpayed: 0,
  link: null,
};
export default function Billing(state = initialState, action) {
  switch (action.type) {
    case types.BILL_UNPAYED:
      return {
        ...state,
        countBill_unpayed: action.payload.countAlert,
        link: action.payload.link,
      };
    default:
      return state;
  }
}
