import * as types from '../../constants';

export default function master_data(state = [], action) {
    switch (action.type) {        
        case types.ADD_MASTERDATA:
            return {
                ...state, ...action.body
            }
        case types.RESET:
            return []    
        case types.ADD_PELANGGAN:
            return {
                ...state, 
                [action.key]: action.value
            }
        default:
            return state
    }
}