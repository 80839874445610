import { TidakMenyusut } from "../../../constants";
export const initialState = (formatDate, moment, key) => {
  return {
    modal_penghentian_aktiva: false,
    fetchingTipeAktiva: false,
    fetchingDepartemen: false,
    loading_save: false,
    validation: [],
    daftar: {
      tipeAktiva: [],
      departemen: [],
    },
    body: {
      details: [
        {
          assetid: 0,
          description: "",
          detassetcode: "",
          excassetid: 0,
          expdate: null,
          glaccount: "",
          glamount: 0,
          reconcileid: 0,
          seq: 0,
        },
      ],
      master: {
        acmdepracc: "",
        acquisitionhistid: 0,
        assetacc: "",
        assetcode: "",
        assetcost: 0,
        assetfistype: 0,
        assetid: 0,
        assetname: "",
        assettype: "",
        depmethod: TidakMenyusut,
        depreciationrate: 0,
        deprexpacc: "",
        deptid: 1,
        disposed: false,
        disposedhistid: 0,
        estimatedlife: 0,
        years: 0,
        month: 0,
        fisasset: true,
        idnext: 0,
        idprev: 0,
        imported: 0,
        lastjournaldate: null,
        locked_by: "",
        locked_time: null,
        notes: "",
        purchasedate: moment().format(formatDate),
        residu: 0,
        templateid: 19,
        usagedate: moment().format(formatDate),
        depramount: 0,
        bookvalue: 0,
      },
      templatedetails: [],
    },
    insert: true,
    judul: "Data Baru",
    idkey: key,
    hasChange: false,
  };
};
