import { request } from "./api";
import { ACCESS_TOKEN, API_BASE_URL } from "../../constants";

export function login(loginRequest) {
  return request({
    url: `${API_BASE_URL}/auth/signin`,
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function loginAdmin(loginRequest) {
  return request({
    url: `${API_BASE_URL}/auth/signinadm`,
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set");
  }
  return request({
    url: `${API_BASE_URL}/user/me`,
    method: "GET",
  });
}

export function signup(signupRequest) {
  return request({
    url: `${API_BASE_URL}/auth/signup`,
    method: "POST",
    body: JSON.stringify(signupRequest),
  });
}

export function checkUsernameAvailability(username, email) {
  return request({
    url: `${API_BASE_URL}/user/checkUsernameAvailability?username=${username}&email=${email}`,
    method: "GET",
  });
}

export function checkEmailAvailability(email) {
  return request({
    url: `${API_BASE_URL}/user/checkEmailAvailability?email=${email}`,
    method: "GET",
  });
}

export function updateTheme(theme, color) {
  return request({
    url: `${API_BASE_URL}/theme-user/${theme}/${color}`,
    method: "PUT",
  });
}
export function verificationEmail(body) {
  console.info(body);
  return request({
    url: `${API_BASE_URL}/auth/users/verify`,
    method: "POST",
    body: JSON.stringify(body),
  });
}
export function verificationEmailGET(param) {
  console.info(param);
  return request({
    url: `${API_BASE_URL}/auth/users/verify?code=${param}`,
    method: "GET",
  });
}
export function forgotEmail(body) {
  return request({
    url: `${API_BASE_URL}/auth/forgetpassword`,
    method: "POST",
    body: JSON.stringify(body),
  });
}
export function resendEmail(email) {
  return request({
    url: `${API_BASE_URL}/auth/users/resendverify`,
    method: "POST",
    body: JSON.stringify(email),
  });
}
export function createNewPassword(body) {
  return request({
    url: `${API_BASE_URL}/auth/users/creatednewpassord`,
    method: "POST",
    body: JSON.stringify(body),
  });
}
export function checkCurrentPassword(email, password) {
  return request({
    url:
      `${API_BASE_URL}/user/checkCurrentPassword?email=${email}&password=${password}`,
    method: "GET",
  });
}

export function ChangePasswordUser(body) {
  return request({
    url: `${API_BASE_URL}/users/changenewpassord`,
    method: "POST",
    body: JSON.stringify(body),
  });
}

export function UpdateProfile(body) {
  return request({
    url: `${API_BASE_URL}/user/update-user`,
    method: "PUT",
    body: JSON.stringify(body),
  });
}
