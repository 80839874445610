import * as svg from "./icons";
import * as comp from "./comps";

/**
 * @description admin
 */

export const Plugin = {
	create: "plugin",
	readall: "plugin",
	installed: "plugin-addons-tenant",
	uninstall: "plugin",
	installall: "all-plugin",
};

export const BillingItems = {
	create: "billitems",
	update: "billitems",
	readall: "billitems-adm",
};

export const ReqActBillItems = {
	readall: "billitems",
};

export const RequestActivation = {
	create: "request-activation",
	update: "request-activation",
	readall: "request-activation",
};

export const RegistrasiEPS = {
	create: "request-activation",
	update: "request-activation",
	readall: "request-activation",
};

export const Voucher = {
	create: "voucher",
	update: "voucher",
	readall: "voucher",
	coupon: "voucher-coupon",
};

export const Migrasi = {
	create: "token-migration",
	update: "token-migration",
	readall: "token-migration",
	delete: "token-migration",
	updateStatus: "token-migration-detail",
	resend: "resend-token-migration",
};

export const Aktivasi = {
	create: "activated",
	update: "activated",
	readall: "activated",
	resend: "resend-mail-payment",
};

export const Pengaturan = {
	create: "setting-adm",
	update: "setting-adm",
	readall: "setting-adm",
};

export const PenawaranPenjualan = {
	caption: "Penawaran Penjualan",
	color: "#93328E",
	modernColor: "",
	icon: svg.penawaran_penjualan,
	general: comp.PenawaranPenjualan,
	list: comp.ListPenawaranPenjualan,
	create: "quotation",
	read: "quotation",
	print: "quotation-print",
	readall: "quotation",
	update: "quotation",
	delete: "quotation",
	lastrecord: "last-quotation-id",
	getnumber: "quotation-no",
	getaudit: "quotation-audit",
	diproses: "quotation-processed",
	transtype: "AR QUO",
	getmemorized: "memorized-by-type",
};

export const PesananPenjualan = {
	caption: "Pesanan Penjualan",
	color: "#DF1995",
	modernColor: "",
	icon: svg.pesanan_penjualan,
	general: comp.PesananPenjualan,
	list: comp.ListPesananPenjualan,
	create: "sales-order",
	read: "sales-order",
	print: "sales-order-print",
	readall: "sales-order",
	update: "sales-order",
	delete: "sales-order",
	getnumber: "sales-order-no",
	lastrecord: "last-sales-order-id",
	pullTransaction: "quotation-to-sales-order",
	pullDetTransaction: "quotation-detail",
	diproses: "sales-order-processed",
	getaudit: "sales-order-audit",
	transtype: "AR ORD",
	getmemorized: "memorized-by-type",
};

export const PengirimanBarang = {
	caption: "Pengiriman Barang",
	color: "#F06400",
	modernColor: "",
	icon: svg.pengiriman_barang,
	general: comp.PengirimanBarang,
	list: comp.ListPengirimanBarang,
	create: "sales-invoice",
	read: "sales-invoice",
	print: "sales-invoice-print",
	readallByTipe: "sales-invoice-by-posted",
	update: "sales-invoice",
	delete: "sales-invoice",
	pullTransaction: "sales-order-to-sales-invoice",
	pullDetTransaction: "sales-order-detail",
	pullTransaction2: "proforma-invoice-to-sales-invoice",
	pullDetTransaction2: "proforma-invoice-detail",
	getnumber: "sales-invoice-no",
	lastrecord: "last-sales-invoice-id",
	akunByTipe: "glaccount-by-currency-typeid",
	diproses: "delivery-order-processed",
	getaudit: "sales-invoice-audit",
	transtype: "AR DLV",
	getmemorized: "memorized-by-type",
	itembalancewarehouses: "item-balance-warehouses",
};

export const FakturPenjualan = {
	caption: "Faktur Penjualan",
	color: "#00AED6",
	modernColor: "",
	icon: svg.faktur_penjualan,
	general: comp.FakturPenjualan,
	list: comp.ListFakturPenjualan,
	create: "sales-invoice",
	read: "sales-invoice",
	print: "sales-invoice-print",
	readallByTipe: "sales-invoice-by-posted",
	update: "sales-invoice",
	delete: "sales-invoice",
	pullTransaction: "sales-order-to-sales-invoice",
	pullDetTransaction: "sales-order-detail",
	pullTransaction2: "delivery-order-to-sales-invoice",
	pullDetTransaction2: "delivery-order-detail",
	pullTransaction3: "proforma-invoice-to-sales-invoice",
	pullDetTransaction3: "proforma-invoice-detail",
	pullDownPayment: "choose-dp",
	getnumber: "sales-invoice-no",
	lastrecord: "last-sales-invoice-id",
	akunByTipe: "glaccount-by-currency-typeid",
	getaudit: "sales-invoice-audit",
	diproses: "sales-invoice-payments",
	diprosesRetur: "sales-invoice-returns",
	transtype: "AR INV",
	getmemorized: "memorized-by-type",
	columnsdp: {
		title: [
			"Keterangan",
			"Total Uang Muka",
			"Tgl Uang Muka",
			"Pajak",
			"Pajak Inklusif",
			"Pajak 1",
			"Pajak 2",
			"No. Faktur",
			"No. SO",
			"Nilai Tukar",
			"Nilai TUkar Pajak",
		],
		dataIndex: [
			"itemovdesc",
			"brutounitprice",
			"itemodate",
			"taxcodes",
			"inclusivetax",
			"taxableamount1",
			"taxableamount2",
			"invoiceno",
			"sono",
			"rate",
			"fiscalrate",
		],
	},
	getNoPajak: "sales-invoice-taxno",
	itembalancewarehouses: "item-balance-warehouses",
};
export const FakturPenagihan = {
	caption: "Faktur Penagihan",
	color: "#00AED6",
	modernColor: "",
	icon: svg.faktur_penagihan,
	general: comp.FakturPenagihan,
	list: comp.ListFakturPenagihan,
	create: "sales-consolidated",
	read: "sales-consolidated",
	readall: "sales-consolidated",
	readallByTipe: "sales-consolidated",
	update: "sales-consolidated",
	delete: "sales-consolidated",
	pullConsolidates: "sales-consolidated-to-payment",
	getAllInvoiceConsolidate: "sales-consolidated-detail",
	pullTransaction: "list-sales-invoice-consolidated",
	pullDetTransaction: "sales-order-detail",
	pullTransaction2: "delivery-order-to-sales-invoice",
	pullDetTransaction2: "delivery-order-detail",
	pullTransaction3: "proforma-invoice-to-sales-invoice",
	pullDetTransaction3: "proforma-invoice-detail",
	pullDownPayment: "choose-dp",
	getnumber: "sales-consolidated-no",
	lastrecord: "last-sales-invoice-id",
	akunByTipe: "glaccount-by-currency-typeid",
	getaudit: "sales-invoice-audit",
	diproses: "sales-invoice-payments",
	diprosesRetur: "sales-invoice-returns",
	transtype: "AR INV",
	getmemorized: "memorized-by-type",
	columnsdp: {
		title: [
			"Keterangan",
			"Total Uang Muka",
			"Tgl Uang Muka",
			"Pajak",
			"Pajak Inklusif",
			"Pajak 1",
			"Pajak 2",
			"No. Faktur",
			"No. SO",
			"Nilai Tukar",
			"Nilai TUkar Pajak",
		],
		dataIndex: [
			"itemovdesc",
			"brutounitprice",
			"itemodate",
			"taxcodes",
			"inclusivetax",
			"taxableamount1",
			"taxableamount2",
			"invoiceno",
			"sono",
			"rate",
			"fiscalrate",
		],
	},
	getNoPajak: "sales-invoice-taxno",
	itembalancewarehouses: "item-balance-warehouses",
};

export const FakturSementara = {
	caption: "Faktur Sementara",
	color: "#00AED6",
	modernColor: "",
	icon: svg.faktur_sementara,
	general: comp.FakturSementara,
	list: comp.ListFakturSementara,
	create: "proforma-invoice",
	read: "proforma-invoice",
	readall: "proforma-invoice",
	readallByTipe: "proforma-invoice-by-posted",
	update: "proforma-invoice",
	delete: "proforma-invoice",
	pullTransaction: "sales-order-to-sales-invoice",
	pullDetTransaction: "sales-order-detail",
	pullTransaction2: "delivery-order-to-sales-invoice",
	pullDetTransaction2: "delivery-order-detail",
	pullDownPayment: "choose-dp",
	getnumber: "proforma-invoice-no",
	lastrecord: "last-proforma-invoice-id",
	akunByTipe: "glaccount-by-currency-typeid",
	getaudit: "proforma-invoice-audit",
	diproses: "proforma-invoice-payments",
	diprosesRetur: "proforma-invoice-returns",
	transtype: "AR INV",
	getmemorized: "memorized-by-type",
	columnsdp: {
		title: [
			"Keterangan",
			"Total Uang Muka",
			"Tgl Uang Muka",
			"Pajak",
			"Pajak Inklusif",
			"Pajak 1",
			"Pajak 2",
			"No. Faktur",
			"No. SO",
			"Nilai Tukar",
			"Nilai TUkar Pajak",
		],
		dataIndex: [
			"itemovdesc",
			"brutounitprice",
			"itemodate",
			"taxcodes",
			"inclusivetax",
			"taxableamount1",
			"taxableamount2",
			"invoiceno",
			"sono",
			"rate",
			"fiscalrate",
		],
	},
	getNoPajak: "sales-invoice-taxno",
};

export const PenerimaanPenjualan = {
	caption: "Penerimaan Penjualan",
	color: "#EE2737",
	modernColor: "",
	icon: svg.penerimaan_penjualan,
	general: comp.PenerimaanPenjualan,
	list: comp.ListPenerimaanPenjualan,
	create: "sales-payment",
	read: "sales-payment",
	readall: "sales-payment",
	update: "sales-payment",
	delete: "sales-payment",
	lastrecord: "last-sales-payment-id",
	getBank: "list-sales-payment-account",
	getnumber: "sales-payment-no",
	getListInvoiceCheque: "list-sales-invoice-payment",
	getaudit: "sales-payment-audit",
	transtype: "AR PMT",
	getmemorized: "memorized-by-type",
	columnsdisc: {
		title: [
			"Akun",
			"Nama Akun",
			"Jumlah",
			"Departemen",
			"Project",
			"Deskripsi",
		],
		dataIndex: [
			"glaccount",
			"accountname",
			"discount",
			"discount",
			"deptid",
			"projectid",
			"description",
		],
	},
};

// export const Recurring = {
//   caption: "Transaksi Berulang",
//   general: comp.Recurring,
//   list: comp.ListRecurring,
//   create: "sales-return",
//   read: "sales-return",
//   readall: "sales-return",
//   update: "sales-return",
//   delete: "sales-return",
//   getnumber: "sales-return-no",
//   lastrecord: "last-sales-return-id",
// };

export const ReturPenjualan = {
	caption: "Retur Penjualan",
	color: "#00AB14",
	modernColor: "",
	icon: svg.retur_penjualan,
	general: comp.ReturPenjualan,
	list: comp.ListReturPenjualan,
	create: "sales-return",
	read: "sales-return",
	readall: "sales-return",
	update: "sales-return",
	delete: "sales-return",
	getnumber: "sales-return-no",
	lastrecord: "last-sales-return-id",
	getInvoiceRetur: "sales-invoice-to-sales-return",
	getItmRetur: "sales-invoice-detail-to-sales-return",
	diproses: "sales-return-invoice",
	getaudit: "sales-return-audit",
	transtype: "AR RTR",
	getmemorized: "memorized-by-type",
};

export const PermintaanPembelian = {
	caption: "Permintaan Pembelian",
	color: "#00AB14",
	modernColor: "",
	icon: svg.permintaan_pembeliaan,
	general: comp.PermintaanPembelian,
	list: comp.ListPermintaanPembelian,
	create: "requisition",
	read: "requisition",
	readall: "requisition",
	update: "requisition",
	delete: "requisition",
	getnumber: "requisition-no",
	lastrecord: "last-requisition-id",
	diproses: "requisition-processed",
	getaudit: "requisition-audit",
	getmemorized: "memorized-by-type",
	transtype: "AP REQ",
};

export const PesananPembelian = {
	caption: "Pesanan Pembelian",
	color: "#EE2737",
	modernColor: "",
	icon: svg.pesanan_pembeliaan,
	general: comp.PesananPembelian,
	list: comp.ListPesananPembelian,
	create: "purchase-order",
	read: "purchase-order",
	readall: "purchase-order",
	update: "purchase-order",
	delete: "purchase-order",
	getnumber: "purchase-order-no",
	getJumItem: "JumlahDetail",
	pullTransaction: "requisition-to-purchase-order",
	pullDetTransaction: "requisition-detail",
	lastrecord: "last-purchase-order-id",
	diproses: "purchase-order-processed",
	getaudit: "purchase-order-audit",
	getmemorized: "memorized-by-type",
	transtype: "AP ORD",
};

export const PenerimaanBarang = {
	caption: "Penerimaan Barang",
	color: "#00AED6",
	modernColor: "",
	icon: svg.penerimaan_barang,
	general: comp.PenerimaanBarang,
	list: comp.ListPenerimaanBarang,
	create: "purchase-invoice",
	read: "purchase-invoice",
	readallByTipe: "purchase-invoice-by-posted",
	update: "purchase-invoice",
	delete: "purchase-invoice",
	getnumber: "purchase-invoice-no",
	pullTransaction: "purchase-order-to-purchase-invoice",
	pullDetTransaction: "purchase-order-detail",
	lastrecord: "last-purchase-invoice-id",
	diproses: "receive-item-processed",
	diprosesRetur: "purchase-invoice-returns",
	getaudit: "purchase-invoice-audit",
	getmemorized: "memorized-by-type",
	transtype: "AP RCV",
};

export const FakturPembelian = {
	caption: "Faktur Pembelian",
	color: "#F06400",
	modernColor: "",
	icon: svg.faktur_pembelian,
	general: comp.FakturPembelian,
	list: comp.ListFakturPembelian,
	create: "purchase-invoice",
	read: "purchase-invoice",
	readallByTipe: "purchase-invoice-by-posted",
	update: "purchase-invoice",
	delete: "purchase-invoice",
	getnumber: "purchase-invoice-no",
	pullTransaction: "purchase-order-to-purchase-invoice",
	pullTransaction2: "receive-item-to-purchase-invoice",
	pullDetTransaction: "purchase-order-detail",
	pullDetTransaction2: "purchase-item-detail",
	pullDownPayment: "choose-dp",
	lastrecord: "last-purchase-invoice-id",
	diproses: "purchase-invoice-payments",
	diprosesRetur: "purchase-invoice-returns",
	accntCurrency: "glaccount-by-currency-typeid",
	chargeToVendor: "charge-to-vendor",
	getaudit: "purchase-invoice-audit",
	transtype: "AP INV",
	getmemorized: "memorized-by-type",
	columnsDP: {
		title: [
			"Keterangan",
			"Total Uang Muka",
			"Tgl Uang Muka",
			"Pajak",
			"Pajak Inklusiv",
			"Pajak 1",
			"Pajak 2",
			"No. Faktur",
			"No. PO",
			"Nilai Tukar",
			"Nilai Tukar Pajak",
		],
		dataIndex: [
			"itemovdesc",
			"brutounitprice",
			"itemodate",
			"taxcodes",
			"inclusivetax",
			"taxableamount1",
			"taxableamount2",
			"invoiceno",
			"pono",
			"rate",
			"fiscalrate",
		],
	},
	getNoPajak: "purchase-invoice-taxno",
};

export const PembayaranPembelian = {
	caption: "Pembayaran Pembelian",
	color: "#DF1995",
	modernColor: "",
	icon: svg.pembayaran_pembelian,
	general: comp.PembayaranPembelian,
	list: comp.ListPembayaranPembelian,
	create: "purchase-payment",
	read: "purchase-payment",
	readall: "purchase-payment",
	update: "purchase-payment",
	delete: "purchase-payment",
	getnumber: "purchase-payment-no",
	getBank: "list-purchase-payment-account",
	getListInvoiceCheque: "list-purchase-invoice-payment",
	lastrecord: "last-purchase-payment-id",
	diproses: "purchase-payment-processed",
	diprosesRetur: "purchase-payment-returns",
	getaudit: "purchase-payment-audit",
	transtype: "AP CHQ",
	getmemorized: "memorized-by-type",
	columnsdisc: {
		title: [
			"Akun",
			"Nama Akun",
			"Jumlah",
			"Departemen",
			"Project",
			"Deskripsi",
		],
		dataIndex: [
			"glaccount",
			"accountname",
			"discount",
			"discount",
			"deptid",
			"projectid",
			"description",
		],
	},
};

export const ReturPembelian = {
	caption: "Retur Pembelian",
	color: "#93328E",
	modernColor: "",
	icon: svg.retur_pembelian,
	general: comp.ReturPembelian,
	list: comp.ListReturPembelian,
	create: "purchase-return",
	read: "purchase-return",
	readall: "purchase-return",
	update: "purchase-return",
	delete: "purchase-return",
	lastrecord: "last-purchase-return-id",
	getnumber: "purchase-return-no",
	getInvoiceRetur: "purchase-invoice-to-purchase-return",
	getItmRetur: "purchase-item-detail-to-purchase-return",
	diproses: "purchase-return-invoice",
	getaudit: "purchase-return-audit",
	transtype: "AP RTR",
	getmemorized: "memorized-by-type",
};

export const JurnalUmum = {
	caption: "Jurnal Umum",
	color: "#00AED6",
	modernColor: "",
	icon: svg.jurnal_umum,
	general: comp.Jurnal,
	list: comp.ListJurnal,
	create: "journal-voucher",
	read: "journal-voucher",
	readallByTipe: "journal-voucher-by-transtype",
	update: "journal-voucher",
	delete: "journal-voucher",
	lastrecord: "last-journal-voucher-id",
	getnumber: "journal-voucher-no-by-type",
	getaudit: "journal-voucher-audit",
	transtype: "GL JV",
	getmemorized: "memorized-by-type",
};

export const GL = {
	read: "general-ledger-history",
};

export const DaftarAkun = {
	caption: "Daftar Akun",
	general: comp.DaftarAkun,
	list: comp.ListDaftarAkun,
	icon: svg.buku_besar,
	create: "glaccount",
	read: "glaccount",
	readall: "glaccount",
	readallBase: "glaccount-base",
	update: "glaccount",
	delete: "glaccount",
	readNo: "glaccount-by-no",
	shipCost: "glaccount-by-not-type",
	akunByTipe: "glaccount-by-typeid",
	accntCurrency: "glaccount-by-currency-typeid",
	jobCost: "glaccount-job-cost",
	getaudit: "glaccount-audit",
	columns: {
		title: [
			"Tipe Akun",
			"No. Akun",
			"Nama Akun (Indonesia)",
			"Nama Akun (English)",
			"Mata Uang",
			"Sub Akun dari",
			"Dihentikan",
		],
		dataIndex: [
			"accounttype",
			"glaccount",
			"accountname",
			"accountname2",
			"currencyid",
			"subaccount",
			"suspended",
		],
	},
};

export const TipeAkun = {
	caption: "Tipe Akun",
	readall: "glaccount-type",
};

export const AddPengguna = {
	caption: "Daftar Pengguna",
	create: "tenant-user",
	read: "tenant-user",
	readall: "tenant-user",
	update: "tenant-user",
	delete: "tenant-user",
};

export const subAkun = {
	caption: "Sub Akun",
	create: "list-available-glaccount",
};

export const RekonsiliasiBank = {
	caption: "Rekonsiliasi Bank",
	color: "#93328E",
	modernColor: "",
	icon: svg.rekonsiliasi_bank,
	list: comp.Rekonsiliasi,
	create: "reconciliation",
	read: "reconciliation",
	readall: "reconciliation",
	update: "reconciliation",
	delete: "reconciliation",
	accreconcile: "reconciliation",
};

export const PembayaranLainnya = {
	caption: "Pembayaran Lainnya",
	color: "#DF1995",
	modernColor: "",
	icon: svg.pembayaran_lainnya,
	general: comp.PembayaranLainnya,
	list: comp.ListPembayaranLainnya,
	create: "journal-voucher",
	read: "journal-voucher",
	readallByTipe: "journal-voucher-by-transtype",
	update: "journal-voucher",
	delete: "journal-voucher",
	lastrecord: "last-journal-voucher-id",
	getnumber: "journal-voucher-no-by-type",
	getaudit: "journal-voucher-audit",
	transtype: "GL PMT",
	getmemorized: "memorized-by-type",
};

export const PenerimaanLainnya = {
	caption: "Penerimaan Lainnya",
	color: "#00AB14",
	modernColor: "",
	icon: svg.penerimaan_lainnya,
	general: comp.PenerimaanLainnya,
	list: comp.ListPenerimaanLainnya,
	create: "journal-voucher",
	read: "journal-voucher",
	readallByTipe: "journal-voucher-by-transtype",
	update: "journal-voucher",
	delete: "journal-voucher",
	lastrecord: "last-journal-voucher-id",
	getnumber: "journal-voucher-no-by-type",
	getaudit: "journal-voucher-audit",
	transtype: "GL ODT",
	getmemorized: "memorized-by-type",
};

export const SaldoAkun = {
	caption: "Saldo Akun",
	list: comp.SaldoAkun,
};

export const RiwayatAkun = {
	caption: "Riwayat Akun",
	list: comp.RiwayatAkun,
};

export const AnggaranAkun = {
	caption: "Anggaran Akun",
	general: comp.AnggaranAkun,
	list: comp.ListAnggaranAkun,
	readall: "search-budget-account",
	create: "budgetaccount",
	update: "budgetaccount",
};

export const RiwayatPelangganPemasok = {
	caption: "Riwayat Pelanggan Pemasok",
	list: comp.RiwayatPelangganPemasok,
};
export const BukuBank = {
	caption: "Buku Bank",
	list: comp.RiwayatBank,
	readall: "bank-book",
};

export const Audit = {
	//abie
	caption: "Audit",
	list: comp.Audit,
};

export const TransaksiBerulang = {
	caption: "Transaksi Berulang",
	general: comp.TransaksiBerulang,
	list: comp.ListTransaksiBerulang,
	create: "recurring",
	read: "recurring",
	readall: "recurring",
	update: "recurring",
	delete: "recurring",
	getnumber: "recurring-no",
	lastrecord: "recurring",
	// disable: true,
};
export const SalinanTransaksi = {
	caption: "Salinan Transaksi",
	general: comp.SalinanTransaksi,
	list: comp.ListSalinanTransaksi,
	create: "memorized",
	read: "memorized",
	readall: "memorized",
	update: "memorized",
	delete: "memorized",
	getnumber: "memorized-no",
	lastrecord: "memorized",
};
const AkhirPeriode = {
	caption: "Akhir Periode",
	type: "modalPeriod",
};
export const PenyesuaianBarang = {
	caption: "Penyesuaian Barang",
	color: "#EE2737",
	modernColor: "",
	icon: svg.penyesuaian_barang,
	general: comp.PenyesuaianBarang,
	list: comp.ListPenyesuaianBarang,
	create: "item-adjustment",
	read: "item-adjustment",
	readall: "item-adjustment",
	update: "item-adjustment",
	delete: "item-adjustment",
	lastrecord: "last-item-adjustment-id",
	getnumber: "item-adjustment-no",
	getaudit: "item-adjustment-audit",
	getmemorized: "memorized-by-type",
	transtype: "IT ADJ",
};

export const PembiayaanPesanan = {
	caption: "Pembiayaan Pesanan",
	color: "#00AED6",
	// color: disableColor,
	modernColor: "",
	icon: svg.pembiayaan_pesanan,
	general: comp.PembiayaanPesanan,
	list: comp.ListPembiayaanPesanan,
	getnumber: "job-cost-no",
	create: "job-cost",
	read: "job-cost",
	readall: "job-cost",
	update: "job-cost",
	delete: "job-cost",
	lastrecord: "last-job-cost-id",
	// disable: true,
	getaudit: "job-cost-audit",
	transtype: "PR JOB",
	getmemorized: "memorized-by-type",
	itembalancewarehouses: "item-balance-warehouses",
};

export const PindahBarang = {
	caption: "Pindah Barang",
	color: "#F06400",
	modernColor: "",
	icon: svg.pindah_barang,
	general: comp.PindahBarang,
	list: comp.ListPindahBarang,
	create: "whtrans",
	read: "whtrans",
	readall: "whtrans",
	update: "whtrans",
	delete: "whtrans",
	getnumber: "whtrans-no",
	lastrecord: "last-whtrans-id",
	getaudit: "whtrans-audit",
	transtype: "IT TRA",
};

export const PengelompokanBarang = {
	caption: "Pengelompokan Barang",
	color: "#DF1995",
	modernColor: "",
	disable: false,
	icon: svg.pengelompokan_barang,
	general: comp.PengelompokanBarang,
	list: comp.ListPengelompokanBarang,
	create: "item-grouping",
	read: "item-grouping",
	readall: "item-grouping",
	update: "item-grouping",
	delete: "item",
	getnumber: "item-grouping-no",
	getdetail: "item-grouping-detail",
	lastrecord: "last-item-grouping-id",
	getaudit: "item-grouping-audit",
	columnsMaster: {
		section: "master",
		title: [
			"No. Group",
			"Deskrpisi",
			"Harga Jual",
			"Satuan",
			"Kode Pajak",
			"Akun Penjualan",
			"Akun Retur Penjualan",
			"Akun Diskon Penjualan",
		],
		dataIndex: [
			"itemno",
			"itemdescription",
			"unitprice",
			"unit1",
			"taxcodes",
			"salesglaccnt",
			"salesretglaccnt",
			"salesdiscount",
		],
	},
	columnsDetail: {
		section: "detail",
		title: ["No. Barang", "Deskripsi", "Kuantitas", "Satuan"],
		dataIndex: ["itemno", "itemdescription", "quantity", "itemunit"],
	},
};

export const AktivaTetap = {
	caption: "Aktiva Tetap",
	color: "#F06400",
	modernColor: "",
	icon: svg.aktiva_tetap,
	general: comp.AktivaTetap,
	list: comp.ListAktivaTetap,
	create: "fixed-asset",
	getnumber: "fixed-asset-no",
	read: "fixed-asset",
	lastrecord: "last-fixed-asset-id",
	readall: "fixed-asset",
	update: "fixed-asset",
	delete: "fixed-asset",
	disposed: "dispose-fixed-asset",
	cekPeriodeEnd: "check-period-end",
	periodeEnd: "period-end",
	undoDisposed: "fixed-asset-undo-dispose",
	// disable: true,
	getaudit: "fixed-asset-audit",
};

export const Kosong = {
	caption: "kosong",
};
export const Persetujuan = {
	caption: "Persetujuan",
	module: "Persetujuan",
	general: comp.Approval,
	getnumber: "checking-note-no",
	list: comp.ListApproval,
	create: "checking-note",
	read: "checking-note",
	readall: "checking-note",
	update: "checking-note",
	delete: "checking-note",
};
export const Otorisasi = {
	caption: "Otorisasi Persetujuan",
	module: "Persetujuan",
	list: comp.Otorisasi,
	create: "authorization-approval",
	update: "authorization-approval",
	read: "authorization-approval",
};

export const TipeAktivaTetap = {
	// disable: true,
	caption: "Tipe Aktiva Tetap",
	color: "#00AB14",
	modernColor: "",
	full: true,
	icon: svg.tipe_aktiva_tetap,
	general: comp.TipeAktivaTetap,
	list: comp.ListTipeAktivaTetap,
	create: "fixed-asset-type",
	read: "fixed-asset-type",
	readall: "fixed-asset-type",
	update: "fixed-asset-type",
	delete: "fixed-asset-type",
	getaudit: "fixed-asset-type-audit",
	columns: {
		title: [
			"Tipe Aktiva Tetap",
			"Tipe Aktiva Tetap Pajak",
			"Metode Penyusutan",
			"Umur Perkiraan",
			"Nilai Penyusutan",
		],
		dataIndex: [
			"fatypedesc",
			"fafisdesc",
			"deprmethod",
			"fisestlife",
			"fisrate",
		],
	},
};

export const TipeAktivaTetapPajak = {
	// disable: true,
	caption: "Tipe Aktiva Tetap Pajak",
	color: "#93328E",
	modernColor: "",
	icon: svg.tipe_aktiva_tetap_pajak,
	general: comp.TipeAktivaTetapPajak,
	list: comp.ListTipeAktivaTetapPajak,
	create: "fixed-asset-fiscal",
	read: "fixed-asset-fiscal",
	readall: "fixed-asset-fiscal",
	update: "fixed-asset-fiscal",
	delete: "fixed-asset-fiscal",
	getaudit: "fixed-asset-fiscal-audit",
	columns: {
		title: [
			"Tipe Aktiva Tetap Pajak",
			"Metode Penyusutan",
			"Umur Perkiraan",
			"Nilai Penyusutan",
		],
		dataIndex: ["fafisdesc", "deprmethod", "fisestlife", "fisrate"],
	},
};

export const FormulaProduk = {
	caption: "Formula Produk",
	color: "#EE2737",
	modernColor: "",
	icon: svg.formula_produk,
	general: comp.FormulaProduk,
	list: comp.ListFormulaProduk,
	create: "bom",
	read: "bom",
	readall: "bom",
	update: "bom",
	delete: "bom",
	getnumber: "bom-no",
};

export const PerintahKerja = {
	caption: "Perintah Kerja",
	color: "#00AB14",
	modernColor: "",
	icon: svg.perintah_kerja,
	general: comp.PerintahKerja,
	list: comp.ListPerintahKerja,
	create: "work-order",
	read: "work-order",
	readall: "work-order",
	update: "work-order",
	delete: "work-order",
	getnumber: "work-order-no",
};

export const PengeluaranBahanBaku = {
	caption: "Pengeluaran Bahan Baku",
	color: "#F06400",
	modernColor: "",
	icon: svg.pengeluaran_bahan_baku,
	general: comp.PengeluaranBahanBaku,
	list: comp.ListPengeluaranBahanBaku,
	create: "material-release",
	read: "material-release",
	readall: "material-release",
	update: "material-release",
	delete: "material-release",
	getnumber: "material-release-no",
	itembalancewarehouses: "item-balance-warehouses",
};

export const HasilProduksi = {
	caption: "Hasil Produksi",
	color: "#00AED6",
	modernColor: "",
	icon: svg.hasil_produksi,
	general: comp.HasilProduksi,
	list: comp.ListHasilProduksi,
	create: "product-result",
	read: "product-result",
	readall: "product-result",
	update: "product-result",
	delete: "product-result",
	getnumber: "product-result-no",
};

export const Chat = {
	caption: "Chat",
	list: comp.Chat,
};

export const ImpPesananPembelian = {
	caption: "Import Pesanan Pembelian",
	list: comp.ImportPesananPembelian,
	create: "import-purchase-order",
};
export const ImpPenerimaanBarang = {
	caption: "Import Penerimaan Barang",
	list: comp.ImportPenerimaanBarang,
	create: "import-purchase-invoice",
};
export const ImpFakturPembelian = {
	caption: "Import Faktur Pembelian",
	list: comp.ImportFakturPembelian,
	create: "import-purchase-invoice",
};
const ImpPembayaranPembelian = {
	caption: "Import Pembayaran Pembelian",
	disable: true,
};

// const ImpFakturPenjualan = {
//   caption: "Import Faktur Pembelian",
//   disable: true,
// };

export const ImpPesananPenjualan = {
	caption: "Import Pesanan Penjualan",
	list: comp.ImportPesananPenjualan,
	create: "import-sales-order",
};
export const ImpPengirimanBarang = {
	caption: "Import Pengiriman Barang",
	list: comp.ImportPengirimanBarang,
	create: "import-sales-invoice",
};
export const ImpFakturPenjualan = {
	caption: "Import Faktur Penjualan",
	list: comp.ImportFakturPenjualan,
	create: "import-sales-invoice",
};
const ImpPenerimaanPenjualan = {
	caption: "Import Penerimaan Penjualan",
	disable: true,
};
export const ImpJurnalUmum = {
	caption: "Import Jurnal Umum",
	list: comp.ImportJurnalUmum,
	create: "import-journal-voucher",
};
const ImpPindahBarang = {
	caption: "Import Pindah Barang",
	disable: true,
};
export const ImpAktivaTetap = {
	caption: "Import Aktiva Tetap",
	disable: false,
	list: comp.ImportAktivaTetap,
	create: "import-fix-asset",
};
const ImpPowerCash = {
	caption: "Import Power Cash",
	disable: true,
};
const ImpPenyesuaianPersediaan = {
	caption: "Import Penyesuaian Persediaan",
	disable: true,
};
const ImpPengeluaranBahan = {
	caption: "Import Pengeluaran Bahan",
	disable: true,
};
const KartuBankPOS = {
	caption: "Kartu Bank POS",
	disable: true,
};

const MesinEDCPOS = {
	caption: "Mesin EDC POS",
	disable: true,
};

const Promo = {
	caption: "Promo",
	disable: true,
};

const PostingDataKasir = {
	caption: "Posting Data Kasir POS",
	disable: true,
};

export const Logout = {
	caption: "Keluar",
	type: "logout",
};
export const TutupDB = {
	caption: "Tutup Database Perusahaan",
	type: "change_db",
};

export const Cabang = {
	caption: "Cabang",
	list: comp.ListCabang,
	general: comp.Cabang,
	create: "branch",
	read: "branch",
	readall: "branch",
	update: "branch",
	delete: "branch",
};
export const BuatDatabasePerusahaanBaru = {
	caption: "Buat Database Perusahaan Baru",
	disable: true,
};
export const BukaDatabasePerusahaan = {
	caption: "Buka Database Perusahaan",
	disable: true,
};
export const TutupDatabasePerusahaan = {
	caption: "Tutup Database Perusahaan",
	disable: true,
};
export const SimpanSebagaiAlias = {
	caption: "Simpan Sebagai Alias",
	disable: true,
};
export const TutupBukuCabang = {
	caption: "Tutup Buku & Buat Data Cabang",
	disable: true,
};

// const HitungUlangSaldo = {
//   caption: "Hitung Ulang saldo",
//   disable: true,
// };
// const RiwayatData = {
// 	caption: "Riwayat Data",
// 	list: comp.RiwayatData,
// };
const SekilasEASY = {
	caption: "Sekilas Easy",
	type: "modal_sekilasEasy",
};
const Panduan = {
	caption: "Panduan",
	type: "link_panduan",
};
const RiwayatUpdate = {
	caption: "Riwayat Update",
	type: "modal_riwayatUpdate",
};
const StatusHpp = {
	caption: "Status Hpp",
	type: "modal_statusHpp",
};
const Registration = {
	caption: "Aktivasi",
	type: "modalRegistration",
};
const QuickSupport = {
	caption: "Quick Support",
	disable: true,
};
const LaporanBugError = {
	caption: "Laporan Bug/Error",
	disable: true,
};
const VideoTutorial = {
	caption: "Video Tutorial",
	type: "open_link",
};
const RepairData = {
	caption: "Repair Data",
	type: "repairData",
};

const ThreadHpp = {
	caption: "Thread Hpp",
	type: "threadHpp",
};
export const Konsolidasi = {
	caption: "Konsolidasi",
	submenu: true,
	data: [
		{ caption: "Konsolidasi", disable: true },
		{ caption: "Batalkan Konsolidasi", disable: true },
	],
};
const ModPembelian = {
	caption: "Pembelian",
	submenu: true,
	data: [
		PermintaanPembelian,
		PesananPembelian,
		PenerimaanBarang,
		FakturPembelian,
		PembayaranPembelian,
		ReturPembelian,
	],
};
const ModPenjualan = {
	caption: "Penjualan",
	submenu: true,
	data: [
		PenawaranPenjualan,
		PesananPenjualan,
		FakturSementara,
		PengirimanBarang,
		FakturPenjualan,
		FakturPenagihan,
		PenerimaanPenjualan,
		ReturPenjualan,
	],
};
const ModCatatanPersetujuan = {
	caption: "Catatan Persetujuan",
	submenu: true,
	data: [Persetujuan, Otorisasi],
};

export const Pengguna = {
	caption: "Pengguna",
	submenu: true,
	data: [
		{
			caption: "Pengguna dan Hak Akses",
			type: "modal_hakases",
		},

		{ caption: "Ganti Password Pengguna", disable: true },
		{
			caption: "Bahasa Sistem",
			submenu: true,
			data: [
				{
					caption: "Indonesia",
					type: "changeLanguage",
					key: "id",
					disable: false,
				},
				{
					caption: "English",
					type: "changeLanguage",
					key: "en",
					disable: false,
				},
			],
		},
		{ caption: "Tema Sistem", type: "modalTheme" },
	],
};
export const Backup = {
	caption: "Backup",
	disable: true,
};
export const PembaharuanSystem = {
	caption: "Pembaharuan Sistem",
	disable: true,
};
export const TutupBukuBuatDataCabang = {
	caption: "Pembaharuan Sistem",
	disable: true,
};

export const MataUang = {
	caption: "Mata Uang",
	general: comp.MataUang,
	list: comp.ListMataUang,
	create: "currencys",
	read: "currencys",
	readall: "currencys",
	update: "currencys",
	delete: "currencys",
	getaudit: "currencys-audit",
	columns: {
		title: ["Nama Mata Uang", "Nilai Tukar"],
		dataIndex: ["currencyname", "exchangerate"],
	},
};

export const Satuan = {
	caption: "Satuan",
	general: comp.Satuan,
	list: comp.ListSatuan,
	create: "unit",
	read: "unit",
	readall: "unit",
	update: "unit",
	delete: "unit",
	getaudit: "unit-audit",
	columns: {
		title: ["Nama Satuan"],
		dataIndex: ["unitname"],
	},
};

export const StatusPelangganPemasok = {
	caption: "Status Pelanggan dan Pemasok",
	general: comp.PelangganPemasok,
	list: comp.ListPelangganPemasok,
	create: "customer-status",
	read: "customer-status",
	readall: "customer-status",
	update: "customer-status",
	delete: "customer-status",
	getaudit: "customer-status-audit",
	columns: {
		title: ["Nama"],
		dataIndex: ["name"],
	},
};

export const TipePelanggan = {
	caption: "Tipe Pelanggan",
	general: comp.TipePelanggan,
	list: comp.ListTipePelanggan,
	create: "customer-type",
	read: "customer-type",
	readall: "customer-type",
	update: "customer-type",
	delete: "customer-type",
	getaudit: "customer-type-audit",
	columns: {
		title: ["Nama"],
		dataIndex: ["typename"],
	},
};

export const Pelanggan = {
	caption: "Pelanggan",
	general: comp.Pelanggan,
	list: comp.ListPelanggan,
	create: "person-data",
	read: "person-data",
	readallByTipe: "person-data-by-typeid",
	update: "person-data",
	delete: "person-data",
	getnumber: "person-data-no",
	getaudit: "person-data-audit",
	columns: {
		title: [
			"No. Pelanggan",
			"Nama Pelanggan",
			"Status",
			"NIK",
			"Dihentikan",
			"Alamat",
			"Alamat 2",
			"Alamat Pajak",
			"Alamat Pajak 2",
			"Kode Pos",
			"Provinsi",
			"Kota",
			"Negara",
			"Kontak",
			"No. Telp",
			"Fax",
			"Email",
			"Website",
			"NPWP",
			"NPPKP",
			"Pajak 1",
			"Pajak 2",
			"Penjual",
			"Tipe Pelanggan",
			"Level Harga",
			"Diskon Penjualan",
			"Syarat",
			"Batas Maks Hutang",
			"Batas Umur Hutang",
			"Mata Uang",
			"Deskripsi",
		],
		dataIndex: [
			"personno",
			"name",
			"statid",
			"nik",
			"suspended",
			"addressline1",
			"addressline2",
			"taxaddress1",
			"taxaddress2",
			"zipcode",
			"stateprov",
			"city",
			"country",
			"contact",
			"phone",
			"fax",
			"email",
			"webpage",
			"tax1exemptionno",
			"tax1exemptionno",
			"tax1id",
			"tax2id",
			"salesmanid",
			"customertypeid",
			"pricelevel",
			"defaultdisc",
			"termsid",
			"creditlimit",
			"creditlimitday",
			"currencyid",
			"defaultinvdescription",
		],
	},
};

export const Pemasok = {
	caption: "Pemasok",
	general: comp.Pemasok,
	list: comp.ListPemasok,
	create: "person-data",
	read: "person-data",
	readallByTipe: "person-data-by-typeid",
	update: "person-data",
	delete: "person-data",
	getnumber: "person-data-no",
	getaudit: "person-data-audit",
	columns: {
		title: [
			"No. Pemasok",
			"Nama Pemasok",
			"Status",
			"Dihentikan",
			"Alamat",
			"Alamat 2",
			"Alamat Pajak",
			"Alamat Pajak 2",
			"Kode Pos",
			"Provinsi",
			"Kota",
			"Negara",
			"Kontak",
			"No. Telp",
			"Fax",
			"Email",
			"Website",
			"NPWP",
			"Pajak 1",
			"Pajak 2",
			"Syarat",
			"Mata Uang",
			"Deskripsi",
			"No. PKP",
		],
		dataIndex: [
			"personno",
			"name",
			"statid",
			"suspended",
			"addressline1",
			"addressline2",
			"taxaddress1",
			"taxaddress2",
			"zipcode",
			"stateprov",
			"city",
			"country",
			"contact",
			"phone",
			"fax",
			"email",
			"webpage",
			"tax1exemptionno",
			"tax1id",
			"tax2id",
			"termsid",
			"currencyid",
			"defaultinvdescription",
			"tax2exemptionno",
		],
	},
};

export const Penjual = {
	caption: "Penjual",
	general: comp.Penjual,
	list: comp.ListPenjual,
	create: "salesman",
	read: "salesman",
	readall: "salesman",
	update: "salesman",
	delete: "salesman",
	getaudit: "salesman-audit",
	columns: {
		title: [
			"Nama Depan",
			"Nama Belakang",
			"Jabatan",
			"Dihentikan",
			"No. Kantor",
			"No. Ekstensi",
			"No. Hp",
			"No. Telp rumah",
			"Fax",
			"Pager",
			"Email",
		],
		dataIndex: [
			"firstname",
			"lastname",
			"jobtitle",
			"suspended",
			"businessphone",
			"businessphoneext",
			"cellular",
			"homephone",
			"fax",
			"pager",
			"email",
		],
	},
};

export const Komisi = {
	disable: true,
	caption: "Komisi",
	general: comp.Komisi,
	list: comp.ListKomisi,
	create: "SalesmanCommision",
	read: "SalesmanCommision",
	readall: "SalesmanCommision",
	update: "updateSalesmanCommision",
	delete: "deleteSalesmanCommision",
	getaudit: "",
};

export const Departemen = {
	disable: false,
	caption: "Departemen",
	general: comp.Departemen,
	list: comp.ListDepartemen,
	create: "department",
	read: "department",
	readall: "department",
	update: "department",
	delete: "department",
	getaudit: "department-audit",
	columns: {
		title: [
			"No. Departemen",
			"Nama Departemen",
			"Nama Kontak",
			"Deskripsi",
			"Tipe",
			"Dihentikan",
		],
		dataIndex: [
			"deptno",
			"deptname",
			"contact_name",
			"description",
			"tipe",
			"suspended",
		],
	},
};

export const Proyek = {
	disable: false,
	caption: "Proyek",
	general: comp.Proyek,
	list: comp.ListProyek,
	create: "project",
	read: "project",
	readall: "project",
	update: "project",
	delete: "project",
	getaudit: "project-audit",
	columns: {
		title: [
			"No. Proyek",
			"Nama Proyek",
			"Nama Kontak",
			"Tanggal",
			"Komplet",
			"Deskripsi",
			"Dihentikan",
		],
		dataIndex: [
			"projectno",
			"projectname",
			"contactname",
			"tanggal",
			"percentcompleted",
			"description",
			"suspended",
		],
	},
};

export const Pajak = {
	caption: "Pajak",
	general: comp.Pajak,
	list: comp.ListPajak,
	create: "tax",
	read: "tax",
	readall: "tax",
	update: "tax",
	delete: "tax",
	getaudit: "tax-audit",
	columns: {
		title: [
			"Kode",
			"Nama",
			"Nilai",
			"Akun pajak penjualan",
			"Akun pajak pembelian",
			"Deskripsi",
		],
		dataIndex: [
			"taxcode",
			"taxname",
			"rate",
			"taxglaccount_name",
			"ptaxglaccount_name",
			"taxdescription",
		],
	},
};

export const SyaratPembayaran = {
	caption: "Syarat Pembayaran",
	general: comp.SyaratPembayaran,
	list: comp.ListSyaratPembayaran,
	create: "term-payment",
	read: "term-payment",
	readall: "term-payment",
	update: "term-payment",
	delete: "term-payment",
	getaudit: "term-payment-audit",
	columns: {
		title: ["Batas Hutang", "Cash on Delivery", "Persentase", "Periode Diskon"],
		dataIndex: ["netdays", "cod", "discpc", "discdays"],
	},
};

export const JasaPengiriman = {
	caption: "Jasa Pengiriman",
	general: comp.JasaPengiriman,
	list: comp.ListJasaPengiriman,
	create: "shipment",
	read: "shipment",
	readall: "shipment",
	update: "shipment",
	delete: "shipment",
	getaudit: "shipment-audit",
	columns: {
		title: ["Name"],
		dataIndex: ["name"],
	},
};

export const ReportView = {
	caption: "Laporan",
	list: comp.PreviewLaporan,
};

// export const Sample = {
//   caption: "Sample",
//   list: comp.Sample,
// };

export const SemuaLaporan = {
	caption: "Semua Laporan",
	list: comp.SemuaLaporan,
	category: "report-category",
	format: "report-format",
	formatbycategory: "report-format-by-category",
	report: "report",
	view: "report",
};

export const Editor = {
	caption: "Editor Laporan",
	list: comp.Editor,
};

export const RancanganFormulir = {
	caption: "Rancangan Formulir",
	list: comp.RancanganFormulir,
};

export const InformasiPerusahaan = {
	caption: "Informasi Perusahaan",
	list: comp.InformasiPerusahaan,
	create: "company",
	read: "company",
	readall: "company",
	update: "company",
	delete: "company",
};

export const Barang = {
	caption: "Barang",
	icon: svg.barang,
	general: comp.Barang,
	list: comp.ListBarang,
	create: "item",
	read: "item",
	readall: "item",
	update: "item",
	delete: "item",
	readNo: "item-by-no",
	getItemUnit: "item-unit",
	getQty: "item-balance-warehouse",
	getQtySaldo: "item-opening-balance",
	getaudit: "item-audit",
	columns: {
		title: [
			"Tipe Barang",
			"Tipe Persediaan",
			"Kategori Barang",
			"No. Barang",
			"Sub Barang Dari",
			"Deskripsi 1",
			"Deskripsi 2",
			"Default Gudang",
			"Departemen",
			"Proyek",
			"Dihentikan",
			"Harga Satuan",
			"Diskon",
			"Kode Pajak",
			"Preferensi Pemasok",
			"Min. Kts Pesan Ulang",
			// "Satuan",
			"Kts",
			"Biaya/Satuan",
			"Total",
			"Gudang",
			"Akun Persediaan",
			"Akun Penjualan",
			"Akun Diskon Penjualan",
			"Akun Retur Penjualan",
			"Akun Retur Pembelian",
			"Akun HPP",
			"Akun Penerimaan Belum Tertagih",
			"Akun Barang Terkirim",
			"No. UPC/Barcode",
			"No. PLU",
			// "Pakai Nomor Seri",
			// "Tipe Nomor Seri",
			// "Pakai Tanggal Kadaluarsa",
			// "Harus memilih nomor seri di transaksi",
			// "Dapat mengeluarkan nomor seri meskipun tidak ada stock tersedia",
		],
		dataIndex: [
			"itemtype",
			"tipepersediaan",
			"categoryid",
			"itemno",
			"subitem",
			"itemdescription",
			"itemdescription2",
			"defwhs",
			"deptid",
			"projectid",
			"suspended",
			"unitprice",
			"discpc",
			"taxcodes",
			"preferedvendor",
			"minimumqty",
			// "unit1",
			"quantity",
			"cost",
			"total",
			"defwhs",
			"inventoryglaccnt",
			"salesglaccnt",
			"salesdiscount",
			"salesretglaccnt",
			"purchaseretglaccnt",
			"cogsglaccnt",
			"unbilledglaccnt",
			"goodsintransglaccnt",
			"itemupc",
			"itemplu",
			// "usesn",
			// "sntype",
			// "useexp",
			// "forcesn",
			// "snempty",
		],
	},
};

export const CariBarang = {
	readall: "search-item",
	readallsubitem: "search-sub-item",
	readallPembelian: "search-item-on-purchase",
};

export const KategoriBarang = {
	caption: "Kategori Barang",
	general: comp.KategoriBarang,
	list: comp.ListKategoriBarang,
	create: "item-category",
	read: "item-category",
	readall: "item-category",
	update: "item-category",
	delete: "item-category",
	getaudit: "item-category-audit",
	columns: {
		title: [
			"Nama",
			"Akun Persediaan",
			"Akun Penjualan",
			"Akun Retur Penjualan",
			"Akun Diskon Penjualan",
			"Akun Retur Pembelian",
			"Akun HPP",
			"Akun Penerimaan Belum Tertagih",
			"Akun Barang Terkirim",
		],
		dataIndex: [
			"name",
			"inventoryglaccnt",
			"salesglaccnt",
			"salesretglaccnt",
			"discountglaccntt",
			"purchaseretglaccnt",
			"cogsglaccnt",
			"unbilledglaccnt",
			"goodsintransglaccnt",
		],
	},
};

export const HargaJual = {
	caption: "Harga Jual",
	general: comp.HargaJual,
	list: comp.ListHargaJual,
	create: "price-standard",
	read: "price-standard",
	readall: "price-standard",
	update: "price-standard",
	delete: "price-standard",
	getnumber: "price-standard-no",
};

export const Gudang = {
	caption: "Gudang",
	general: comp.Gudang,
	list: comp.ListGudang,
	create: "warehouse",
	read: "warehouse",
	readall: "warehouse",
	update: "warehouse",
	delete: "warehouse",
	getaudit: "warehouse-audit",
	columns: {
		title: [
			"Nama Gudang",
			"Alamat",
			"Alamat 2",
			"Alamat 3",
			"Penanggung Jawab",
			"Deskripsi",
		],
		dataIndex: [
			"name",
			"address1",
			"address2",
			"address3",
			"pic",
			"description",
		],
	},
};

export const BarangPerGudang = {
	caption: "Barang per Gudang",
	list: comp.BarangPerGudang,
	create: "Shipment",
	read: "geShipment",
	readall: "Shipment",
	update: "updateShipment",
	delete: "deleteShipment",
};

export const PencatatanNomorSerial = {
	disable: false,
	caption: "Pencatatan Nomor Serial",
	general: comp.PencatatanNomorSerial,
	list: comp.ListPencatatanNomorSerial,
	create: "serial-number-entry",
	read: "serial-number-entry",
	readall: "serial-number-entry",
	update: "serial-number-entry",
	delete: "serial-number-entry",
	getnumber: "serial-number-entry-no",
};

export const StatusProdukDanBahanBaku = {
	disable: true,
	caption: "Status Produk Dan Bahan Baku",
	general: comp.JasaPengiriman,
	list: comp.ListJasaPengiriman,
	create: "Shipment",
	read: "geShipment",
	readall: "Shipment",
	update: "updateShipment",
	delete: "deleteShipment",
};

//menu manufaktur
export const PenanggungJawabProduksi = {
	caption: "Penanggung Jawab Produksi",
	general: comp.PenanggungJawabProduksi,
	list: comp.ListPenanggungJawabProduksi,
	create: "person-produksi",
	read: "person-produksi",
	readall: "person-produksi",
	update: "person-produksi",
	delete: "person-produksi",
	getnumber: "person-produksi-no",
};
export const BiayaProduksi = {
	caption: "Biaya Produksi",
	general: comp.BiayaProduksi,
	list: comp.ListBiayaProduksi,
	create: "dlabor",
	read: "dlabor",
	readall: "dlabor",
	update: "dlabor",
	delete: "dlabor",
	getnumber: "dlabor-no",
};
export const KategoriFormulaProduk = {
	caption: "Kategori Formula Produk",
	general: comp.KategoriFormulaProduk,
	list: comp.ListKategoriFormulaProduk,
	create: "bom-category",
	read: "bom-category",
	readall: "bom-category",
	update: "bom-category",
	delete: "bom-category",
};
export const StandarisasiBiayaProduk = {
	caption: "Standarisasi Biaya Produk",
	general: comp.StandarisasiBiayaProduk,
	list: comp.ListStandarisasiBiayaProduk,
	create: "std-biaya-produksi",
	read: "std-biaya-produksi",
	readall: "std-biaya-produksi",
	update: "std-biaya-produksi",
	delete: "std-biaya-produksi",
	getnumber: "std-biaya-produksi-no",
};
export const StandarisasiHargaBarang = {
	caption: "Standarisasi Harga Barang",
	general: comp.StandarisasiHargaBarang,
	list: comp.ListStandarisasiHargaBarang,
	create: "std-item-produksi",
	read: "std-item-produksi",
	readall: "std-item-produksi",
	update: "std-item-produksi",
	delete: "std-item-produksi",
	getnumber: "std-item-produksi-no",
};
export const AkhirPeriodeProduksi = {
	caption: "Akhir Periode Produksi",
	icon: svg.akhir_periode_produksi,
	type: "modalAkhirPeriode",
	create: "period-end-prod",
	read: "period-end-prod",
	readall: "period-end-prod",
	update: "period-end-prod",
	delete: "period-end-prod",
	transtype: "PE PRD",
};

export const Preferensi = {
	caption: "Preferensi",
	type: "modalPreference",
	list: comp.Preferensi,
	create: "preference",
	readall: "preference",
	update: "preference",
	import: "import-preparation",
};

export const AddOns = {
	caption: "Plugin",
	type: "modalAddOn",
	create: "addon",
	readall: "addon",
	update: "addon",
};

export const Template = {
	caption: "Template",
	create: "template",
	read: "template",
	readall: "template",
	update: "template",
	delete: "template",
	type: "templatetype",
	readByType: "template-by-templatetype",
	default: "template-default",
	getScreen: "template-by-trans",
	updateLayout: "template-layout",
	templatefield: "templatefield",
	templatedefault: "template-templatetype-default",
};

export const PersiapanAwal = {
	caption: "Persiapan Awal",
	list: comp.ImportPreference,
};

export const DesainEmail = {
	caption: "Desain Email",
	list: comp.TemplateEmail,
	update: "email-format",
	read: "email-format",
};
export const TemplateWA = {
	caption: "Template Whatsapp",
	disable: true,
};
export const StatusDataCNT = {
	caption: "Status CnT",
	list: comp.StatusCNT,
};

export const Invitation = {
	caption: "Invitation",
	list: comp.ListInvitation,
	general: comp.Invitation,
	readall: "invitation",
	update: "invitation",
};

export const TipeActivity = {
	caption: "Tipe Activity",
	list: comp.ListTipeActivity,
	general: comp.TipeActivity,
	readall: "type-activity",
	update: "type-activity",
	delete: "type-activity",
	create: "type-activity",
	// disable: true,
};

export const SchedulingSales = {
	caption: "Scheduling Sales",
	list: comp.ListSchedulingSales,
	general: comp.SchedulingSales,
	readall: "schedule-sales",
	update: "schedule-sales",
	delete: "schedule-sales",
	create: "schedule-sales",
	// disable: true,
};

export const SalesmanActivity = {
	caption: "Salesman Activity",
	list: comp.ListSalesmanActivity,
	general: comp.SalesmanActivity,
	readall: "schedule-sales",
	update: "schedule-sales",
	delete: "schedule-sales",
	create: "schedule-sales",
	read: "salesman-activity",
	// disable: true,
};

export const CalendarScheduling = {
	caption: "Calendar Scheduling",
	disable: true,
};

export const ModCNT = {
	caption: "Connect and Tracking",
	submenu: true,
	data: [
		DesainEmail,
		TemplateWA,
		StatusDataCNT,
		Invitation,
		TipeActivity,
		SchedulingSales,
		SalesmanActivity,
		CalendarScheduling,
	],
};

export const Penjualan = [
	PenawaranPenjualan,
	PesananPenjualan,
	FakturSementara,
	PengirimanBarang,
	FakturPenjualan,
	FakturPenagihan,
	PenerimaanPenjualan,
	ReturPenjualan,
];
export const Pembelian = [
	PermintaanPembelian,
	PesananPembelian,
	PenerimaanBarang,
	FakturPembelian,
	PembayaranPembelian,
	ReturPembelian,
];
export const BukuBesar = [
	JurnalUmum,
	RekonsiliasiBank,
	PembayaranLainnya,
	PenerimaanLainnya,
];
export const BukuBesarNavbar = [
	JurnalUmum,
	DaftarAkun,
	RekonsiliasiBank,
	PembayaranLainnya,
	PenerimaanLainnya,
	SalinanTransaksi,
];
export const Persediaan = [
	PenyesuaianBarang,
	PembiayaanPesanan,
	PindahBarang,
	PengelompokanBarang,
];
const ImpPembelian = [
	ImpPesananPembelian,
	ImpPenerimaanBarang,
	ImpFakturPembelian,
	ImpPembayaranPembelian,
];
const ImpPenjualan = [
	ImpPesananPenjualan,
	ImpPengirimanBarang,
	ImpFakturPenjualan,
	ImpPenerimaanPenjualan,
];
const ImpManufaktur = [ImpPengeluaranBahan];
const ImpPersediaan = [ImpPindahBarang, ImpPenyesuaianPersediaan];
const impBukuBesar = [ImpJurnalUmum];
const ImportAktivaTetap = [ImpAktivaTetap];
const ImportPowerCash = [ImpPowerCash];
const PowerCash = [KartuBankPOS, MesinEDCPOS, Promo, PostingDataKasir];
export const Aktiva = [AktivaTetap, TipeAktivaTetapPajak, TipeAktivaTetap];
export const Manufaktur = [
	FormulaProduk,
	PerintahKerja,
	PengeluaranBahanBaku,
	HasilProduksi,
];
export const ManufakturMobileModern = [
	FormulaProduk,
	PerintahKerja,
	AkhirPeriodeProduksi,
	PengeluaranBahanBaku,
	HasilProduksi,
];

export const PenjualanModern = [
	PenawaranPenjualan,
	PesananPenjualan,
	FakturSementara,
	PengirimanBarang,
	FakturPenjualan,
	FakturPenagihan,
	PenerimaanPenjualan,
	ReturPenjualan,
];
export const PembelianModern = [
	PermintaanPembelian,
	PesananPembelian,
	PenerimaanBarang,
	FakturPembelian,
	PembayaranPembelian,
	ReturPembelian,
];
export const BukuBesarModern = [
	JurnalUmum,
	RekonsiliasiBank,
	DaftarAkun,
	Kosong,
	PembayaranLainnya,
	PenerimaanLainnya,
];
export const BukuBesarNavbarModern = [
	JurnalUmum,
	DaftarAkun,
	RekonsiliasiBank,
	PembayaranLainnya,
	PenerimaanLainnya,
	SalinanTransaksi,
];
export const PersediaanModern = [
	PenyesuaianBarang,
	PembiayaanPesanan,
	Barang,
	Kosong,
	PindahBarang,
	PengelompokanBarang,
];
export const AktivaModern = [
	AktivaTetap,
	TipeAktivaTetap,
	Kosong,
	Kosong,
	TipeAktivaTetapPajak,
];
export const ManufakturModern = [
	FormulaProduk,
	PerintahKerja,
	AkhirPeriodeProduksi,
	Kosong,
	PengeluaranBahanBaku,
	HasilProduksi,
];

export const PersediaanMenu = [
	Barang,
	KategoriBarang,
	// HargaJual,
	Gudang,
	// BarangPerGudang,
	PenyesuaianBarang,
	// PembiayaanPesanan,
	PindahBarang,
	// PencatatanNomorSerial,
	// StatusProdukDanBahanBaku,
];
export const ManufakturMenu = [
	PenanggungJawabProduksi,
	BiayaProduksi,
	KategoriFormulaProduk,
	StandarisasiBiayaProduk,
	StandarisasiHargaBarang,
	FormulaProduk,
	PerintahKerja,
	PengeluaranBahanBaku,
	HasilProduksi,
	AkhirPeriodeProduksi,
];
export const MasterData = [
	MataUang,
	StatusPelangganPemasok,
	TipePelanggan,
	Pelanggan,
	Pemasok,
	Penjual,
	Komisi,
	Departemen,
	Proyek,
	Pajak,
	SyaratPembayaran,
	JasaPengiriman,
	Cabang,
];
export const Menu = [
	TutupDB,
	InformasiPerusahaan,
	PersiapanAwal,
	Konsolidasi,
	Pengguna,
	TutupBukuCabang,
	Chat,
	Logout,
];
export const AlatBantu = [
	Preferensi,
	AddOns,
	// HitungUlangSaldo,
	// RiwayatData,
	SekilasEASY,
	Panduan,
	RiwayatUpdate,
	// Registrasi,
	Registration,
	QuickSupport,
	LaporanBugError,
	VideoTutorial,
	RepairData,
	ThreadHpp,
	StatusHpp,
];

// const ModulUtama = [];
export const AllMenu = [
	...Pembelian,
	...Penjualan,
	...BukuBesar,
	...Persediaan,
	...Aktiva,
	...Manufaktur,
	...PersediaanMenu,
	...ManufakturMenu,
	...AlatBantu,
	...Menu,
];

// export const Laporan = [SemuaLaporan, RancanganFormulir, Editor];
export const Laporan = [SemuaLaporan, RancanganFormulir];

export const MenuModern = [
	{
		name: "Manufaktur",
		key: 5,
		color: "#93328E",
		svg: svg.manufaktur,
		menu: "ManufakturModern",
		disable: false,
	},
	{
		name: "Pembelian",
		key: 2,
		color: "#00AB14",
		svg: svg.pembelian,
		menu: "PembelianModern",
		disable: false,
	},
	{
		name: "Aktiva Tetap",
		key: 6,
		color: "#DF1995",
		svg: svg.aktiva,
		menu: "AktivaModern",
		disable: false,
	},
	{
		name: "Home",
		key: 3,
		color: "#fff",
		svg: svg.logoHome,
		menu: "Home",
		disable: false,
	},
	{
		name: "Penjualan",
		key: 0,
		color: "#EE2737",
		svg: svg.penjualan,
		menu: "PenjualanModern",
		disable: false,
	},
	{
		name: "Persediaan",
		key: 4,
		color: "#F06400",
		svg: svg.persediaan,
		menu: "PersediaanModern",
		disable: false,
	},
	{
		name: "Buku Besar",
		key: 1,
		color: "#00AED6",
		svg: svg.buku_besar,
		menu: "BukuBesarModern",
		disable: false,
	},
];

export const RancanganLaporan = [
	{
		name: "Pembelian",
		key: 1,
		laporan: [
			{
				name: "Permintaan Pembelian",
				key: 1,
				icon: "snippets",
				component: comp.designPreview,
			},
			{
				name: "Pesanan Pembelian",
				key: 2,
				icon: "snippets",
				component: null,
			},
			{
				name: "Penerimaan Barang",
				key: 3,
				icon: "snippets",
				component: null,
			},
			{
				name: "Faktur Pembelian",
				key: 4,
				icon: "snippets",
				component: null,
			},
			{
				name: "Retur Pembelian",
				key: 5,
				icon: "snippets",
				component: null,
			},
			{
				name: "Pembayaran Pembelian",
				key: 6,
				icon: "snippets",
				component: null,
			},
		],
	},
	{
		name: "Penjualan",
		key: 2,
		laporan: [
			{
				name: "Penawaran Penjualan",
				key: 1,
				icon: "snippets",
				component: comp.designPreview,
			},
			{
				name: "Pesanan Penjualan",
				key: 2,
				icon: "snippets",
				component: null,
			},
			{
				name: "Pengiriman Barang",
				key: 3,
				icon: "snippets",
				component: null,
			},
			{
				name: "Faktur Penjualan",
				key: 4,
				icon: "snippets",
				component: null,
			},
			{
				name: "Retur Penjualan",
				key: 5,
				icon: "snippets",
				component: null,
			},
			{
				name: "Penerimaan Penjualan",
				key: 6,
				icon: "snippets",
				component: null,
			},
			{
				name: "Faktur Pajak Lokal",
				key: 7,
				icon: "snippets",
				component: null,
			},
			{
				name: "Faktur Pajak Asing",
				key: 8,
				icon: "snippets",
				component: null,
			},
		],
	},
	{
		name: "Buku Besar",
		key: 3,
		laporan: [
			{
				name: "Jurnal Umum",
				key: 1,
				icon: "snippets",
				component: null,
			},
			{
				name: "Pembayaran Lainnya",
				key: 2,
				icon: "snippets",
				component: null,
			},
			{
				name: "Penerimaan Lainnya",
				key: 3,
				icon: "snippets",
				component: null,
			},
		],
	},
	{
		name: "Persediaan",
		key: 4,
		laporan: [
			{
				name: "Pindah Barang",
				key: 1,
				icon: "snippets",
				component: null,
			},
			{
				name: "Penyesuaian Barang",
				key: 2,
				icon: "snippets",
				component: null,
			},
			{
				name: "Pembiayaan Pesanan",
				key: 3,
				icon: "snippets",
				component: null,
			},
		],
	},
	{
		name: "Aktiva Tetap",
		key: 5,
		laporan: [
			{
				name: "Aktiva Tetap",
				key: 1,
				icon: "snippets",
				component: null,
			},
		],
	},
	{
		name: "Manufaktur",
		key: 6,
		laporan: [
			{
				name: "Standart Biaya Produksi",
				key: 1,
				icon: "snippets",
				component: null,
			},
			{
				name: "Standart Harga Barang",
				key: 2,
				icon: "snippets",
				component: null,
			},
			{
				name: "Formula Produk",
				key: 3,
				icon: "snippets",
				component: null,
			},
			{
				name: "Perintah Kerja",
				key: 4,
				icon: "snippets",
				component: null,
			},
			{
				name: "Detail Perintah Kerja",
				key: 5,
				icon: "snippets",
				component: null,
			},
			{
				name: "Pengeluaran Bahan Baku",
				key: 6,
				icon: "snippets",
				component: null,
			},
			{
				name: "Hasil Produksi",
				key: 7,
				icon: "snippets",
				component: null,
			},
		],
	},
];

const ModPersediaan = {
	caption: "Persediaan",
	submenu: true,
	data: [
		Satuan,
		Barang,
		KategoriBarang,
		HargaJual,
		Gudang,
		BarangPerGudang,
		PenyesuaianBarang,
		PembiayaanPesanan,
		PindahBarang,
		PencatatanNomorSerial,
		StatusProdukDanBahanBaku,
	],
};

const ModManufaktur = {
	caption: "Manufaktur",
	submenu: true,
	data: ManufakturMenu,
};
export const ModBukuBesar = {
	caption: "Buku Besar",
	submenu: true,
	data: [
		JurnalUmum,
		PembayaranLainnya,
		PenerimaanLainnya,
		DaftarAkun,
		SaldoAkun,
		RiwayatAkun,
		AnggaranAkun,
		BukuBank,
		RekonsiliasiBank,
		TransaksiBerulang,
		// Recurring,
		SalinanTransaksi,
		AkhirPeriode,
	],
};

const ModAktivaTetap = {
	caption: "Aktiva Tetap",
	submenu: true,
	data: Aktiva,
};

const ModPowerCash = {
	caption: "Power Cash",
	submenu: true,
	data: PowerCash,
};

const ModEcommerce = {
	caption: "E-Commerce",
	submenu: true,
	data: [ModCNT],
};

export let HeaderMenu = [
	{
		caption: "Menu",
		// submenu: true,
		data: Menu,
		ic: svg.Menu,
	},
	{
		caption: "Master Data",
		data: MasterData,
		// submenu: true,
		ic: svg.MasterData,
	},
	{
		caption: "Modul Utama",
		// submenu: true,
		ic: svg.ModulUtama,
		data: [
			ModPembelian,
			ModPenjualan,
			ModBukuBesar,
			ModPersediaan,
			ModManufaktur,
			ModAktivaTetap,
			ModCatatanPersetujuan,
			ModPowerCash,
			ModEcommerce,
		],
	},
	{
		caption: "Import Transaksi",
		// submenu: true,
		ic: svg.Import,
		data: [
			{
				caption: "Pembelian",
				submenu: true,
				data: ImpPembelian,
			},
			{
				caption: "Penjualan",
				submenu: true,
				data: ImpPenjualan,
			},
			{
				caption: "Buku Besar",
				submenu: true,
				data: impBukuBesar,
			},
			{
				caption: "Persediaan",
				submenu: true,
				data: ImpPersediaan,
			},
			{
				caption: "Manufaktur",
				submenu: true,
				data: ImpManufaktur,
			},
			{
				caption: "Aktiva Tetap",
				submenu: true,
				data: ImportAktivaTetap,
			},
			{
				caption: "Power Cash",
				submenu: true,
				data: ImportPowerCash,
			},
		],
	},
	{
		caption: "Laporan",
		// submenu: true,
		data: Laporan,
		ic: svg.Laporan,
	},
	{
		caption: "Alat Bantu",
		// submenu: true,
		data: AlatBantu,
		ic: svg.Pengaturan,
	},
];

export const Billing = {
	getbill: "bill",
	get: "payments",
	create: "bill-invoice",
	usedActivation: "use-activation-code",
	usedVoucher: "use-voucher-code",
	historyDB: "history-database",
	getInvoice: "bill-invoice",
	getInvoiceDetail: "bill-invoice-detail",
	expire: "expire",
};

// export const formDelphi = {

// }
