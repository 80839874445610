import {
	SELECT_OBJECTS,
	ADD_NEW_OBJECT,
	DESELECT_ALL_OBJECTS,
	DESELECT_ALL_OBJECTS_EXEPT,
	MOVE_OBJECT,
	SET_SELECTED_OBJECTS_EDIT_START_POSITION,
	RESIZE_OBJECTS,
	ROTATE_OBJECT,
	SET_TEXT_ALIGN,
	SET_VERTICAL_ALIGN,
	SET_FILL_COLOR,
	SET_STROKE_COLOR,
	SET_STROKE_WIDTH,
	UPDATE_OBJECT_TEXT,
	LOAD_OBJECT,
	CLEAR_OBJECT,
	DELETE_OBJECT,
	RESIZE_PAPER,
	SET_TABLE_NAME,
	SET_TABLE_FIELDNAME,
	SHOW_PREVIEW,
	USE_BACKGROUND,
	SET_FRAME,
	SET_VISIBLEMASTER,
	SET_FONT,
	SET_FONTCOLOR,
	SET_TEXT,
	SET_MODE,
	SET_POSITION_RULER,
	ADD_RULER,
	REMOVE_RULER,
	USE_SAMPLEDATA,
	SET_FRACTIONDIGIT,
	SET_WORD_WRAP,
	SET_FRACTION,
} from "../../constants";

function updateObjects(state, ids, payload) {
	let updatedObjectsByHash = { ...state.objectsByHash };

	ids.forEach((id) => {
		updatedObjectsByHash[id] = {
			...updatedObjectsByHash[id],
			...payload,
		};
	});

	return { ...state, objectsByHash: updatedObjectsByHash };
}

function updatePaper(state, payload) {
	let sizePaper = { ...state.sizePaper, ...payload };
	return { ...state, sizePaper };
}

function changeValue(value, desc, obj) {
	for (const i in obj) {
		if (obj[i].name === value) {
			obj[i].position = desc;
			break; //Stop this loop, we found it!
		}
	}
}

function updateRuller(state, id, payload) {
	let ruler = state.ruler;
	changeValue(id, payload.position, ruler);
	return { ...state, ruler };
}

function deleteObjects(state, ids) {
	let objectsByHash = { ...state.objectsByHash };
	let objectsById = [...state.objectsById];

	delete objectsByHash[ids[0]];
	objectsById = objectsById.filter((item) => item !== ids[0]);

	return {
		...state,
		objectsById: [...objectsById],
		selectedObjectsId: [],
		objectsByHash: { ...objectsByHash },
	};
}

function updateObjectNestedProps(state, ids, payload, nestedProps) {
	let updatedObjectsByHash = { ...state.objectsByHash };

	ids.forEach((id) => {
		updatedObjectsByHash[id] = {
			...updatedObjectsByHash[id],
			[nestedProps]: {
				...updatedObjectsByHash[id][nestedProps],
				...payload,
			},
		};
	});

	return { ...state, objectsByHash: updatedObjectsByHash };
}

const initialState = {
	editMode: null,
	mousePosition: {
		x: null,
		y: null,
	},
	selectedObjectsId: [],
	objectsById: [],
	objectsByHash: {},
	sizePaper: {
		name: "A5",
		orientation: "Landscape",
		height: 148,
		width: 210,
		detailCount: 10,
		fraction: 6,
	},
	preview: false,
};

export default (state = initialState, action) => {
	let updatedObjectsByHash = {};

	switch (action.type) {
		case SELECT_OBJECTS:
			return {
				...state,
				selectedObjectsId: state.selectedObjectsId.concat(action.payload.ids),
			};

		case DESELECT_ALL_OBJECTS:
			return { ...state, selectedObjectsId: [] };

		case DESELECT_ALL_OBJECTS_EXEPT:
			return { ...state, selectedObjectsId: [action.payload] };

		case MOVE_OBJECT:
		case SET_TEXT:
		case SET_MODE:
			return updateObjects(state, action.ids, action.payload);

		case SET_SELECTED_OBJECTS_EDIT_START_POSITION:
			updatedObjectsByHash = { ...state.objectsByHash };

			state.selectedObjectsId.forEach((id) => {
				updatedObjectsByHash[id] = {
					...updatedObjectsByHash[id],
					editStartPositionOffset: {
						x: action.payload.x - updatedObjectsByHash[id].x,
						y: action.payload.y - updatedObjectsByHash[id].y,
					},
				};
			});

			return { ...state, objectsByHash: updatedObjectsByHash };

		case RESIZE_OBJECTS:
			return updateObjects(state, action.ids, action.payload);

		case RESIZE_PAPER:
			return updatePaper(state, action.payload);

		case SET_FRACTIONDIGIT:
			return updatePaper(state, action.payload);

		case SET_POSITION_RULER:
			return updateRuller(state, action.id, action.payload);

		case ADD_RULER:
			return {
				...state,
				ruler: [...state.ruler, action.payload],
			};

		case REMOVE_RULER:
			return {
				...state,
				ruler: [],
			};

		case ROTATE_OBJECT:
			return updateObjects(state, [action.id], action.payload);

		case DELETE_OBJECT:
			return deleteObjects(state, [action.id]);

		case SET_VERTICAL_ALIGN:
			return updateObjectNestedProps(
				state,
				state.selectedObjectsId,
				action.payload,
				action.tipe
			);

		case SET_TEXT_ALIGN:
			return updateObjectNestedProps(
				state,
				state.selectedObjectsId,
				action.payload,
				action.tipe
			);

		case SET_WORD_WRAP:
			return updateObjectNestedProps(
				state,
				state.selectedObjectsId,
				action.payload,
				action.tipe
			);

		case SET_FRAME:
			return updateObjectNestedProps(
				state,
				state.selectedObjectsId,
				action.payload,
				action.tipe
			);

		case SET_FONT:
			return updateObjectNestedProps(
				state,
				state.selectedObjectsId,
				action.payload,
				action.tipe
			);

		case SET_FRACTION:
			return updateObjectNestedProps(
				state,
				state.selectedObjectsId,
				action.payload,
				action.tipe
			);

		case SET_FONTCOLOR:
			return updateObjectNestedProps(
				state,
				state.selectedObjectsId,
				action.payload,
				action.tipe
			);

		case SET_TABLE_NAME:
			return updateObjectNestedProps(
				state,
				state.selectedObjectsId,
				action.payload,
				"table"
			);

		case SET_TABLE_FIELDNAME:
			return updateObjectNestedProps(
				state,
				state.selectedObjectsId,
				action.payload,
				"table"
			);

		case SET_FILL_COLOR:
			return updateObjects(state, state.selectedObjectsId, action.payload);

		case SET_VISIBLEMASTER:
			return updateObjects(state, state.selectedObjectsId, action.payload);

		case SET_STROKE_COLOR:
			return updateObjects(state, state.selectedObjectsId, action.payload);

		case SET_STROKE_WIDTH:
			return updateObjects(state, state.selectedObjectsId, action.payload);

		case ADD_NEW_OBJECT:
			return {
				...state,
				objectsById: [...state.objectsById, action.id],
				objectsByHash: {
					...state.objectsByHash,
					[action.id]: action.payload.object,
				},
			};

		case LOAD_OBJECT:
			return {
				...state,
				...action.payload.object,
			};

		case CLEAR_OBJECT:
			return {
				...state,
				selectedObjectsId: [],
				objectsById: [],
				objectsByHash: {},
				ruler: [],
			};

		case UPDATE_OBJECT_TEXT:
			return updateObjects(state, [action.id], action.payload);

		case SHOW_PREVIEW:
			return {
				...state,
				preview: !state.preview,
			};
		case USE_BACKGROUND:
			return {
				...state,
				...action.payload,
			};
		case USE_SAMPLEDATA:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};
