import * as types from "../../constants";

export function daftarPengguna(body) {
	return {
		type: types.DAFTAR_PENGGUNA,
		payload: body.daftarPengguna,
	};
}

export function profileCompany(body) {
	return {
		type: types.PROFILE_COMPANY,
		payload: body,
	};
}

export function formatDateCompany(body) {
	return {
		type: types.FORMAT_DATE_COMPANY,
		payload: body,
	};
}

export function transCode(body) {
	return {
		type: types.TRANSCODE,
		payload: body,
	};
}

export function hakAkses(body) {
	return {
		type: types.HAK_AKSES,
		payload: body,
	};
}

export function loadingHandleTenant(data) {
	return {
		type: types.FETCH_TENANT,
		payload: data,
	};
}
export function allChart(data) {
	return {
		type: types.ALL_CHART,
		payload: data,
	};
}

export function setHpp(data) {
	return {
		type: types.HPP,
		payload: data,
	};
}
