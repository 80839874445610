import * as types from "../../constants";
const initialState = {
  listGroup: [],
  isOpen: false,
  collapse: false,
  currentOpen: {
    data: null,
    chat: [],
  },
};
export default function Chat(state = initialState, action) {
  switch (action.type) {
    case types.OPEN_GROUP:
      return {
        ...state,
        isOpen: true,
        currentOpen: {
          ...state.currentOpen,
          group: action.payload,
        },
      };
    case types.OPEN_CHAT:
      return {
        ...state,
        currentOpen: {
          ...state.currentOpen,
          data: action.payload.data,
          chat: action.payload.chat,
        },
      };
    case types.ADD_GROUP:
      return {
        ...state,
        listGroup: [...new Set([...state.listGroup, ...action.payload])],
      };
    case types.NEW_CHAT:
      const { data } = action.payload;
      let currentList = state.listGroup.some(function (item) {
        return item.idDatabase === data.idDatabase;
      });
      return {
        ...state,
        isOpen: true,
        currentOpen: {
          ...state.currentOpen,
          data: action.payload.data,
          group: action.payload.group,
        },
        listGroup: !currentList
          ? [...new Set([...state.listGroup, action.payload.data])]
          : [...state.listGroup],
      };
    case types.SEND_CHAT:
      let newData = state.currentOpen.chat.concat(action.payload);
      return {
        ...state,
        currentOpen: {
          ...state.currentOpen,
          chat: newData,
        },
      };
    case types.RESET_CHAT:
      return {
        listGroup: [],
        isOpen: false,
        currentOpen: {
          data: null,
          chat: [],
        },
      };
    case types.CLOSE_CHAT:
      return {
        ...state,
        isOpoen: false,
      };
    case types.COLLAPSE:
      return {
        ...state,
        collapse: action.payload,
      };
    default:
      return state;
  }
}
