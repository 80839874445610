export const Persetujuan = (formatDate, moment) => {
  return {
    body: {
      details: [
        {
          approved: false,
          approvedate: null,
          description: "",
          followup: false,
          follupdesc: null,
          note: null,
          noteid: 0,
          status: "",
          transdate: null,
          transid: 0,
          transno: "",
        },
      ],
      master: {
        approved: false,
        approvedesc: "",
        followup: false,
        notedate: moment().format(formatDate),
        noteid: 0,
        transid: 0,
        approveno: "",
        type: null,
        typenote: "",
        userid: 0,
      },
    },
    cellSelected: { onidx: null, onrowIdx: null },
    idTable: null,
  };
};
