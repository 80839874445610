import { Button, Checkbox, Tag } from "antd";
import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import withStyles from "react-jss";
import { styles } from "../../assets/styles/jss/components/preferensi-database";
import ModalAddCurrency from "../../components/modal/modal-add-currency";
import { getAll } from "../../utility/api/crud";
import { ErrorMessage } from "../../utility/functions/publicFunctions";

class FiturDasar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      daftar: {
        mataUang: [],
      },
      defaultCurrency: [],
      currency: false,
      modalcurrency: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.parentState.pcurrency &&
      props.parentState.pcurrency !== state.daftar.mataUang
    ) {
      let defCurrency = props.parentState.pcurrency.map(
        ({ currencyname }) => currencyname
      );
      return {
        defaultCurrency: defCurrency,
        daftar: {
          mataUang: props.parentState.pcurrency,
        },
      };
    }
    return null;
  }

  getMataUang() {
    const { tenant } = this.props;
    const dataSourceKey = tenant.tenantId.dataSourceKey;
    let getAllCur = getAll(dataSourceKey, "MataUang");
    getAllCur
      .then((response) => {
        let defCurrency = response.listdata.map(({ currencyid }) => currencyid);
        this.setState({
          defaultCurrency: defCurrency,
          daftar: {
            ...this.state.daftar,
            mataUang: response.listdata,
          },
        });
      })
      .catch((error) => {
        ErrorMessage(error);
      });
  }
  componentDidMount() {
    // this.getMataUang();
  }
  showCloseModal(e, modal) {
    e.preventDefault();
    this.setState({
      [modal.name]: !this.state[modal.name],
    });
  }

  render() {
    const { daftar } = this.state;
    const { parentState, handle, t } = this.props;
    return (
      <div>
        <header>
          <desc className="welcome_desc">
            {t(
              "Centang pernyataan berikut agar Easy Cloud menyesuaikan kebutuhan perusahaan Anda."
            )}
          </desc>
        </header>
        <div className="content-preference">
          <form className="form__fitur-dasar">
            <div>
              <div>
                <Checkbox
                  className="field"
                  name="eabt"
                  checked={parentState.pcompany.features.perusahaan.eabt}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "perusahaan",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t("Perusahaan Anda memiliki banyak cabang.")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  name="usemulticurrency"
                  className="field currency"
                  checked={parentState.pcompany.usemulticurrency}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t("Perusahaan bertransaksi dengan multi mata uang asing.")}
                </Checkbox>
                <ModalAddCurrency
                  parentState={parentState}
                  handle={handle}
                  visible={this.state.modalcurrency}
                  onCancel={(e) =>
                    this.showCloseModal(e, { name: "modalcurrency" })
                  }
                  onOk={(e) =>
                    this.showCloseModal(e, { name: "modalcurrency" })
                  }
                />
                <div
                  className={`child expand__mata-uang ${
                    parentState.pcompany.usemulticurrency ? "collapses" : ""
                  }`}
                >
                  <div className="tags">
                    {daftar.mataUang.map((tag) => (
                      <Tag
                        closable
                        onClose={(e) =>
                          handle.onChange(
                            {
                              target: {
                                name: "pcurrency",
                                value: e,
                                action: "delete",
                              },
                            },
                            "pcurrency"
                          )
                        }
                        id={tag.currencyname}
                        key={tag.currencyname}
                      >
                        {tag.currencyname}
                      </Tag>
                    ))}
                  </div>
                  {/* <Select
                    disabled
                    size="small"
                    mode="multiple"
                    optionLabelProp="children"
                    onChange={(e) =>
                      handle.onChange(
                        {
                          target: {
                            name: "pcurrency",
                            value: e,
                            action: "delete",
                          },
                        },
                        "pcurrency"
                      )
                    }
                    value={this.state.defaultCurrency}
                    defaultValue={this.state.defaultCurrency}
                  >
                    {daftar.mataUang.map((opt) => (
                      <Option key={opt.currencyname} value={opt.currencyname}>
                        {opt.currencyname}
                      </Option>
                    ))}
                  </Select> */}
                  <Button
                    size="small"
                    type="primary"
                    onClick={(e) =>
                      this.showCloseModal(e, { name: "modalcurrency" })
                    }
                  >
                    {t("Tambah Mata Uang")}
                  </Button>
                </div>
              </div>
              <div>
                <Checkbox
                  className="field"
                  name="usetax"
                  checked={parentState.pcompany.usetax}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t("Perusahaan menggunakan pajak pertambahan nilai.")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  className="field"
                  name="approval"
                  checked={parentState.pcompany.features.perusahaan.approval}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "perusahaan",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t(
                    "Perusahaan Anda memerlukan persetujuan dalam kegiatan bertransaksi."
                  )}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  className="field"
                  name="listingfixedassets"
                  checked={
                    parentState.pcompany.features.perusahaan.listingfixedassets
                  }
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "perusahaan",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t(
                    "Perusahaan Anda mencatat aktiva tetap yang dimiliki perusahaan."
                  )}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  className="field"
                  name="usebudgets"
                  checked={parentState.pcompany.features.perusahaan.usebudgets}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "perusahaan",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t(
                    "Perusahaan Anda menghitung anggaran biaya, beban dan penjualan."
                  )}
                </Checkbox>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withNamespaces("translations")(withStyles(styles)(FiturDasar));
