export const initialState = (formatDate, moment) => {
  return {
    setInitialState: false,
    visible: false,
    tabPane: "1",
    errorPage: null,
    modalExchangerate: false,
    loadingTable_transaksi: false,
    loadingTable_barang: false,
    loadingTable_pemasok: false,
    loadingTable_salinTransaksi: false,
    permintaanPembelian: [],
    dataMemorize: [],
    total: {
      subtotal: 0,
      diskon: 0,
      ppn: 0.1,
      pajak2: 0,
      freight: 0,
      jumlah: 0,
    },
    validation: [],
    modalTarikTransaksi: false,
    modalSimpanTransaksi: false,
    modalSalinTransaksi: false,
    modalPemasok: false,
    loading_save: false,
    loading: {
      saveExit: false,
      next: false,
      previous: false,
      pratinjau: false,
      dp: false,
      recurring: false,
      cnt: false,
      email: false,
    },

    prevClosed: false,
    personData: {},
    daftar: {
      pemasok: [],
      akun: [],
    },
    body: {
      details: [
        {
          brutounitprice: 0,
          closed: false,
          deptid: "",
          groupno: "",
          groupseq: 0,
          itemdiscpc: "0",
          itemid: "",
          itemno: "",
          itemovdesc: "",
          itemreserved1: "",
          itemreserved10: "",
          itemreserved11: "",
          itemreserved12: "",
          itemreserved13: "",
          itemreserved14: "",
          itemreserved15: "",
          itemreserved16: "",
          itemreserved17: "",
          itemreserved18: "",
          itemreserved19: "",
          itemreserved2: "",
          itemreserved20: "",
          itemreserved3: "",
          itemreserved4: "",
          itemreserved5: "",
          itemreserved6: "",
          itemreserved7: "",
          itemreserved8: "",
          itemreserved9: "",
          itemunit: "",
          jumlah: 0,
          poid: 0,
          projectid: "",
          qtyrecv: 0,
          quantity: "",
          reqid: 0,
          reqseq: 0,
          seq: 0,
          taxableamount1: "",
          taxableamount2: "",
          taxcodes: "",
          unitprice: 0,
          unitratio: 1,
          detreqno: "",
        },
      ],
      master: {
        approved: false,
        approvedesc: null,
        cashdiscount: 0,
        cashdiscpc: "",
        checknote: true,
        chequedate: moment().format(formatDate),
        chequeno: "",
        closed: false,
        cntcode: "",
        description: "",
        dp: 0,
        dpaccount: 0,
        dpused: 0,
        expected: null,
        fiscalrate: 0,
        fob: "",
        follow: "",
        followup: false,
        urgent: false,
        freight: 0,
        freightaccnt: null,
        freighttocost: true,
        freighttovendor: true,
        getfrompr: true,
        glhistid: 0,
        glperiod: 0,
        glyear: 0,
        imported: 0,
        inclusivetax: false,
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
        locked_by: "",
        locked_time: "",
        noteid: 0,
        paidfrom: 0,
        poamount: 0,
        podate: moment().format(formatDate),
        poid: 0,
        pono: "",
        proceed: false,
        qtyrcv: 0,
        rate: null,
        reconcileid: 0,
        roundedtax1amount: 0,
        roundedtax2amount: 0,
        shipto1: "",
        shipto2: "",
        shipto3: "",
        shipto4: "",
        shipto5: "",
        shipvendid: 0,
        shipvia: null,
        status: "",
        subtotal: 0,
        tax1amount: 0,
        tax1code: "",
        tax1id: 0,
        tax1rate: 0,
        tax2amount: 0,
        tax2code: "",
        tax2id: 0,
        tax2rate: 0,
        taxableamount1: 0,
        taxableamount2: 0,
        taxableamount3: 0,
        taxableamtcode1: "",
        taxableamtcode2: "",
        taxableamtcode3: "",
        templateid: 16,
        termid: null,
        userid: 0,
        vendorid: "",
        vendoristaxable: false,
        idnext: 0,
        idprev: 0,
        lastid: 0,
        newdp: "",
        newdpused: "",
        currencyname: "",
        transtype: "AP ORD",
        recdetid: 0,
      },
      templatedetails: [],
      attachment: [],
    },
    insert: true,
    judul: "Data Baru",
    dataTemplate: [],
    formatDate: formatDate,
    hasChange: false,
  };
};
