import classNames from "classnames";
import React from "react";
import withStyles from "react-jss";
import { styles } from "../../../assets/styles/jss/components/sidebar";
function ItemSidebar(props) {
  const { active, title, classes, onClick, icon } = props;
  return (
    <li
      onClick={onClick}
      className={classNames(
        "s-sidebar__nav-link-preparation",
        active && classes.activeContainer
      )}
    >
      <img src={icon} className={classes.icon_title} alt="" />
      <em className={classes.title}>{title}</em>
    </li>
  );
}

export default withStyles(styles)(ItemSidebar);
