export const initialState = (formatDate, moment) => {
    return {
        visible: false,
        tabPane: "1",
        errorPage: null,
        loadingTable_barang: false,
        loadingTable_pemasok: false,
        loadingTable_salinTransaksi: false,
        loading: {
            next: false,
            saveExit: false,
            previous: false,
            pratinjau: false,
            jurnal: false,
            recurring: false,
        },
        validation: [],
        daftar: {
            kategori: [],
            barang: [],
            unit: []
        },
        dataTarikan: [],
        tipeTarikan: "Pesanan",
        modalTarikTransaksi: false,
        modalPemasok: false,
        modalExchangerate: false,
        modalSimpanTransaksi: false,
        modalSalinTransaksi: false,
        loading_save: false,
        body: {
            itmdetails: [
                {
                    item: [],
                    "bomid": 0,
                    "cost": 0,
                    "costunit": 0,
                    "itemreserved1": "",
                    "itemreserved10": "",
                    "itemreserved11": "",
                    "itemreserved12": "",
                    "itemreserved13": "",
                    "itemreserved14": "",
                    "itemreserved15": "",
                    "itemreserved16": "",
                    "itemreserved17": "",
                    "itemreserved18": "",
                    "itemreserved19": "",
                    "itemreserved2": "",
                    "itemreserved20": "",
                    "itemreserved3": "",
                    "itemreserved4": "",
                    "itemreserved5": "",
                    "itemreserved6": "",
                    "itemreserved7": "",
                    "itemreserved8": "",
                    "itemreserved9": "",
                    "itemunit": "",
                    "keterangan": "",
                    "materialno": "",
                    "materialqty": 0,
                    "seq": 0,
                    "unitratio": 0
                }
            ],
            "details": [
                {
                    dlabor: [],
                    "bomid": 0,
                    "cost": 0,
                    "dlaborid": 0,
                    "dlaborqty": 0,
                    "itemreserved1": "",
                    "itemreserved10": "",
                    "itemreserved2": "",
                    "itemreserved3": "",
                    "itemreserved4": "",
                    "itemreserved5": "",
                    "itemreserved6": "",
                    "itemreserved7": "",
                    "itemreserved8": "",
                    "itemreserved9": "",
                    "keterangan": "",
                    "seq": 0
                }
            ],
            "master": {
                "approved": false,
                "bomid": 0,
                "bomno": "",
                "categoryid": null,
                "checknote": false,
                "cost": 0,
                "defaultbom": false,
                "description": "",
                "dlaborcost": 0,
                "expensecost": 0,
                "follow": "",
                "followup": false,
                "itemno": "",
                "link01": "",
                "link02": "",
                "link03": "",
                "link04": "",
                "link05": "",
                "link06": "",
                "link07": "",
                "link08": "",
                "link09": "",
                "link10": "",
                "link11": "",
                "link12": "",
                "link13": "",
                "link14": "",
                "link15": "",
                "link16": "",
                "link17": "",
                "link18": "",
                "link19": "",
                "link20": "",
                "matbomcost": 0,
                "matcost": 0,
                "minqty": 0,
                "mohcost": 0,
                "noteid": 0,
                "qtybuild": 0,
                "suspended": false,
                "templateid": 0,
                "unit": "",
                "unitratio": 0,
                "updatedate": moment().format(formatDate),
                "urgent": false,
                "userid": 0,
                refproduk: false,
                idnext: 0,
                idprev: 0,
            },
            templatedetails: [],
        },
        insert: true,
        judul: "Data Baru",
        personData: [],
        dataTemplate: [],
        hasChange: false,
    };
};
