export const initialState = () => {
  return {
    loading: false,
    body: {
      assetfistype: 0,
      deprmethod: 0,
      fafisdesc: "",
      fisestlife: 0,
      fisrate: 0,
    },
    insert: true,
    judul: "Data Baru",
    hasChange: false,
  };
};
