import React, { Component } from "react";
import { styles } from "../../assets/styles/jss/components/preferensi-database";
import withStyles from "react-jss";
import { Checkbox } from "antd";
import { withNamespaces } from "react-i18next";

class Pembelian extends Component {
  render() {
    const { t, parentState, handle } = this.props;
    return (
      <div>
        <header>
          <desc className="welcome_desc">
            {t(
              "Centang pernyataan berikut agar Easy Cloud menyesuaikan kebutuhan perusahaan Anda."
            )}
          </desc>
        </header>
        <div className="content-preference">
          <form className="form__fitur-dasar">
            <div>
              <div>
                <Checkbox
                  className="field"
                  checked={
                    parentState.pcompany.features.pembelian.useminmaxpurcprice
                  }
                  name="useminmaxpurcprice"
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                          type: "features",
                          modul: "pembelian",
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t(
                    "Perusahaan anda menggunakan minimun dan maksimum harga Pembelian"
                  )}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  className="field"
                  name="showdm"
                  checked={parentState.pcompany.showdm === 1}
                  onChange={(e) =>
                    handle.onChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked === true ? 1 : null,
                        },
                      },
                      "pcompany"
                    )
                  }
                >
                  {t(
                    "Perusahaan anda meretur berdasarkan Faktur Pembelian yang masih beredar saja"
                  )}
                </Checkbox>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withNamespaces("translations")(withStyles(styles)(Pembelian));
