export const initialState = (formatDate, moment) => {
  return {
    modalExchangerate: false,
    modalSimpanTransaksi: false,
    modalSalinTransaksi: false,
    errorPage: null,
    personData: [],
    insert: true,
    judul: "Data Baru",
    validation: [],
    loading: {
      saveExit: false,
      previous: false,
      next: false,
      jurnal: false,
      pratinjau: false,
      email: false,
    },
    total: {
      subtotal: 0,
      diskon: 0,
      ppn: 0.1,
      pajak2: 0,
      freight: 0,
      jumlah: 0,
    },

    body: {
      sndata: [],
      details: [
        {
          apreturnid: 0,
          brutounitprice: 0,
          deptid: "",
          invoiceseq: "",
          invri: null,
          itemdescription: "",
          itemhistid: "",
          groupno: "",
          groupseq: 0,
          itemno: "",
          itemreserved1: "",
          itemreserved10: "",
          itemreserved11: "",
          itemreserved12: "",
          itemreserved13: "",
          itemreserved14: "",
          itemreserved15: "",
          itemreserved16: "",
          itemreserved17: "",
          itemreserved18: "",
          itemreserved19: "",
          itemreserved2: "",
          itemreserved20: "",
          itemreserved3: "",
          itemreserved4: "",
          itemreserved5: "",
          itemreserved6: "",
          itemreserved7: "",
          itemreserved8: "",
          itemreserved9: "",
          itemunit: "",
          poid: "",
          poseq: "",
          projectid: "",
          qtyold: "",
          quantity: "",
          qtyacuan: "",
          seq: 1,
          snok: false,
          taxableamount1: "",
          taxableamount2: "",
          taxcodes: "",
          unitprice: 0,
          unitratio: 1,
          userid: "",
          warehouseid: 0,
        },
      ],
      master: {
        overbudget: true,
        personname: "",
        personno: "",
        apinvoiceid: null,
        approved: false,
        approvedesc: null,
        apreturnid: 0,
        checknote: true,
        currencyid: "",
        description: "",
        fiscalrate: 1,
        follow: "",
        followup: false,
        urgent: false,
        freight: 0,
        glhistid: 0,
        glperiod: 0,
        glyear: 0,
        idnext: 0,
        idprev: 0,
        inclusivetax: false,
        invoiceamount: 0,
        invoicedate: moment().format(formatDate),
        invoiceno: "",
        isnihil: false,
        istaxable: false,
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
        locked_by: "",
        locked_time: "",
        noteid: 0,
        printed: true,
        rate: 1,
        reportedtax1: true,
        reportedtax2: true,
        roundedtax1amount: 0,
        roundedtax2amount: 0,
        sspdate: "",
        status: "",
        subtotal: 0,
        tax1amount: 0,
        tax1code: "",
        tax1id: 0,
        tax1rate: 0,
        tax2amount: 0,
        tax2code: "",
        tax2id: 0,
        tax2rate: 0,
        taxableamount1: 0,
        taxableamount2: 0,
        taxableamount3: 0,
        taxableamtcode1: "",
        taxableamtcode2: "",
        taxableamtcode3: "",
        taxdate: "",
        templateid: 19,
        userid: 0,
        usesn: true,
        vendorid: null,
        warehouseid: 0,
        withoutpi: true,
        lastid: 0,
        currencyname: "",
        posted: true,
      },
      templatedetails: [],
      attachment: [],
    },
    daftar: {
      pemasok: [],
      invoice: [],
    },
    dataTemplate: [],
    hasChange: false,
  };
};
