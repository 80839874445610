export const initialState = (formatDate, moment) => {
  return {
    insert: true,
    validation: [],
    contexMenuId: null,
    daftar: {
      bank: [],
    },
    body: {
      master: {
        glacc: "",
        balance: 0,
        balancesesuai: 0,
        balancekalkulasi: 0,
        lastposteddate: moment().format(formatDate),
        lastbankbalance: 0,
        bankbookbalance: 0,
        lastupdate: null,
        selisihsaldo: 0,
      },
      details: [],
      included: {},
    },
  };
};
