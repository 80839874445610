import * as types from "../../constants";

//load tenant
export function addTenantAction(bodyTenant) {
  return {
    type: types.ADD_TENANT,
    payload: bodyTenant,
  };
}

export function deleteTenantAction(id) {
  return {
    type: types.DELETE_TENANT,
    id,
  };
}

export function openTenantActionn(body) {
  const { id, isOpened } = body;
  return {
    type: types.OPEN_TENANT,
    id,
    isOpened,
    payload: body,
  };
}
export function closeTenant() {
  return {
    type: types.CLOSE_TENANT,
  };
}
export function archiveTenant(body) {
  return {
    type: types.ARCHIVE_TENANT,
    payload: body,
  };
}
export function Preferensi(body) {
  return {
    type: types.PREFERENSI,
    payload: body,
  };
}
