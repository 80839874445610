import { BASE_CDN } from "constants/index";

export { default as pembelian } from "../assets/img/dashboard-mobile/pembelian.svg";
export { default as penjualan } from "../assets/img/dashboard-mobile/penjualan.svg";
export { default as persediaan } from "../assets/img/dashboard-mobile/persediaan.svg";
export { default as manufaktur } from "../assets/img/dashboard-mobile/manufaktur.svg";
export { default as aktiva } from "../assets/img/dashboard-mobile/aktiva-tetap.svg";

/**
 * @module PENJUALAN
 * @author @pradiktabagus
 */
export const faktur_penjualan = `${BASE_CDN}/w_100,h_100/v1648793804/Penjualan/1faktur-penjualan_zcdlrc.svg`;
export const faktur_sementara = `${BASE_CDN}/w_100,h_100/v1648809533/button/faktur-sementara_w80jta.png`;
export const penawaran_penjualan = `${BASE_CDN}/w_100,h_100/v1648793804/Penjualan/1penawaran-penjualan_pmiseb.svg`;
export const penerimaan_penjualan = `${BASE_CDN}/w_100,h_100/v1648793804/Penjualan/1penerimaan-penjualan_bz8wvc.svg`;
export const pengiriman_barang = `${BASE_CDN}/w_100,h_100/v1648793802/Penjualan/pengiriman-barang_oorfvc.svg`;
export const pesanan_penjualan = `${BASE_CDN}/w_100,h_100/v1648793800/Penjualan/1pesanan-penjualan_b97kx7.svg`;
export const retur_penjualan = `${BASE_CDN}/w_100,h_100/v1648793802/Penjualan/retur-penjualan_q41etq.svg`;
export const faktur_penagihan = `${BASE_CDN}/w_100,h_100/v1648793802/Penjualan/faktur_penagihan_kdne7n.svg`;

/**
 * @module PEMBELIAN
 * @author @pradiktabagus
 */
export const pembayaran_pembelian = `${BASE_CDN}/w_100,h_100/v1648793667/Pembelian/1pembayaran-pembelian_gew1si.svg`;
export const faktur_pembelian = `${BASE_CDN}/w_100,h_100/v1648793667/Pembelian/1faktur-pembelian_dtzkeh.svg`;
export const penerimaan_barang = `${BASE_CDN}/w_100,h_100/v1648793668/Pembelian/1penerimaan-barang_s3fd96.svg`;
export const permintaan_pembeliaan = `${BASE_CDN}/w_100,h_100/v1648793669/Pembelian/1permintaan-pembelian_qizl7s.svg`;
export const pesanan_pembeliaan = `${BASE_CDN}/w_100,h_100/v1648793665/Pembelian/1pesanan-pembelian_hbdi5b.svg`;
export const retur_pembelian = `${BASE_CDN}/w_100,h_100/v1648793665/Pembelian/1retur-pembelian_hbvw0z.svg`;

/**
 * @module BUKU_BESAR
 * @author @pradiktabagus
 */
export const jurnal_umum = `${BASE_CDN}/w_100,h_100/v1648793456/Buku%20besar/1jurnal-umum_xc39bk.svg`;
export const rekonsiliasi_bank = `${BASE_CDN}/w_100,h_100/v1648793456/Buku%20besar/1rekonsiliasi-bank_tp3fbk.svg`;
export const pembayaran_lainnya = `${BASE_CDN}/w_100,h_100/v1648793458/Buku%20besar/1pembayaran-lainnya_b6qd7p.svg`;
export const penerimaan_lainnya = `${BASE_CDN}/w_100,h_100/v1648793458/Buku%20besar/1penerimaan-lainnya_uda7wt.svg`;
export { default as buku_besar } from "../assets/img/dashboard-mobile/buku-besar.svg";

/**
 * @module PERSEDIAAN
 * @author @pradiktabagus
 */
export const penyesuaian_barang = `${BASE_CDN}/w_100,h_100/v1648793952/Persediaan/1penyesuaian-barang_survvu.svg`;
export const pembiayaan_pesanan = `${BASE_CDN}/w_100,h_100/v1648793951/Persediaan/1pembiayaan-pesanan_a7v2eu.svg`;
export const pindah_barang = `${BASE_CDN}/w_100,h_100/v1648793952/Persediaan/1pindah-barang_yvfvy9.svg`;
export const pengelompokan_barang = `${BASE_CDN}/w_100,h_100/v1648793950/Persediaan/1pengelompokan-barang_i9lftg.svg`;
export const barang = `${BASE_CDN}/w_100,h_100/v1648793952/Persediaan/barang_zudp07.svg`;

/**
 * @module AKTIVA
 * @author @pradiktabagus
 */
export const aktiva_tetap = `${BASE_CDN}/w_100,h_100/v1648793382/aktifa/1aktiva_tetap_gsjw5i.svg`;
export const tipe_aktiva_tetap_pajak = `${BASE_CDN}/w_100,h_100/v1648793382/aktifa/1tipe-aktiva-tetap-pajak_sqhy3v.svg`;
export const tipe_aktiva_tetap = `${BASE_CDN}/w_100,h_100/v1648793382/aktifa/1tipe-aktiva-tetap_dbydrl.svg`;

/**
 * @module MANUFAKTUR
 * @author @pradiktabagus
 */
export const formula_produk = `${BASE_CDN}/w_100,h_100/v1648793541/Manufaktur/1formula-produk_mz7iv2.svg`;
export const perintah_kerja = `${BASE_CDN}/w_100,h_100/v1648793543/Manufaktur/1perintah-kerja_ckthuw.svg`;
export const pengeluaran_bahan_baku = `${BASE_CDN}/w_100,h_100/v1648793543/Manufaktur/1pengeluaran_bahan_baku_xfbe5l.svg`;
export const hasil_produksi = `${BASE_CDN}/w_100,h_100/v1648793543/Manufaktur/1hasil-produksi_nmu3cx.svg`;
export const akhir_periode_produksi = `${BASE_CDN}/w_100,h_100/v1648793541/Manufaktur/akhir_periode_produksi_hywkag.svg`;

export const dibayar = require("../assets/img/stamp/dibayar.svg");
export const paid = require("../assets/img/stamp/paid.svg");
export const diproses = require("../assets/img/stamp/diproses.svg");
export const processed = require("../assets/img/stamp/processed.svg");
export const diterima = require("../assets/img/stamp/diterima.svg");
export const received = require("../assets/img/stamp/received.svg");
export const ditutup = require("../assets/img/stamp/ditutup.svg");
export const closed = require("../assets/img/stamp/closed.svg");
export const lunas = require("../assets/img/stamp/lunas.svg");
export const fullPayment = require("../assets/img/stamp/fullPayment.svg");
export const mengantri = require("../assets/img/stamp/mengantri.svg");
export const queue = require("../assets/img/stamp/queue.svg");
export const menunggu = require("../assets/img/stamp/menunggu.svg");
export const waiting = require("../assets/img/stamp/waiting.svg");
export const direkonsiliasi = require("../assets/img/stamp/direkonsiliasi.svg");
export const reconciled = require("../assets/img/stamp/reconciled.svg");
export const disetujui = require("../assets/img/stamp/disetujui.svg");
export const approved = require("../assets/img/stamp/approved.svg");

export const backArrow = require("../assets/img/back.svg");
export const logoEasy = `${BASE_CDN}/w_100,h_100/v1648785186/logoEasy_hech9f.png`;
export const logoNew = require("../assets/img/logoNew.svg");
export const logoHome = require("../assets/img/homehexagon.svg");
export const Logo = `${BASE_CDN}/w_237,h_38/v1648785054/logo_qltosq.png`;
export const LogoReport =
	"https://cdn.cloudflare.steamstatic.com/steam/apps/570/capsule_616x353.jpg";
export const Ic_Report = `${BASE_CDN}/w_100,h_100/v1649055489/Laporan/icon-laporan-revisi_1_ci7b1q.svg`;
export const Ic_ReportTable = `${BASE_CDN}/w_100,h_100/v1649057203/Laporan/icon-laporan-pivot_yx3any.svg`;
/**
 * @module rancangan_formulir
 */
export const RF_PermintaanPembelian = `${BASE_CDN}/w_75,h_75/v1649212656/desain%20template/PurchaseRequestion_hlp0vb.svg`;
export const RF_PesananPembelian = `${BASE_CDN}/w_75,h_75/v1649212656/desain%20template/PurchaseOrder_ewzyyy.svg`;
export const RF_PenerimaanBarang = `${BASE_CDN}/w_75,h_75/v1649212656/desain%20template/ReceiveItem_iwuvk4.svg`;
export const RF_FakturPembelian = `${BASE_CDN}/w_75,h_75/v1649212654/desain%20template/PurchaseInvoice_y7jeup.svg`;
export const RF_ReturPembelian = `${BASE_CDN}/w_75,h_75/v1649212656/desain%20template/PurchaseReturn_mswfx4.svg`;
export const RF_PembayaranPembelian = `${BASE_CDN}/w_75,h_75/v1649212658/desain%20template/VendorPayment_slbuap.svg`;
export const RF_PenawaranPenjualan = `${BASE_CDN}/w_75,h_75/v1649212658/desain%20template/SalesQuotation_slpeav.svg`;
export const RF_PesananPenjualan = `${BASE_CDN}/w_75,h_75/v1649212656/desain%20template/SalesOrder_pgnxc2.svg`;
export const RF_PengirimanBarang = `${BASE_CDN}/w_75,h_75/v1649212658/desain%20template/DeliveryOrder_opxhnj.svg`;
export const RF_FakturPenjualan = `${BASE_CDN}/w_75,h_75/v1649212656/desain%20template/SalesInvoice_xildx1.svg`;
export const RF_FakturPenagihan = `${BASE_CDN}/w_75,h_75/v1649212656/desain%20template/faktur_penagihan_kdne7n.svg`;
export const RF_ReturPenjualan = `${BASE_CDN}/w_75,h_75/v1649212658/desain%20template/SalesReturn_phnmwm.svg`;
export const RF_PenerimaanPenjualan = `${BASE_CDN}/w_75,h_75/v1649212658/desain%20template/SalesPayment_ohb5vb.svg`;
export const RF_JurnalUmum = `${BASE_CDN}/w_75,h_75/v1649212654/desain%20template/JournalVoucher_ydp9zc.svg`;
export const RF_StandarisasiBiayaProduksi = `${BASE_CDN}/w_75,h_75/v1649227252/Manufaktur/Standarisasi-biaya-produksi_kqifcq.png`;
export const RF_StandarisasiHargaBarang = `${BASE_CDN}/w_75,h_75/v1649227252/Manufaktur/standarisasi-harga-barang_pjkivb.png`;
export const RF_FormulaProduk = `${BASE_CDN}/w_75,h_75/v1648793541/Manufaktur/formula-produk_ledb2m.svg`;
export const RF_PerintahKerja = `${BASE_CDN}/w_75,h_75/v1648793543/Manufaktur/1perintah-kerja_ckthuw.svg`;
export const RF_HasilProduksi = `${BASE_CDN}/w_75,h_75/v1648793543/Manufaktur/1hasil-produksi_nmu3cx.svg`;
export const RF_PengeluaranBahanBaku = `${BASE_CDN}/w_75,h_75/v1648793543/Manufaktur/1pengeluaran_bahan_baku_xfbe5l.svg`;
export const RF_PembayaranLainnya = `${BASE_CDN}/w_100,h_100/v1649212658/desain%20template/DirectPayment_bglplo.svg`;
export const RF_PenerimaanLainnya = `${BASE_CDN}/w_75,h_75/v1649212654/desain%20template/OtherReceipts_drvqnn.svg`;
export const RF_PindahBarang = `${BASE_CDN}/w_75,h_75/v1649212654/desain%20template/ItemTransfer_ju3hug.svg`;
export const RF_PenyesuaianBarang = `${BASE_CDN}/w_75,h_75/v1649212654/desain%20template/InventoryAdjustment_k0um3y.svg`;
export const RF_PembiayaanPesanan = `${BASE_CDN}/w_75,h_75/v1649212654/desain%20template/JobCosting_tk3z0o.svg`;
export const RF_AktivaTetap = `${BASE_CDN}/w_75,h_75/v1649212659/desain%20template/FixedAssets_jjomxw.svg`;

/**
 * @description icon database
 */
export { default as LOGO_DB } from "../assets/img/database/ic_logo_db.png";
export { default as USER } from "../assets/img/database/ic_user.png";
export { default as PENAGIHAN } from "../assets/img/database/ic_penagian.png";
export { default as PENAWARAN } from "../assets/img/database/ic_penawaran.png";
export { default as KEY } from "../assets/img/database/ic_key.png";
export { default as ARSIP } from "../assets/img/database/ic_arsip_db.png";
export { default as DATABASE } from "../assets/img/database/ic_database.png";

/**
 * @description toolbar
 */

// export const Menu = `${BASE_CDN}/w_75,h_75/v1632813005/img/toolbar/menu_vdxtyi.png`;
// export const MasterData = `${BASE_CDN}/w_75,h_75/v1632813005/img/toolbar/master_data_yxdtgz.png`;
// export const Import = `${BASE_CDN}/w_75,h_75/v1648791767/tool%20bar/import_wrd0sh.svg`;
// export const Laporan = `${BASE_CDN}/w_75,h_75/v1632813005/img/toolbar/laporan_yxuuoy.png`;
// export const ModulUtama = `${BASE_CDN}/w_75,h_75/v1632813005/img/toolbar/modul_utama_pkktaz.png`;
// export const Pengaturan = `${BASE_CDN}/w_75,h_75/v1632813005/img/toolbar/pengaturan_fiukmk.png`;
export const Menu = `${BASE_CDN}/v1661333886/tool%20bar/menu_ra5kb8.png`;
export const MasterData = `${BASE_CDN}/v1661333886/tool%20bar/master_data_lbs0rx.png`;
export const Import = `${BASE_CDN}/w_75,h_75/v1648791767/tool%20bar/import_wrd0sh.svg`;
export const Laporan = `${BASE_CDN}/v1661333886/tool%20bar/laporan_zz7iqi.png`;
export const ModulUtama = `${BASE_CDN}/v1661333886/tool%20bar/modul_utama_fjdy0a.png`;
export const Pengaturan = `${BASE_CDN}/v1661333886/tool%20bar/pengaturan_m4yirx.png`;

/**
 * @description
 */
export const RoboExit = `${BASE_CDN}/w_150,h_150/v1648801580/Easy%20Robo/exit_ozau7y.png`;
export const RoboDelete = `${BASE_CDN}/w_150,h_150/v1648801578/Easy%20Robo/delete_hnyvb7.png`;
export const RoboNotFound = `${BASE_CDN}/w_150,h_150/v1648801580/Easy%20Robo/notfound_gsxds4.png`;
export const RoboEmptyItem = `${BASE_CDN}/w_150,h_150/v1648801584/Easy%20Robo/itemnotfound_zqzg7d.png`;
export const RoboWarning = `${BASE_CDN}/w_150,h_150/v1648801577/Easy%20Robo/warning_ggjlgu.png`;

/**
 * @description preferensi
 */
export const PrefPerusahaan = `${BASE_CDN}/w_100,h_100/v1648793080/Preferensi/perusahaan_p58dd6.png`;
export const PrefAkunStandart = `${BASE_CDN}/w_100,h_100/v1648793080/Preferensi/akun_standart_v1nrvm.png`;
export const PrefFitur = `${BASE_CDN}/w_100,h_100/v1648793080/Preferensi/fitur_pta5ia.png`;
export const PrefPembelianPenualan = `${BASE_CDN}/w_100,h_100/v1648793080/Preferensi/pembelian_penjualan_f4bhth.png`;
export const PrefPengingat = `${BASE_CDN}/w_100,h_100/v1648793080/Preferensi/pengingat_oz50nt.png`;
export const PrefPengaturanLain = `${BASE_CDN}/w_100,h_100/v1648793080/Preferensi/pengaturanlain_suyl8e.png`;
export const PrefPlugin = `${BASE_CDN}/w_100,h_100/v1648793080/Preferensi/plugin_dmukh0.png`;
export const PrefUpload = `${BASE_CDN}/w_100,h_100/v1648810834/button/upload-file-icon_vt0kbc.svg`;

/**
 * @module laporan
 * @author @pradiktabagus
 */
export const LapKeuangan = `${BASE_CDN}/w_100,h_100/v1648792629/icon%20menu%20utama/Laporan_Keunagan_ozwdok.png`;
export const LapBukuBesar = `${BASE_CDN}/w_100,h_100/v1648792627/icon%20menu%20utama/buku_besar_vtgktc.png`;
export const LapPenjualan = `${BASE_CDN}/w_100,h_100/v1648792628/icon%20menu%20utama/laporan_penjualan_ok5uh2.png`;
export const LapBarang = `${BASE_CDN}/w_100,h_100/v1648792626/icon%20menu%20utama/barang_reqrwl.png`;
export const LapAkunHutangPemasok = `${BASE_CDN}/w_100,h_100/v1648792628/icon%20menu%20utama/laporan_hutang_srgjai.png`;
export const LapAkunPiutanPelanggan = `${BASE_CDN}/w_100,h_100/v1648792630/icon%20menu%20utama/laporan_piutang_qc0l43.png`;
export const LapPembelian = `${BASE_CDN}/w_100,h_100/v1648792628/icon%20menu%20utama/laporan_pembelian_lljm5p.png`;
export const LapPembiayaanPesanan = `${BASE_CDN}/w_100,h_100/v1648792627/icon%20menu%20utama/job_coasting_hgvipt.png`;
export const LapLainnya = `${BASE_CDN}/w_100,h_100/v1648792628/icon%20menu%20utama/laporan_lainya_srjvtl.png`;
export const LapAudit = `${BASE_CDN}/w_100,h_100/v1648792629/icon%20menu%20utama/laporan_audit_r1fdkt.png`;
export const LapAktivaTetap = `${BASE_CDN}/w_100,h_100/v1648792627/icon%20menu%20utama/aktiva_tetap_lfzz5w.png`;
export const LapPajak = `${BASE_CDN}/w_100,h_100/v1648792630/icon%20menu%20utama/tax_report_vvoa4k.png`;
export const LapDepartment = `${BASE_CDN}/w_100,h_100/v1648792627/icon%20menu%20utama/departement_report_f6eb4l.png`;
export const LapProject = `${BASE_CDN}/w_100,h_100/v1648792629/icon%20menu%20utama/laporan_project_itqqii.png`;
export const LapTersimpan = `${BASE_CDN}/w_100,h_100/v1648792629/icon%20menu%20utama/laporan_tersimpan_edtj6m.png`;
export const LaptExport = `${BASE_CDN}/w_100,h_100/v1648792627/icon%20menu%20utama/ekport_report_fiubvq.png`;
export const LapManufaktur = `${BASE_CDN}/w_100,h_100/v1648792630/icon%20menu%20utama/report_manufacturing_ekwdy4.png`;
export const LapCustomReport = `${BASE_CDN}/w_100,h_100/v1648792628/icon%20menu%20utama/custom_report_swuqfh.png`;
export const LapFormatImport = `${BASE_CDN}/w_100,h_100/v1648792629/icon%20menu%20utama/laporan-format-impor_ydzw4l.png`;

/**
 * @module persiapanawal
 */
export const PerAkun = `${BASE_CDN}/w_100,h_100/v1648798945/Persiapan%20Awal/daftar_akun_yfbgbu.png`;
export const PerPemasok = `${BASE_CDN}/w_100,h_100/v1648798945/Persiapan%20Awal/daftar_pemasok_iuunar.png`;
export const PerPelanggan = `${BASE_CDN}/w_100,h_100/v1648798945/Persiapan%20Awal/daftar_pelanggan_zhoe5x.png`;
export const PerBarangJasa = `${BASE_CDN}/w_100,h_100/v1648798945/Persiapan%20Awal/daftar_barang_jasa_cshvlp.png`;

export { default as Ic_Profile } from "../assets/img/ic_photoprofile.png";
export { default as Ic_Password_Pengguna } from "../assets/img/ic_password_pengguna.svg";
export { default as Ic_Profil_Pengguna } from "../assets/img/ic_profil_pengguna.svg";

/**
 * @description widget
 */
export { default as Ic_Person } from "../assets/img/ic_person.png";

export { default as Ic_Facebook } from "../assets/img/ic_facebook.svg";
export { default as Ic_Google } from "../assets/img/ic_google.svg";
export const Ic_LogoEasy = `${BASE_CDN}/w_100,h_100/v1648785186/logoEasy_hech9f.png`;

export { default as Bg_login } from "../assets/img/ic_background_login.svg";
/**
 * @description notification
 */
export { default as Ic_Notification } from "../assets/img/ic_notification.png";

export const Ic_CNT_Diproses = `${BASE_CDN}/w_100,h_100/v1648806389/button/1_Penawaran_Penjualan-02_tkrilv.png`;
export const Ic_CNT_Menunggu = `${BASE_CDN}/w_100,h_100/v1648806389/button/1_Penawaran_Penjualan-01_w64axw.png`;
export const Ic_CNT_Ditolak = `${BASE_CDN}/w_100,h_100/v1648806390/button/1_Penawaran_Penjualan-03_tcv2lf.png`;
