import { request } from "./api";
import { API_BASE_URL } from "../../constants";

export function getAllTenant() {
  return request({
    url: `${API_BASE_URL}/tenant`,
    method: "GET",
  });
}

export function getTenantByUserId() {
  return request({
    url: `${API_BASE_URL}/tenant-user-by-userid`,
    method: "GET",
  });
}

export function getArchiveTenantByUserId() {
  return request({
    url: `${API_BASE_URL}/tenant-user-by-userid-archives`,
    method: "GET",
  });
}

export function saveTenant(createDatabase) {
  return request({
    url: `${API_BASE_URL}/tenant`,
    method: "POST",
    body: JSON.stringify(createDatabase),
  });
}

export function checkTenantAvailability(tenant) {
  return request({
    url:
      `${API_BASE_URL}/tenant-name-availability?data-source-schema-name=` +
      tenant,
    method: "GET",
  });
}

export function updateStatus(tenantKey) {
  return request({
    url: `${API_BASE_URL}/stat-tenant`,
    method: "PUT",
    body: JSON.stringify(tenantKey),
  });
}

export function updateNameTenant(tenant) {
  return request({
    url: `${API_BASE_URL}/tenant`,
    method: "PUT",
    body: JSON.stringify(tenant),
  });
}

export function tambahPengguna(tenant) {
  return request({
    url: `${API_BASE_URL}/tenant`,
    method: "POST",
    body: JSON.stringify(tenant),
  });
}

export function addPengguna(body) {
  return request({
    url: `${API_BASE_URL}/tenant-user`,
    method: "POST",
    body: JSON.stringify(body),
  });
}

export function getPenggunaTenant(id) {
  return request({
    url: `${API_BASE_URL}/tenant-user-by-id/${id}`,
    method: "GET",
  });
}

export function getTenantUser(id, include) {
  let url = include
    ? `${API_BASE_URL}/tenant-user-by-tenantid/${id}/1`
    : `${API_BASE_URL}/tenant-user-by-tenantid/${id}`;
  return request({
    url,
    method: "GET",
  });
}

export function getAllUser(id) {
  let url = API_BASE_URL + `/all-user/${id}`;
  return request({
    url: url,
    method: "GET",
  });
}

export function deleteTenantUser(id, dataSourceKey) {
  return request({
    url: `${API_BASE_URL}/tenant-user?id=${id}&datasourcekey=${dataSourceKey}`,
    method: "DELETE",
  });
}

export function updateRoleTenantUser(body) {
  return request({
    url: `${API_BASE_URL}/role-tenant-user`,
    method: "PUT",
    body: JSON.stringify(body),
  });
}
