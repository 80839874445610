export const initialState = () => {
  return {
    modal_levelHarga: false,
    modal_glaccnt: false,
    modal_cariBarang: false,
    contexMenuId: null,
    itemNoDetail: "",
    loading: {
      simpan: false,
      pajak: false,
      akun: false,
    },
    daftar: {
      akun: [],
      pajak: [],
    },
    recordNo: "",
    insert: true,
    body: {
      details: [
        {
          itemno: "",
          itemid: 0,
          itemunit: "",
          quantity: 0,
          itemdescription: "",
        },
      ],
      master: {
        itemdescription: "",
        itemid: 0,
        itemno: "",
        minimumqty: 0,
        salesdiscount: null,
        salesglaccnt: null,
        salesretglaccnt: null,
        suspended: false,
        taxcodes: "",
        unit1: "",
        unitprice: 0,
        unitprice2: 0,
        unitprice3: 0,
        unitprice4: 0,
        unitprice5: 0,
        idnext: 0,
        idprev: 0,
        lastid: 0,
      },
    },
    hasChange: false,
    satuan: 1,
  };
};
