export const initialState = (formatDate, moment) => {
  return {
    NotifAlert: {
      visible: false,
      title: null,
      description: null,
      cancelText: null,
      okText: null,
      onSubmit: null,
    },
    errorPage: null,
    visible: false,
    modalSimpanTransaksi: false,
    modalSalinTransaksi: false,
    loadingTable_salinTransaksi: false,
    loading: {
      previous: false,
      saveExit: false,
      next: false,
      pratinjau: false,
    },
    tabPane: "1",
    validation: [],
    body: {
      master: {
        approvedesc: null,
        urgent: false,
        approved: false,
        checknote: false,
        description: "",
        follow: "",
        followup: false,
        getfromjc: false,
        getfromsi: false,
        isclosed: false,
        noteid: 0,
        proceed: false,
        received: false,
        reqdate: moment().format(formatDate),
        reqid: 0,
        reqno: "",
        templateid: 1,
        userid: 0,
        status: "",
        idnext: 0,
        idprev: 0,
        lastid: 0,
      },
      details: [
        {
          arinvoiceid: 0,
          arinvoiceseq: 0,
          closed: false,
          deptid: "",
          itemid: "",
          itemno: "",
          groupno: "",
          groupseq: 0,
          itemovdesc: "",
          itemreserved1: "",
          itemreserved10: "",
          itemreserved11: "",
          itemreserved12: "",
          itemreserved13: "",
          itemreserved14: "",
          itemreserved15: "",
          itemreserved16: "",
          itemreserved17: "",
          itemreserved18: "",
          itemreserved19: "",
          itemreserved2: "",
          itemreserved20: "",
          itemreserved3: "",
          itemreserved4: "",
          itemreserved5: "",
          itemreserved6: "",
          itemreserved7: "",
          itemreserved8: "",
          itemreserved9: "",
          itemunit: "",
          notes: "",
          projectid: "",
          qtyordered: 0,
          qtyreceived: 0,
          quantity: "",
          reqdate: "",
          reqid: 0,
          seq: 1,
          unitratio: 1,
        },
      ],
      templatedetails: [],
      attachment: [],
    },
    insert: true,
    judul: "Data Baru",
    dataTemplate: [],
    formatDate: formatDate,
    hasChange: false,
  };
};
