import * as types from "../../constants";

export default function RemovePanes(state = [], action) {
  switch (action.type) {
    case types.REMOVE_TAB:
      return [{ ...action.body }];
    case types.CLEAR_REMOVE_TAB:
    case types.RESET:
      return [];
    default:
      return state;
  }
}
