import * as types from "../../constants";

const initialState = {
  Tenants: [],
  id: "",
  isOpened: "",
  ArchiveTenants: [],
  Preferensi: null,
  isFillPreparation: true,
};

export default function Tenants(state = initialState, action) {
  switch (action.type) {
    case types.ADD_TENANT:
      // return action.Tenants
      return {
        ...state,
        Tenants: action.payload,
      };
    case types.DELETE_TENANT:
      return state.filter((tenant) => tenant.id !== action.id);
    case types.OPEN_TENANT:
      return {
        ...state,
        id: action.id,
        isOpened: action.isOpened,
        ...action.payload,
      };
    case types.CLOSE_TENANT:
      return {
        ...state,
        id: "",
        isOpened: "",
      };
    case types.ARCHIVE_TENANT:
      return {
        ...state,
        ArchiveTenants: action.payload,
      };
    case types.PREFERENSI:
      return {
        ...state,
        Preferensi: action.payload,
      };
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}
