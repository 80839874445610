import * as types from "../../constants";

export default function Global(state = [], action) {
	switch (action.type) {
		case types.DAFTAR_PENGGUNA:
			return {
				...state,
				daftarPenggunaTenant: action.payload,
			};
		case types.PROFILE_COMPANY:
			return {
				...state,
				profileCompany: action.payload,
			};
		case types.FORMAT_DATE_COMPANY:
			return {
				...state,
				formatDate: action.payload,
			};
		case types.TRANSCODE:
			return {
				...state,
				transcode: action.payload,
			};
		case types.HAK_AKSES:
			return {
				...state,
				hakAkses: action.payload,
			};
		case types.BILL_UNPAYED:
			return {
				...state,
				countBill_unpayed: action.payload,
			};
		case types.FETCH_TENANT:
			return {
				...state,
				fethTenant: action.payload,
			};
		case types.HPP:
			return {
				...state,
				hpp: action.payload,
			};
		case types.ALL_CHART:
			return {
				...state,
				chart: action.payload,
			};
		case types.RESET:
			return [];
		default:
			return state;
	}
}
