import stomp from "stompjs";
import sockJs from "sockjs-client";
import { BASE_SOCKET } from "../../constants";
import React from "react";

let sock = new sockJs(`${BASE_SOCKET}/chat-example`);
export const socket = stomp.over(sock);
socket.debug = null;
socket.connect(
  {},
  function () {
    console.log("connect");
  },
  function () {
    console.log("disconect");
  }
);
export const SocketContext = React.createContext();
