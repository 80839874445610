import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./app/App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import i18n from "./constants/i18n";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "react-jss";
import { themes } from "./containers/page/theme/themePalette";
import { socket, SocketContext } from "./utility/websocket/index";
const xxx = store.getState();

// eslint-disable-next-line no-extend-native
Array.prototype.inArray = function (comparer) {
  for (let i = 0; i < this.length; i++) {
    if (comparer(this[i])) return true;
  }
  return false;
};

// eslint-disable-next-line no-extend-native
Array.prototype.pushIfNotExist = function (element, comparer) {
  if (!this.inArray(comparer)) {
    this.push(element);
  }
};

ReactDOM.render(
  <Provider store={store}>
    <SocketContext.Provider value={socket}>
      {/* <ThemeProvider theme={themes[xxx.Theme.color]}> */}
      <ThemeProvider
        theme={
          themes[
            xxx.Auths.currentUser
              ? xxx.Auths.currentUser.colormode
              : "themePurple"
          ]
        }
      >
        <Router>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </Router>
      </ThemeProvider>
    </SocketContext.Provider>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.register();
