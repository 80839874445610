export const themes = {
  themeGreen: {
    colorPrimary: "#007ac1",
    fontSize: 14,
    labelColor: "#00AB14",
    borderColor: "#08E020",
    hoverColor: "#227d2d",
    columnColor: "#4e4e4e",
    colorBase2: "#86d990",
    headerColor: "linear-gradient(to right, #00AB14 , #00AB14)",
  },
  themeRed: {
    colorPrimary: "#007ac1",
    fontSize: 14,
    labelColor: "#EE2737",
    borderColor: "#FD6D79 ",
    hoverColor: "#ba1a27",
    columnColor: "#4e4e4e",
    colorBase2: "#fa737e",
    headerColor: "linear-gradient(to right, #EE2737 , #EE2737)",
  },
  themeBlue: {
    colorPrimary: "#007ac1",
    fontSize: 14,
    labelColor: "#00AED6",
    borderColor: "#6ACFE7 ",
    hoverColor: "#4facbd",
    columnColor: "#4e4e4e",
    colorBase2: "#52c8e3",
    headerColor: "linear-gradient(to right, #00AED6 , #00AED6)",
  },
  themeOrange: {
    colorPrimary: "#007ac1",
    fontSize: 14,
    labelColor: "#F06400",
    borderColor: "#FFA86A ",
    hoverColor: "#d15802",
    columnColor: "#4e4e4e",
    colorBase2: "#fc9a53",
    headerColor: "linear-gradient(to right, #F06400  , #F06400)",
  },
  themePink: {
    colorPrimary: "#007ac1",
    fontSize: 14,
    labelColor: "#DF1995",
    borderColor: "#FB81CD ",
    hoverColor: "#bf0f7d",
    columnColor: "#4e4e4e",
    colorBase2: "#ed6bbc",
    headerColor: "linear-gradient(to right, #DF1995  , #DF1995)",
  },
  themePurple: {
    colorPrimary: "#007ac1",
    fontSize: 14,
    labelColor: "#93328E",
    borderColor: "#FD4CFD",
    hoverColor: "#70256c",
    columnColor: "#4e4e4e",
    colorBase2: "#f060e8",
    headerColor: "linear-gradient(to right, #93328E  , #93328E)",
  },
  themeGray: {
    colorPrimary: "#007ac1",
    fontSize: 14,
    labelColor: "#859fac",
    borderColor: "##7b9eb0",
    hoverColor: "#708691",
    columnColor: "#4e4e4e",
    colorBase2: "#a6cbde",
    headerColor: "linear-gradient(to right, #859fac  , #859fac)",
  },
};
