import * as types from "../../constants";

export function changeAuthAction(bodyAuth) {
  const { isvalidlogin, token } = bodyAuth;
  return {
    type: types.CHANGE_AUTH,
    isvalidlogin,
    token,
  };
}

export function addCurrentUserAction(bodyAuth) {
  const { currentUser, isAuthenticated } = bodyAuth;
  return {
    type: types.ADD_CURRENT_AUTH,
    currentUser,
    isAuthenticated,
  };
}

export function redirect(link) {
  return {
    type: types.REDIRECT,
    link,
  };
}
export function sessionEnded(isSession) {
  return {
    type: types.SESSION_ENDED,
    isSession,
  };
}

export function reset() {
  return {
    type: types.RESET,
  };
}

export function resetTab() {
  return {
    type: types.RESET_TAB,
  };
}
