import * as types from "../../constants";
const initialState = {
  discAccount: [],
};
export default function transaction(state = initialState, action) {
  switch (action.type) {
    case types.DISC_ACCOUNT:
      let noFaktur = [];
			let oldData = [];
			let data = [];
			if (state.discAccount[action.key] !== undefined) {
				// noFaktur = action.payload.length > 0 ? action.payload[0][action.invName] : 0;
				noFaktur =
					action.payload.length > 0
						? action.payload.map((el) => el[action.invName])
						: [];

				oldData = state.discAccount[action.key].filter(
					(o) => o[action.invName] === noFaktur
				);

				data = state.discAccount[action.key].filter(
					(o) => !noFaktur.includes(o[action.invName])
				);
			}
      return {
        ...state,
        discAccount: {
          ...state.discAccount,
          [action.key]: [...data, ...action.payload],
        },
      };
    case types.UPDATE_DISC_ACCOUNT:
      return {
        ...state,
      };
    case types.DELET_DISC_ACCOUNT_BYINV:
      let arr = state.discAccount[action.idkey].filter((o) => o[action.invName] !== action.invoice);
      return {
        ...state,
        discAccount: {
          ...state.discAccount,
          [action.idkey]: [...arr],
        },
      };
    case types.DELETE_DISC_ACCOUNT_BYQUE:
      delete state.discAccount[action.idKey];
      return {
        ...state,
        discAccount: state.discAccount,
      };
    case types.RESET_DISC_ACCOUNT:
      return {
        ...state,
        discAccount: [],
      };
    default:
      return state;
  }
}
