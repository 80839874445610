export const initialState = (formatDate, moment, props) => {
  let transtype = props.parentAsal ? props.parentAsal.additional.transtype : "";
  let parentAsal = props.parentAsal ? props.parentAsal : {};
  let personid = props.parentAsal ? props.parentAsal.additional.personid : "";
  let person = props.parentAsal ? props.parentAsal.additional.person : "";
  let noForm = props.parentAsal ? props.parentAsal.additional.noForm : "";

  return {
    setInitialState: false,
    visible: false,
    tabPane: "1",
    errorPage: null,
    validation: [],
    prevClosed: false,
    body: {
      details: [
        {
          estdescription: "",
          estimatedamount: 0,
          estimateddate: null,
          estinvoiceno: "",
          id: 0,
          invoiceid: 0,
          recurringid: 0,
          seq: 0,
          status: 0,
        },
      ],
      master: {
        description: "",
        glaccount: null,
        id: 0,
        isassigninvno: false,
        perioda: "Bulanan",
        personid: personid,
        recurringdata: JSON.stringify(parentAsal),
        recurringdate: moment().format(formatDate),
        recurringno: "",
        recurringtotal: 2,
        startinvno: noForm,
        status: 0,
        transtype: transtype,
        userid: 0,
        person: person,
      },
      parentAsal: parentAsal,
      templatedetails: [],
      attachment: [],
    },
    insert: true,
    judul: "Data Baru",
    dataTemplate: [],
    formatDate: formatDate,
    hasChange: false,
    loaddata: false,
  };
};
