export const initialState = (formatDate, moment) => {
  return {
    editable: true,
    insert: true,
    loadingTable_salinTransaksi: false,
    visible_ITEM: false,
    itemNoDetail: "",
    recordNo: "",
    daftar: {
      akun: [],
      template: [],
      departement: [],
      project: [],
      warehouse: [],
    },
    body: {
      sndata: [],
      details: [
        {
          allocated: 0,
          currentqty: "",
          currentvalue: 0,
          itemdescription: "",
          deptid: "",
          itemadjid: 0,
          itemhistid: 0,
          itemno: "",
          itemid: 0,
          itemunit: "",
          newqty: "",
          newvalue: 0,
          projectid: "",
          qtydifference: 0,
          seq: 0,
          valuedifference: 0,
          warehouseid: "",
        },
      ],
      master: {
        followup: false,
        follow: "",
        urgent: false,
        approved: false,
        overbudget: true,
        adjaccount: null,
        adjcheck: false,
        adjdate: moment().format(formatDate),
        adjno: "",
        adjtype: 1,
        description: "",
        glhistid: 0,
        glperiod: 0,
        glyear: 0,
        idnext: 0,
        idprev: 0,
        imported: 0,
        itemadjid: 0,
        locked_by: "",
        locked_time: "",
        production: false,
        templateid: 17,
        totalvalue: 0,
        userid: 0,
        warehouseid: 1,
      },
      templatedetails: [],
    },
    hasChange: false,
  };
};
