import * as types from "../../constants";
import { initialState as permintaan_pembelian } from "../../constants/initialstate/permintaan-pembelian";
import { initialState as pesanan_pembelian } from "../../constants/initialstate/pesanan-pembelian";
import { initialState as penerimaan_barang } from "../../constants/initialstate/penerimaan-barang";
import { initialState as faktur_pembelian } from "../../constants/initialstate/faktur-pembelian";
import { initialState as pembayaran_pembelian } from "../../constants/initialstate/pembayaran-pembelian";
import { initialState as penawaran_penjualan } from "../../constants/initialstate/penawaran_penjualan";
import { initialState as pesanan_penjualan } from "../../constants/initialstate/pesanan-penjualan";
import { initialState as pengiriman_barang } from "../../constants/initialstate/pengiriman_barang";
import { initialState as faktur_penjualan } from "../../constants/initialstate/faktur_penjualan";
import { initialState as faktur_penagihan } from "../../constants/initialstate/faktur_penagihan";
import { initialState as faktur_sementara } from "../../constants/initialstate/faktur_sementara";
import { initialState as penerimaan_penjualan } from "../../constants/initialstate/penerimaan_penjualan";
import { initialState as retur_pembelian } from "../../constants/initialstate/retur-pembelian";
import { initialState as activa } from "../../constants/initialstate/activa";
import { initialState as tipe_activa_tetap_pajak } from "../../constants/initialstate/tipe-activa-tetap-pajak";
import { initialState as tipe_activa_tetap } from "../../constants/initialstate/tipe-activa-tetap";
import { initialState as jurnal_umum } from "../../constants/initialstate/jurnal-umum";
import { initialState as rekonsiliasi } from "../../constants/initialstate/rekonsiliasi";
import { initialState as pembayaran_lainnya } from "../../constants/initialstate/pembayaran-lainnya";
import { initialState as penerimaan_lainnya } from "../../constants/initialstate/penerimaan-lainnya";
import { initialState as pembiayaan_pesanan } from "../../constants/initialstate/pembiayaan-pesanan";
import { initialState as pindah_barang } from "../../constants/initialstate/pindah-barang";
import { initialState as pengelompokan_barang } from "../../constants/initialstate/pengelompokan-barang";
import { initialState as penyesuaian_barang } from "../../constants/initialstate/penyesuaian-barang";
import { initialState as retur_penjualan } from "../../constants/initialstate/retur_penjualan";
import { initialState as transaksi_berulang } from "../../constants/initialstate/transaksi-berulang";
import { initialState as formula_produk } from "../../constants/initialstate/formula-produk";
import { initialState as perintah_kerja } from "../../constants/initialstate/perintah-kerja";
import { initialState as pengeluaran_bahan } from "../../constants/initialstate/pengeluaran-bahan";
import { initialState as hasil_produksi } from "../../constants/initialstate/hasil-produksi";
import { initialState as harga_jual } from "../../constants/initialstate/harga_jual";
import {
	Pelanggan,
	Pemasok,
	Barang,
	DaftarAkun,
} from "../../constants/initialstate/master_data";
import moment from "moment";
import { Persetujuan } from "../../constants/initialstate/master_data/approval";

moment.locale("id");

function getInit(parent, formatDate, key, wipaccount, profileCompany, props) {
	const { pCompany } = profileCompany;
	switch (parent) {
		case "Permintaan Pembelian":
			return permintaan_pembelian(formatDate, moment);
		case "Pesanan Pembelian":
			return pesanan_pembelian(formatDate, moment);
		case "Penerimaan Barang":
			return penerimaan_barang(formatDate, moment);
		case "Faktur Pembelian":
			return faktur_pembelian(formatDate, moment);
		case "Penawaran Penjualan":
			return penawaran_penjualan(formatDate, moment);
		case "Pembayaran Pembelian":
			return pembayaran_pembelian(formatDate, moment, key);
		case "Pesanan Penjualan":
			return pesanan_penjualan(formatDate, moment);
		case "Pengiriman Barang":
			return pengiriman_barang(formatDate, moment);
		case "Faktur Penjualan":
			return faktur_penjualan(formatDate, moment);
		case "Faktur Penagihan":
			return faktur_penagihan(formatDate, moment);
		case "Faktur Sementara":
			return faktur_sementara(formatDate, moment);
		case "Penerimaan Penjualan":
			return penerimaan_penjualan(formatDate, moment);
		case "Retur Penjualan":
			return retur_penjualan(formatDate, moment);
		case "Retur Pembelian":
			return retur_pembelian(formatDate, moment);
		case "Aktiva Tetap":
			return activa(formatDate, moment, key);
		case "Tipe Aktiva Tetap Pajak":
			return tipe_activa_tetap_pajak();
		case "Tipe Aktiva Tetap":
			return tipe_activa_tetap();
		case "Jurnal Umum":
			return jurnal_umum(formatDate, moment);
		case "Rekonsiliasi Bank":
			return rekonsiliasi(formatDate, moment);
		case "Pembayaran Lainnya":
			return pembayaran_lainnya(formatDate, moment);
		case "Penerimaan Lainnya":
			return penerimaan_lainnya(formatDate, moment);
		case "Pembiayaan Pesanan":
			return pembiayaan_pesanan(formatDate, moment, wipaccount);
		case "Pindah Barang":
			return pindah_barang(formatDate, moment);
		case "Pengelompokan Barang":
			return pengelompokan_barang();
		case "Penyesuaian Barang":
			return penyesuaian_barang(formatDate, moment);
		case "Transaksi Berulang":
			return transaksi_berulang(formatDate, moment, props);
		case "Formula Produk":
			return formula_produk(formatDate, moment);
		case "Perintah Kerja":
			return perintah_kerja(formatDate, moment);
		case "Pengeluaran Bahan Baku":
			return pengeluaran_bahan(formatDate, moment);
		case "Hasil Produksi":
			return hasil_produksi(formatDate, moment);
		/**@description MasterData */
		case "Pelanggan":
			return Pelanggan(pCompany.startdate);
		case "Pemasok":
			return Pemasok(pCompany.startdate);
		case "Barang":
			return Barang(pCompany.startdate);
		case "Daftar Akun":
			return DaftarAkun(pCompany.startdate);
		case "Persetujuan":
			return Persetujuan(formatDate, moment);
		case "Harga Jual":
			return harga_jual(formatDate, moment);
		default:
			return {};
	}
}

function addInitial(
  state,
  parent,
  key,
  formatDate,
  wipaccount,
  profileCompany,
  props
) {
  if (key === undefined) {
    delete state[parent];
    return { ...state, [parent]: {} };
  }
  return {
    ...state,
    [parent]: {
      ...state[parent],
      [key]: getInit(
        parent,
        formatDate,
        key,
        wipaccount,
        profileCompany,
        props
      ),
    },
  };
}

export default function Draft(state = {}, action) {
  switch (action.type) {
    case types.ADD_DRAFT:
      const draftform = state[action.parent];
      return {
        ...state,
        [action.parent]: { ...draftform, [action.key]: action.value },
      };
    case types.DELETE_DRAFT:
      let draftParent = {};
      let parent = action.parent;
      if (parent !== undefined) {
        draftParent = state[parent];
        if (draftParent) {
          delete draftParent[action.key];
        } else {
          draftParent = {};
        }
      } else {
        parent = action.key;
      }

      return { ...state, [parent]: draftParent };

    case types.ADD_PARENT_DRAFT:
      return addInitial(
        state,
        action.parent,
        action.key,
        action.formatDate,
        action.wipaccount,
        action.profileCompany,
        action.props
      );
    case types.RESET:
    case types.RESET_TAB:
      return [];

    default:
      return state;
  }
}
