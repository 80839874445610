import i18n from "i18next";
import { reactI18nextModule } from 'react-i18next';
import en from './locale/en';
import fr from './locale/fr';
import it from './locale/it';
import de from './locale/de';
import es from './locale/es';
import id from './locale/id';
import cn from './locale/cn';
import store from '../store'


let defLanguage ="id"
const xxx=  store.getState();
if (xxx.Language !==undefined) {            
  defLanguage =xxx.Language.language
}

i18n
  .use(reactI18nextModule)
  .init({
    resources: {
      en,
      fr,
      it,
      de,
      es,
      id,
      cn,
    },
    lng: defLanguage,
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });
export default i18n;
