export const initialState = (formatDate, moment) => {
  return {
		visible: false,
		tabPane: "1",
		errorPage: null,
		loadingTable_barang: false,
		loadingTable_pemasok: false,
		loadingTable_salinTransaksi: false,
		loading: {
			next: false,
			saveExit: false,
			previous: false,
			pratinjau: false,
			jurnal: false,
			recurring: false,
		},
		validation: [],
		daftar: {
			kategori: [],
			barang: [],
			unit: [],
			penanggungjawab: [],
		},
		dataTarikan: [],
		tipeTarikan: "Pesanan",
		modalTarikTransaksi: false,
		modalPemasok: false,
		modalExchangerate: false,
		modalSimpanTransaksi: false,
		modalSalinTransaksi: false,
		loading_save: false,
		body: {
			details: [
				{
					cost: 0,
					deptid: 0,
					id: 0,
					itemno: "",
					itemreserved1: "",
					itemreserved10: "",
					itemreserved2: "",
					itemreserved3: "",
					itemreserved4: "",
					itemreserved5: "",
					itemreserved6: "",
					itemreserved7: "",
					itemreserved8: "",
					itemreserved9: "",
					jobdescription: "",
					maxdate: moment().format(formatDate),
					nojob: "",
					quantity: 0,
					seq: 0,
					soid: 0,
					sono: "",
					soseq: 0,
					status: 0,
					unit: "",
					unitratio: 1,
					wodetseq: 0,
					woid: 0,
				},
			],
			master: {
				approved: false,
				branchcodeid: 0,
				checknote: false,
				description: "",
				expecteddate: "",
				finished: false,
				follow: "",
				followup: false,
				formulatemplateid: null,
				id: 0,
				idnext: 0,
				idprev: 0,
				idperson: null,
				importedtransactionid: 0,
				noteid: 0,
				reserved1: "",
				reserved2: "",
				reserved3: "",
				reserved4: "",
				reserved5: "",
				templateid: null,
				transactionid: 0,
				urgent: false,
				urgentdesc: "",
				userid: 0,
				wodate: moment().format(formatDate),
				wono: "",
				link01: "",
				link02: "",
				link03: "",
				link04: "",
				link05: "",
				link06: "",
				link07: "",
				link08: "",
				link09: "",
				link10: "",
				link11: "",
				link12: "",
				link13: "",
				link14: "",
				link15: "",
				link16: "",
				link17: "",
				link18: "",
				link19: "",
				link20: "",
			},
			materials: [
				{
					cost: 0,
					costunit: 0,
					id: 0,
					itemno: "",
					keterangan: "",
					qtytaken: 0,
					quantity: 0,
					reserved1: "",
					reserved10: "",
					reserved2: "",
					reserved3: "",
					reserved4: "",
					reserved5: "",
					reserved6: "",
					reserved7: "",
					reserved8: "",
					reserved9: "",
					total: 0,
					unit: "",
					unitratio: 1,
					wodetid: 0,
					wodetseq: 1,
				},
			],
			ordereks: [
				{
					dlaborno: "",
					id: 0,
					keterangan: "",
					quantity: 0,
					reserved1: "",
					reserved10: "",
					reserved2: "",
					reserved3: "",
					reserved4: "",
					reserved5: "",
					reserved6: "",
					reserved7: "",
					reserved8: "",
					reserved9: "",
					standardcost: 0,
					seq: 0,
					wodetid: 0,
					wodetseq: 1,
					totalcost: 0,
				},
			],
			templatedetails: [],
			templatedetailsFormula: [],
		},
		insert: true,
		judul: "Data Baru",
		personData: [],
		dataTemplate: [],
		dataTemplateDetail: [],
		hasChange: false,
		cellSelected: { onidx: 0, onrowIdx: 0 },
	};
};
