import * as types from "../../constants";

const initialState = {
  activeKey: null,
  panes: [],
};

function changeCaption(state, action) {
  state.panes
    .find((x) => x.title === action.induk)
    .content.find((xy) => xy.key === action.activeKey).title = action.name;
  return { ...state };
}

export default function Tabs(state = initialState, action) {
  switch (action.type) {
    case types.INIT_TAB:
      return { activeKey: action.activeKey, panes: action.panes };
    case types.RESET:
      return initialState;
    case types.CLOSE_ALL_TAB:
      return { ...state, panes: [state.panes[0], state.panes[1]] };
    case types.CHANGE_CAPTION_TAB:
      return changeCaption(state, action);
    case types.RESET_TAB:
      return { activeKey: "Informasi", panes: state.panes.splice(0, 2) };
    default:
      return state;
  }
}
