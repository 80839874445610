import { AES, enc, mode, pad } from "crypto-js";
import { ACCESS_TOKEN, ACCESS_TOKEN_ADMIN } from "../../constants";
import moment from "moment";

export const request = (options) => {
	const { type } = options;
	// let contentType = "application/json";
	let headers = new Headers({
		"Content-Type": "application/json",
		"X-Api-Key": generateSecretKey(),
	});
	if (type) {
		headers = new Headers({
			"X-Api-Key": generateSecretKey(),
		});
		// contentType = type;
	}

	if (localStorage.getItem(ACCESS_TOKEN)) {
		headers.append("Authorization", `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`);
	}

	const defaults = {
		headers,
	};
	const controller = new AbortController();
	const timeoutId = setTimeout(() => controller.abort(), 3600000);

	options = Object.assign({}, defaults, options);
	options = { ...options, signal: controller.signal };

	return fetch(options.url, options).then((response) =>
		response
			.json(() => {
				clearTimeout(timeoutId);
			})
			.then((json) => {
				if (!response.ok) {
					return Promise.reject(json);
				}
				return json;
			})
	);
};

export const requestAdmin = (options) => {
	const headers = new Headers({
		"Content-Type": "application/json",
		"X-Api-Key": generateSecretKey(),
	});

	if (localStorage.getItem(ACCESS_TOKEN_ADMIN)) {
		headers.append("Authorization", `Bearer ${localStorage.getItem(ACCESS_TOKEN_ADMIN)}`);
	}

	const defaults = {
		headers,
	};
	options = Object.assign({}, defaults, options);

	return fetch(options.url, options).then((response) =>
		response.json().then((json) => {
			if (!response.ok) {
				return Promise.reject(json);
			}
			return json;
		})
	);
};

export const request2 = (options) => {
	return fetch(options.url, {
		method: options.method,
		headers: {
			Accept: "text/html",
			"Content-Type": "text/html",
		},
	}).then((response) => {
		response.json().then((json) => {
			if (!response.ok) {
				return Promise.reject(json);
			}
			return json;
		});
	});
};

let savedTime = {};
let stillValid = false;
const getDate = () => {
	return fetch(
		"https://script.googleusercontent.com/macros/echo?user_content_key=QGfprAGBdXuSL-L9GR9yKGHHHnIn02F64pTafq5cYvYDtrxBKLObd4WXz-m397hfxAPGARohxoTsTROg2-X4lo6oNEtONwRKm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnJ9GRkcRevgjTvo8Dc32iw_BLJPcPfRdVKhJT5HNzQuXEeN3QFwl2n0M6ZmO-h7C6bwVq0tbM60-uGhoxl1-0xYRxRXMYcElxQ&lib=MwxUjRcLr2qLlnVOLh12wSNkqcO1Ikdrk"
	)
		.then((response) => response.json())
		.then((json) => {
			savedTime = json;
			stillValid = true;
			return json;
		});
};

const generateSecretKey = () => {
	let dateMoment;
	if (!stillValid) {
		dateMoment = moment(getDate().fulldate).format("YYYY-MM-DD HH:mm:ss");
		setTimeout(() => {
			stillValid = false;
		}, 160000);
	} else {
		dateMoment = moment(moment(savedTime.fulldate)).format(
			"YYYY-MM-DD HH:mm:ss"
		);
	}
	let body = {
		// origin: "https://easycloud.co.id",
		clientId: "YPVSG8JLJZ5HKVK9",
		date: dateMoment,
		duration: "3",
	};

	const stringObj = JSON.stringify(body);
	const parsedKey = enc.Utf8.parse("EASYCLOUD1999945");

	const encryptedText = AES.encrypt(stringObj, parsedKey, {
		mode: mode.ECB,
		padding: pad.Pkcs7,
	}).toString();

	return encryptedText;
};
