import React, { Suspense } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import withStyles from "react-jss";
import { Switch, Route, withRouter } from "react-router-dom";
import withSizes from "react-sizes";
import { MIN_AVAIL, TOKEN_FCM } from "../constants/index";
import { Layout, notification } from "antd";
import { getCurrentUser } from "../utility/api/auth";
import { ACCESS_TOKEN } from "../constants";
import PrivateRoute from "../PrivateRoute";
import LoadingIndicator from "../components/additional/LoadingIndicator";
import {
  addCurrentUserAction,
  redirect,
  reset,
  changeAuthAction,
  sessionEnded,
} from "../state/action/auth";

import {
  formatDateCompany,
  transCode,
  hakAkses,
  profileCompany,
} from "../state/action/global";
import { addTenantAction, openTenantActionn } from "../state/action/tenant";
import { changeWidth } from "../state/action/width";
import { styles } from "./Styles";
import { ErrorMessage, search } from "../utility/functions/publicFunctions";
import ModalPreference from "../components/modal/modal-preference-db";

import {
  Login,
  Register,
  NotFound,
  Create_Databae,
  Dashboard,
  Verification,
  ForgotPassword,
  NewPassword,
  Billing,
  Aktifasi,
  AdminPage,
  RegistrasiEPS,
} from "../constants/comps";
import { ThemeProvider } from "react-jss";
import { themes } from "../containers/page/theme/themePalette";
import { logoEasy } from "../constants/icons";
import autoBind from "react-autobind";
import { withNamespaces } from "react-i18next";
import packageJson from "../../package.json";
import CacheBuster from "./CacheBuster";
import { getAll, getHakAkses, listFormatTanggal } from "../utility/api/crud";
import { getTenantByUserId } from "../utility/api/tenant";
import { getTokenFCM, onMessageListener } from "../utility/firebase";
import { RevokeFCM } from "utility/api/global";
import { SocketContext } from "utility/websocket";
global.appVersion = packageJson.version;
const dev = packageJson.dev;

const { Content } = Layout;
class Wrapped extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenFirebase: false,
      isLoading: false,
      isSession: null,
      openDB: null,
      modal_preference: false,
      tenant: null,
      preference: null,
    };

    autoBind(this);

    notification.config({ placement: "topRight", top: 70, duration: 3 });
  }

  loadCurrentUser() {
    const { sessionEnd } = this.props;
    this.setState({ isLoading: true });
    getCurrentUser()
      .then((response) => {
        this.setState({ isLoading: false });
        this.props.addCurentUser({
          currentUser: response,
          isAuthenticated: true,
        });
        if (this.props.Auths.redirectTo === null) {
          this.props.redirect("/tenant");
        }
        this.props.history.push(this.props.Auths.redirectTo);
      })
      .then(() => {
        sessionEnd(true);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        sessionEnd(false);
        this.handleLogout();
      });
  }

  getAksesUser(idUser, dataSourceKey) {
    let getAkses = getHakAkses({
      tenantId: dataSourceKey,
      userId: idUser,
    });
    let dataResponse = [];
    getAkses
      .then((response) => {
        dataResponse = response.data.hakAksesList;
        this.props.transCode(response.data.user.transcode);
      })
      .then(() => this.createTabsPane(dataResponse))
      .catch((error) => {
        ErrorMessage(error);
      });
  }

  createTabsPane = (arr) => {
    const { hakAkses } = this.props;
    let dataAkses = arr;
    let induk = [];
    let dataFinal = [];

    dataAkses.map((datas) => {
      let modul = datas.modul;
      let index =
        induk.length > 0 ? induk.findIndex((name) => name === modul) : -1;
      if (index === -1) {
        induk.push(modul);
        dataFinal.push({
          modul,
          data: [],
        });
        index = induk.length - 1;
      }
      dataFinal[index].data.push(datas);

      return null;
    });
    hakAkses(dataFinal);
  };

  getListFormatTgl = ({ tenantId, formattgl }) => {
    const { formatDateCompany } = this.props;
    let getData = listFormatTanggal({ tenantId });
    getData
      .then((response) => {
        let dateFormat = response.listdata.find((x) => {
          return x.fdateid === formattgl;
        });
        // formatDateCompany(formatDate);
        formatDateCompany(dateFormat.formatdate);
      })
      .catch((error) => {
        ErrorMessage(error);
      });
  };

  loadDatabase() {
    let promise = getTenantByUserId();
    const { addTenant } = this.props;
    promise
      .then((response) => {
        addTenant(response.listdata);
      })
      .catch((error) => {})
      .finally(() => {});
  }

  handleOpen = (dataSourceKey) => {
    const { modal_preference } = this.state;
    if (modal_preference) {
      return;
    }
    let body = {};
    let defAccnt = [];
    let features = {};
    let pcompany = {};
    let addOns = [];
    let ptemplateTypeViews = [];
    let settingReminders = {};
    const { openTenant, redirect, profileCompany } = this.props;
    const { push } = this.props.history;

    let preference;
    this.getAksesUser(this.props.Auths.currentUser.id, dataSourceKey);
    let getDataPreference = getAll(dataSourceKey, "Preferensi");
    getDataPreference
      .then((res) => {
        body = res.data;
        features = JSON.parse(body.pcompany.features);
        addOns = body.addOns;
        pcompany = {
          ...body.pcompany,
          features,
        };
        ptemplateTypeViews = body.ptemplateTypeViews;
        settingReminders = body.settingReminders;
        let detList = res.data.pdefAccnt.pdefAccntDetShowList;
        delete body.pdefAccnt.pdefAccntDetShowList;
        for (let i = 0; i < detList.length; i++) {
          defAccnt.push({
            ...detList[i],
            hutang: detList[i].hutang !== null ? detList[i].hutang.glid : null,
            lrblmterealisasi:
              detList[i].lrblmterealisasi !== null
                ? detList[i].lrblmterealisasi.glid
                : null,
            lrterealisasi:
              detList[i].lrterealisasi !== null
                ? detList[i].lrterealisasi.glid
                : null,
            piutang:
              detList[i].piutang !== null ? detList[i].piutang.glid : null,
            potbeli:
              detList[i].potbeli !== null ? detList[i].potbeli.glid : null,
            potjual:
              detList[i].potjual !== null ? detList[i].potjual.glid : null,
            umbeli: detList[i].umbeli !== null ? detList[i].umbeli.glid : null,
            umjual: detList[i].umjual !== null ? detList[i].umjual.glid : null,
          });
        }
      })
      .then(() => {
        preference = {
          settingReminders,
          addOns,
          pcompany,
          ptemplateTypeViews,
          pdefAccnt: {
            ...body.pdefAccnt,
            labaditahan: body.pdefAccnt.labaditahan
              ? body.pdefAccnt.labaditahan.glid
              : null,
            ob: body.pdefAccnt.ob ? body.pdefAccnt.ob.glid : null,
            defAccntDet: defAccnt,
            iteminventory: body.pdefAccnt.iteminventory
              ? body.pdefAccnt.iteminventory.glid
              : null,
            itemsales: body.pdefAccnt.itemsales
              ? body.pdefAccnt.itemsales.glid
              : null,
            itemsalesret: body.pdefAccnt.itemsalesret
              ? body.pdefAccnt.itemsalesret.glid
              : null,
            itemcogs: body.pdefAccnt.itemcogs
              ? body.pdefAccnt.itemcogs.glid
              : null,
            itemdisc: body.pdefAccnt.itemdisc
              ? body.pdefAccnt.itemdisc.glid
              : null,
            itempurchaseret: body.pdefAccnt.itempurchaseret
              ? body.pdefAccnt.itempurchaseret.glid
              : null,
            itempurchase: body.pdefAccnt.itempurchase
              ? body.pdefAccnt.itempurchase.glid
              : null,
            itemunbilled: body.pdefAccnt.itemunbilled
              ? body.pdefAccnt.itemunbilled.glid
              : null,
            itemgoods: body.pdefAccnt.itemgoods
              ? body.pdefAccnt.itemgoods.glid
              : null,
            itemjobcost: body.pdefAccnt.itemjobcost
              ? body.pdefAccnt.itemjobcost.glid
              : null,
            povariant: body.pdefAccnt.povariant
              ? body.pdefAccnt.povariant.glid
              : null,
            wip: body.pdefAccnt.wip ? body.pdefAccnt.wip.glid : null,
            defrounded: body.pdefAccnt.defrounded
              ? body.pdefAccnt.defrounded.glid
              : null,
            deftunai: body.pdefAccnt.deftunai
              ? body.pdefAccnt.deftunai.glid
              : null,
            mohallocated: body.pdefAccnt.mohallocated
              ? body.pdefAccnt.mohallocated.glid
              : null,
            mohcontrol: body.pdefAccnt.mohcontrol
              ? body.pdefAccnt.mohcontrol.glid
              : null,
            pajakhasil: body.pdefAccnt.pajakhasil
              ? body.pdefAccnt.mohcontrol.glid
              : null,
            poother: body.pdefAccnt.poother
              ? body.pdefAccnt.poother.glid
              : null,
          },
        };
        this.setState({
          preference,
        });
      })
      .then(() => {
        const { pcompany } = preference;
        this.getListFormatTgl({
          tenantId: dataSourceKey,
          formattgl: pcompany.formattgl,
        });
        profileCompany(preference);
        if (preference.pcompany.preparation) {
          push(`/open=${dataSourceKey}`);
          redirect(`/open=${dataSourceKey}`);

          let tenantCari = this.props.Tenants.Tenants.map((x) => x.tenantId);

          let tenant = search("dataSourceKey", dataSourceKey, tenantCari);

          openTenant({
            id: dataSourceKey,
            isOpened: true,
            data: this.props.Tenants.Tenants[tenant.urut],
          });
        } else {
          // info({
          //   title: "Admin belum mengatur preferensi database",
          //   content:
          //     "Preferensi database harus di atur terlebih dahulu untuk membukanya",
          // });
          // this.showCloseModal({ name: "modal_preference" });

          let tenantCari = this.props.Tenants.Tenants.map((x) => x.tenantId);

          let tenant = search("dataSourceKey", dataSourceKey, tenantCari);

          this.setState({
            modal_preference: true,
            tenant: this.props.Tenants.Tenants[tenant.urut],
          });
        }
      })
      .catch((error) => {
        ErrorMessage(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleOpenDB(authToken, db) {
    localStorage.setItem(ACCESS_TOKEN, authToken);
    this.props.changeAuth({
      isvalidlogin: true,
      token: authToken,
    });
    this.loadDatabase();

    getCurrentUser()
      .then((response) => {
        this.props.addCurentUser({
          currentUser: response,
          isAuthenticated: true,
        });
        this.handleOpen(db);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        this.handleLogout();
      });
  }
  componentDidUpdate(prevProps, prevState) {
    const { Auths } = this.props;
    if (Auths.isSession !== prevProps.Auths.isSession && !Auths.isSession) {
      this.handleLogout();
    }
  }

  handleSetToken = (token) => {
    window.localStorage.setItem(TOKEN_FCM, token);
  };
  componentDidMount() {
    getTokenFCM(this.handleSetToken);
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    const paramsMatch = window.location.href.match(/\?.+/);

    if ((this.props.Auths.isvalidlogin === true || token) && !paramsMatch) {
      this.loadCurrentUser();
    }

    if (paramsMatch) {
      const params = new URLSearchParams(paramsMatch[0]);
      const authToken = params.get("token");
      const db = params.get("db");
      const pvt = params.get("private");
      if (authToken) {
        this.setState({ isLoading: true });
        this.handleOpenDB(authToken, db);
      }

      if (pvt) {
        this.setState({ openDB: pvt || null });
      }
    }

    // TODO, uncomment sebelum build.

    if (!dev) {
      window.onbeforeunload = (e) => {
        window.onunload = () => {
          localStorage.removeItem(ACCESS_TOKEN);
        };
        return undefined;
      };

      window.onload = () => {};
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    getCurrentUser()
      .then((res) => {
        this.setState({ isSession: true });
      })
      .catch((err) => {
        if (err.status === 403) {
          this.setState({ isSession: false });
          this.handleLogout();
        }
      });
  }

  componentWillUnmount() {
    if (SocketContext) SocketContext.disconnect();
  }

  handleLogin() {
    const { t } = this.props;
    let { openDB } = this.state;
    notification.success({
      message: "Easy Cloud",
      description: t("Anda berhasil masuk."),
    });

    if (this.props.Auths.isvalidlogin === true) {
      if (openDB !== null) {
        // this.props.redirect("/private-wait");
        this.setState({ isLoading: true });
        this.handleOpenDB(localStorage.getItem(ACCESS_TOKEN), openDB);
        return;
      }
      this.props.redirect("/tenant");
      this.loadCurrentUser();
    }
  }

  handleLogout(
    redirectTo = "/",
    notificationType = "success",
    description = this.props.t("Anda berhasil keluar.")
  ) {
    const { t, Auths } = this.props;
    const { isSession } = this.state;
    const id = Auths.currentUser?.id;
    RevokeFCM(id)
      .then((response) => {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem("state");
        this.props.addCurentUser({ currentUser: null, isAuthenticated: false });
        this.props.redirect("/");
        this.props.history.push(redirectTo);
        if (!isSession) {
          ErrorMessage({
            status: 409,
            message: t("Anda sudah masuk pada perangkat lain."),
          });
        } else {
          notification[notificationType]({
            message: "Easy Cloud",
            description,
          });
        }

        this.props.history.push("/");
        this.props.reset();
        localStorage.removeItem("state");
      })
      .catch((err) => {
        ErrorMessage(err);
      });
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator />;
    }
    let { Auths, classes, isAvailable } = this.props;

    let { openDB } = this.state;

    isAvailable = true;

    return isAvailable ? (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            // refreshCacheAndReload();
          }

          return (
            <ThemeProvider
              theme={
                themes[
                  Auths.currentUser
                    ? Auths.currentUser.colormode
                    : "themePurple"
                ]
              }
            >
              <Suspense fallback={<LoadingIndicator />}>
                <Layout className={classes.body}>
                  <Content>
                    <div className={classes.container}>
                      <Switch>
                        <Route
                          exact
                          path="/"
                          render={(props) => {
                            return (
                              <Login onLogin={this.handleLogin} {...props} />
                            );
                          }}
                        />

                        <Route
                          path="/private=:pvt"
                          render={(props) => {
                            return (
                              <Login onLogin={this.handleLogin} {...props} />
                            );
                          }}
                        />
                        <Route path="/register" component={Register} />
                        <Route
                          path="/verifikasi/accesstoken=:id"
                          component={Verification}
                        />
                        <Route
                          path="/create-new-password/accesstoken=:id"
                          component={NewPassword}
                        />
                        <Route path="/reset" component={ForgotPassword} />
                        <Route path="/internal" component={AdminPage} />
                        <PrivateRoute
                          authenticated={Auths.isAuthenticated}
                          path="/tenant"
                          component={Create_Databae}
                          currentUser={Auths.currentUser}
                          handleLogout={this.handleLogout}
                          openDB={openDB}
                        />
                        <PrivateRoute
                          authenticated={Auths.isAuthenticated}
                          path="/billing=:id&:id"
                          component={Billing}
                          currentUser={Auths.currentUser}
                        />
                        <PrivateRoute
                          authenticated={Auths.isAuthenticated}
                          path="/penawaran=:id&:id"
                          component={Aktifasi}
                          currentUser={Auths.currentUser}
                        />
                        <PrivateRoute
                          authenticated={Auths.isAuthenticated}
                          path="/register=:id&:id"
                          component={RegistrasiEPS}
                          currentUser={Auths.currentUser}
                        />
                        <PrivateRoute
                          authenticated={Auths.isAuthenticated}
                          path="/open=:id"
                          component={Dashboard}
                          currentUser={Auths.currentUser}
                          handleLogout={this.handleLogout}
                          subdomain="public"
                        />
                        <Route component={NotFound} />
                      </Switch>
                    </div>
                    <ModalPreference
                      history={this.props.history}
                      tenant={this.state.tenant}
                      dataCompany={this.state.preference}
                      visible={this.state.modal_preference}
                      onCancel={() =>
                        this.setState({ modal_preference: false, tenant: null })
                      }
                    />
                  </Content>
                </Layout>
              </Suspense>
            </ThemeProvider>
          );
        }}
      </CacheBuster>
    ) : (
      <div
        style={{
          backgroundColor: "#efae15",
          textAlign: "center",
          height: "100%",
          color: "white",
          fontSize: "20px",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logoEasy} alt="logo" />
        Sorry, that device is not supported.<br></br>Minimum resolution width is{" "}
        {MIN_AVAIL}px.
      </div>
    );
  }
}

const mapStateToProps = ({ Tenants, Auths, Tabs, Theme }) => ({
  Auths,
  Tabs,
  Theme,
  Tenants,
});

const mapSizesToProps = ({ width }) => ({
  isAvailable: width > MIN_AVAIL,
});

const mapDispatchToProps = (dispatch) => ({
  formatDateCompany: (response) => {
    dispatch(formatDateCompany(response));
  },
  profileCompany: (response) => {
    dispatch(profileCompany(response));
  },
  transCode: (response) => {
    dispatch(transCode(response));
  },
  addCurentUser: (bodyAuth) => {
    dispatch(addCurrentUserAction(bodyAuth));
  },
  changeWidth: (width) => {
    dispatch(changeWidth(width));
  },
  redirect: (link) => {
    dispatch(redirect(link));
  },
  reset: (link) => {
    dispatch(reset(link));
  },
  changeAuth: (bodyAuth) => {
    dispatch(changeAuthAction(bodyAuth));
  },
  hakAkses: (data) => {
    dispatch(hakAkses(data));
  },
  openTenant: (body) => {
    dispatch(openTenantActionn(body));
  },
  addTenant: (bodyTenant) => {
    dispatch(addTenantAction(bodyTenant));
  },
  sessionEnd: (value) => {
    dispatch(sessionEnded(value));
  },
});

const App = withStyles(styles)(Wrapped);

export default withRouter(
  withNamespaces("translations")(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withSizes(mapSizesToProps)(App))
  )
);
