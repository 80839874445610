import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createBrowserHistory as createHistory } from "history";
import Reducers from "./state/reducer";
// import AES from "crypto-js/aes";
// import CryptoJS from "crypto-js";
// import { KEY } from "./constants/index";
import { initialStateTemplate } from "./constants/index";

export const history = createHistory();

const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const loadState = () => {
  try {
    let serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    } else {
      // serializedState = AES.decrypt(serializedState, KEY).toString(
      //   CryptoJS.enc.Utf8
      // );
    }

    let xx = JSON.parse(serializedState);
    return { ...xx, ...initialStateTemplate };
  } catch (e) {
    return initialStateTemplate;
  }
};

const saveState = (state) => {
  try {
    // const serializedState = AES.encrypt(JSON.stringify(state), KEY);
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) { }
};

const peristedState = loadState();

const store = createStore(
  connectRouter(history)(Reducers),
  peristedState,
  composedEnhancers
);

// TODO comment sebelum build
store.subscribe(() => {
  let xxx = store.getState();
  const allowed = [
    "Auths",
    "Tabs",
    "Tenants",
    "Draft",
    "Language",
    "Transaction",
    // "svgRender",
    "layoutBuilder",
    "Global",
    "Laporan",
  ];
  const filtered = Object.keys(xxx)
    .filter((key) => allowed.includes(key))
    .reduce((obj, key) => {
      obj[key] = xxx[key];
      return obj;
    }, {});
  saveState(filtered);
});

export default store;
