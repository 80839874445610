import { combineReducers } from "redux";
import Auths from "./reducer/auth";
import Billing from "./reducer/billing";
import Tenants from "./reducer/tenant";
import IsMobile from "./reducer/width";
import Master_data from "./reducer/master_data";
import Tabs from "./reducer/panes";
import Draft from "./reducer/draft";
import Pelanggan from "./reducer/pelanggan";
import RemovePanes from "./reducer/removepanes";
import Daftar_akun from "./reducer/daftar_akun";
import Global from "./reducer/global";
import Language from "./reducer/language";
import Transaction from "./reducer/transaction";
import svgRender from "./reducer/svgRenderReducer";
import layoutBuilder from "./reducer/layoutBuilderReducer";
import Admin from "./reducer/admin";
import Theme from "./reducer/theme";
import Laporan from "./reducer/laporan";
import Chat from "./reducer/chat";

const Reducers = combineReducers({
  Auths,
  Admin,
  Tenants,
  IsMobile,
  svgRender,
  layoutBuilder,
  Master_data,
  Tabs,
  Draft,
  Pelanggan,
  RemovePanes,
  Daftar_akun,
  Global,
  Language,
  Transaction,
  Theme,
  Laporan,
  Billing,
  Chat,
});

export default Reducers;
