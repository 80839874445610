export const initialState = (formatDate, moment) => {
  return {
    modalSimpanTransaksi: false,
    modalSalinTransaksi: false,
    hasChange: false,
    dataTemplate: [],
    dataTarikan: [],
    insert: true,
    errorPage: null,
    validation: [],
    personData: {},
    loadingTable_salinTransaksi: false,
    daftar: {
      pelanggan: [],
      akunPenjualan: [],
      akunBiaya: [],
    },
    total: {
      subtotal: 0,
      diskon: 0,
      ppn: 0.1,
      pajak2: 0,
      freight: 0,
      jumlah: 0,
    },
    body: {
      sndata: [],
      downpayments: [
        {
          arinvoiceid: "",
          brutounitprice: 0,
          deptid: "",
          dpid: "",
          dpseq: "",
          fiscalrate: 1,
          inclusivetax: false,
          invoiceno: "",
          itemno: "",
          itemovdesc: "",
          projectid: "",
          qty: "",
          rate: 1,
          seq: "",
          soid: 0,
          sono: "",
          taxableamount1: "",
          taxableamount2: "",
          taxcodes: "",
          unitprice: 0,
        },
      ],
      details: [
        {
          arinvoiceid: 0,
          brutounitprice: 0,
          deptid: 1,
          discfaktur: 0,
          discinc: 0,
          doid: 0,
          doseq: 0,
          dpused: 0,
          groupno: "",
          groupseq: 0,
          itemcost: 0,
          itemcostbase: 0,
          itemdiscpc: "",
          itemhistid: 0,
          itemno: "",
          itemtype: 0,
          itemovdesc: "",
          itemreserved1: "",
          itemreserved10: "",
          itemreserved11: "",
          itemreserved12: "",
          itemreserved13: "",
          itemreserved14: "",
          itemreserved15: "",
          itemreserved16: "",
          itemreserved17: "",
          itemreserved18: "",
          itemreserved19: "",
          itemreserved2: "",
          itemreserved20: "",
          itemreserved3: "",
          itemreserved4: "",
          itemreserved5: "",
          itemreserved6: "",
          itemreserved7: "",
          itemreserved8: "",
          itemreserved9: "",
          itemunit: "",
          proceed: true,
          projectid: 1,
          quantity: "",
          quantityreq: 0,
          quoteid: 0,
          quoteseq: 0,
          requisition: true,
          seq: 1,
          sistatqty: 0,
          sistatqtyrt1: 0,
          snok: false,
          soid: 0,
          soseq: 0,
          taxableamount1: 0,
          taxableamount2: 0,
          taxcodes: "",
          unitprice: 0,
          unitratio: 1,
          usedinsiid: 0,
          warehouseid: 1,
          sono: "",
          quoteno: "",
          warename: "",
          qtysaatini: "",
        },
      ],
      master: {
        overbudget: true,
        approved: false,
        approvedesc: null,
        araccount: null,
        arinvoiceid: 0,
        cashdiscount: 0,
        cashdiscpc: "",
        cashsales: true,
        checknote: true,
        chequedate: "",
        chequeno: "",
        currencyid: 1,
        currencyname: "",
        customerid: null,
        customeristaxable: false,
        customername: "",
        customerno: "",
        deliveryorder: false,
        depositto: 0,
        description: "",
        dp: 0,
        dpaccount: "",
        dpused: 0,
        fiscalrate: 0,
        fob: "",
        follow: "",
        followup: false,
        urgent: false,
        freight: 0,
        freightaccnt: "",
        getfromdo: false,
        getfromother: true,
        getfromquote: true,
        getfromso: false,
        glhistid: 0,
        glperiod: 0,
        glyear: 0,
        idnext: 0,
        idprev: 0,
        imported: false,
        inclusivetax: false,
        invfromsr: true,
        invoiceamount: 0,
        invoicedate: moment().format(formatDate),
        invoiceno: "",
        invoicetype: 0,
        isdp: 0,
        istaxpayment: true,
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
        locked_by: "",
        locked_time: "",
        noteid: 0,
        owing: 0,
        owingdc: 0,
        paidamount: 0,
        parentarinvid: 0,
        payment: 0,
        posted: false,
        printed: false,
        purchaseorderno: "",
        rate: 0,
        reconciled: true,
        reconcileid: 0,
        reportedtax1: true,
        reportedtax2: true,
        returnamount: 0,
        roundedtax1amount: 0,
        roundedtax2amount: 0,
        salesmanid: "",
        shipdate: null,
        shipid: "",
        shipped: true,
        shipto1: "",
        shipto2: "",
        shipto3: "",
        shipto4: "",
        shipto5: "",
        status: "",
        subtotal: "",
        tax1amount: 0,
        tax1code: "",
        tax1id: 0,
        tax1rate: 0,
        tax2amount: 0,
        tax2code: "",
        tax2id: 0,
        tax2rate: 0,
        taxableamount1: 0,
        taxableamount2: 0,
        taxableamount3: 0,
        taxableamtcode1: "",
        taxableamtcode2: "",
        taxableamtcode3: "",
        taxdate: null,
        taxformcode: "",
        taxformnumber: "",
        templateid: 23,
        termdiscount: 0,
        termid: "",
        transactionid: 0,
        userid: 0,
        usesn: true,
        warehouseid: 1,
        lastid: 0,
        shiptoData: "",
        recdetid: 0,
      },
      templatedetails: [],
      attachment: [],
    },
  };
};
