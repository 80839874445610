export const initialState = (formatDate, moment) => {
  return {
    insert: true,
    daftar: {
      warehouse: [],
    },
    body: {
      sndata: [],
      details: [
        {
          itemhistid: 0,
          itemno: "",
          itemovdesc: "",
          itemunit: "",
          quantity: 0,
          seq: 0,
          transferid: 0,
          unitratio: 1,
        },
      ],
      master: {
        approved: false,
        approvedesc: null,
        follow: null,
        followup: false,
        urgent: false,
        description: "",
        fromwh: "",
        fromwhid: null,
        fromaddress: null,
        glperiod: 0,
        glyear: 0,
        idnext: 0,
        idprev: 0,
        imported: 0,
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
        locked_by: "",
        locked_time: "",
        templateid: 29,
        towhid: null,
        towh: "",
        toaddress: null,
        transferdate: moment().format(formatDate),
        transferid: 0,
        transferno: "",
        userid: 0,
      },
      templatedetails: [],
    },
    dataTemplate: [],
    hasChange: false,
  };
};
