import { BASE_CDN } from "../../constants/index";
import React from "react";
import { createUseStyles } from "react-jss";
// import BarLoader from "react-spinners/BarLoader";
import "./sidebar.css";

const useStyles = createUseStyles({
  container: {
    display: "grid",
    "justify-content": "center",
    "align-items": "center",
    height: "100%",
    "font-family": "Segoe UI",
  },
});
export default function LoadingIndicator(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="hexcontainer scaled">
        <img
          src={`
            ${BASE_CDN}/v1648785199/easy6_pbpwrb.png
          `}
          alt="easy logo"
          className="logo-loading"
        />
      </div>
    </div>
  );
}
