export const initialState = (formatDate, moment) => {
  return {
    modalSimpanTransaksi: false,
    modalSalinTransaksi: false,
    dataTemplate: [],
    hasChange: false,
    fromFaktur: false,
    errorPage: null,
    insert: true,
    validation: [],
    daftar: {
      pelanggan: [],
      getBank: [],
      project: [],
      department: [],
    },
    personData: {},
    body: {
      datadisc: [
        {
          accountname: "",
          deptid: 0,
          deptname: "",
          description: "",
          discaccount: 0,
          discount: 0,
          glaccount: "",
          invpmtid: 0,
          paymentid: 0,
          projectid: 0,
          projectname: "",
          seq: 1,
        },
      ],
      details: [
        {
          arinvoiceid: 0,
          paymentid: 0,
          currencyid: 0,
          currencyname: "",
          description: "",
          discount: 0,
          duedate: null,
          invoiceamount: 0,
          invoicedate: null,
          invoiceno: "",
          owing: 0,
          owingdc: 0,
          paymentamount: 0,
          pph23amount: 0,
          pph23fiscalrate: 0,
          pph23number: "",
          pph23rate: 0,
          recdisc: 0,
          seq: 1,
          strowing: "",
          taxpph23: 0,
          termname: "",
          pay: false,
          discdate: null,
          disctakenamount: 0,
        },
      ],
      master: {
        overbudget: true,
        applyfromcredit: true,
        approved: false,
        approvedesc: null,
        aroverpay: 0,
        bankaccount: null,
        customerid: null,
        checknote: true,
        cheqno: "",
        chequeamount: 0,
        chequedate: moment().format(formatDate),
        chequeno: "",
        currencyid: 0,
        currencyname: "",
        customername: "",
        customerno: "",
        deptid: 1,
        description: "",
        discountamount: 0,
        fiscalpmt: false,
        fiscalpmt_disabled: true,
        follow: "",
        followup: false,
        urgent: false,
        glhistid: 0,
        glperiod: 0,
        glyear: 0,
        idnext: 0,
        idprev: 0,
        imported: 0,
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
        locked_by: "",
        locked_time: "",
        noteid: 0,
        overpay: 0,
        overpayused: 0,
        paymentdate: moment().format(formatDate),
        paymentid: 0,
        posted: true,
        printed: "",
        projectid: 1,
        rate: 1,
        reconcileid: 0,
        sequenceno: "",
        templateid: 25,
        userid: 0,
        lastid: 0,
      },
      templatedetails: [],
      attachment: [],
    },
  };
};
