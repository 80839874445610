import React, { Component } from "react";
import { Modal, Input, Switch, Button, Icon } from "antd";
import withStyles from "react-jss";
import { styles } from "../../assets/styles/jss/components/modal";
import autoBind from "react-autobind";
import { withNamespaces } from "react-i18next";
import { ModalErrorMessage } from "../../utility/functions/publicFunctions";

class ModalAddCurrency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputField: [
        {
          autodefaccnt: false,
          currencyid: 0,
          currencyname: "",
          exchangerate: "",
        },
      ],
    };
    autoBind(this);
  }
  addField() {
    const { inputField } = this.state;
    let add = {
      autodefaccnt: false,
      currencyid: 0,
      currencyname: "",
      exchangerate: "",
    };
    for (let xx = 0; xx < inputField.length; xx++) {
      if (
        inputField[xx].currencyname !== "" &&
        inputField[xx].exchangerate !== ""
      ) {
        this.setState({
          inputField: [...inputField, add],
        });
      } else {
        ModalErrorMessage({
          status: 409,
          message: "Mata Uang/Nilai Tukar Belum diisi.",
        });
        return;
      }
    }
  }

  onClose(e) {
    this.props.onCancel(e);
    this.setState({
      inputField: [
        {
          autodefaccnt: false,
          currencyid: 0,
          currencyname: "",
          exchangerate: "",
        },
      ],
    });
  }

  onChange(e, index) {
    const { name, value } = e.target;
    const { inputField } = this.state;
    inputField[index][name] = value;
    this.setState({ inputField });
  }

  onOk(e) {
    e.preventDefault();
    const { inputField } = this.state;
    for (let xx = 0; xx < inputField.length; xx++) {
      if (
        inputField[xx].currencyname !== "" &&
        inputField[xx].exchangerate !== ""
      ) {
        this.props.handle.onChange(
          {
            target: { name: "pcurrency", value: inputField },
          },
          "pcurrency"
        );
        this.onClose(e);
      } else {
        ModalErrorMessage({
          status: 409,
          message: "Mata Uang/Nilai Tukar Belum diisi.",
        });
        return;
      }
    }
  }

  onRemove(index) {
    const { inputField } = this.state;
    inputField.splice(index, 1);
    this.setState({
      inputField,
    });
  }
  render() {
    const { visible, classes, t } = this.props;
    const { inputField } = this.state;
    return (
      <Modal
        maskStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
        closable={false}
        destroyOnClose
        title={t("Tambah Mata Uang")}
        visible={visible}
        okText="Add"
        cancelText="Kembali"
        centered={true}
        footer={[
          <Button size="small" type="danger" onClick={this.onClose}>
            {t("Kembali")}
          </Button>,
          <Button size="small" type="primary" onClick={this.addField}>
            {t("Tambah Mata Uang")}
          </Button>,
          <Button size="small" type="primary" onClick={this.onOk}>
            Ok
          </Button>,
        ]}
      >
        {inputField.map((field, i) => (
          <div className={classes.matauang_modal}>
            <Input
              size="small"
              placeholder={t("Mata Uang")}
              name="currencyname"
              value={field.currencyname}
              onChange={(e) => this.onChange(e, i)}
            />
            <Input
              size="small"
              name="exchangerate"
              placeholder={t("Nilai Tukar")}
              value={field.exchangerate}
              onChange={(e) => this.onChange(e, i)}
            />
            <div>
              <label className="mr-5">{t("Mata Uang Otomatis")}</label>
              <Switch
                size="small"
                checked={field.autodefaccnt}
                onChange={(e) =>
                  this.onChange(
                    { target: { name: "autodefaccnt", value: e } },
                    i
                  )
                }
              />
            </div>
            {i !== 0 ? (
              <Icon type="minus-circle" onClick={this.onRemove} />
            ) : null}
          </div>
        ))}
      </Modal>
    );
  }
}

export default withNamespaces("translations")(
  withStyles(styles)(ModalAddCurrency)
);
