export const initialState = () => {
  return {
    loading: false,
    body: {
      afistypeid: 0,
      assettype: 0,
      fatypedesc: "",
    },
    insert: true,
    judul: "Data Baru",
    dataTipeAktivaTetapPajak: [],
    hasChange: false,
  };
};
