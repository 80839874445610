export const styles = (theme) => ({
  table: {
    width: "100%",
    "& .react-grid-HeaderCell": {
      background: theme.columnColor,
      // background: theme.labelColor,
      color: "#fff",
      padding: "0px 8px",
    },
    "& .react-grid-Grid": {
      borderRadius: "5px",
    },
    "&  .drag-handle": {
      display: "none",
    },
    "& .widget-HeaderCell__value": {
      fontWeight: "400",
      fontSize: "10.5pt",
    },
    "& .react-grid-Cell__value": {
      fontSize: "9pt",
      color: "#000000",
    },
    "& .react-grid-Cell": {
      cursor: "pointer",
    },
  },
  content_import: {
    padding: "10px 20px",
    width: "calc(100% - 20em)",
    float: "right",
    overflow: "auto",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    gridGap: "10px",
  },
  main_import: {
    height: "calc(100vh - 99px)",
    overflow: "hidden",
  },
  [`@media screen and (min-width: 321px) and (max-width: 768px)`]: {
    content_import: {
      width: "calc(100% - 4em)",
    },
  },
});
