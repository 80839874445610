export const styles = (theme) => ({
  burgerIcon: {
    cursor: "pointer",
    position: "absolute",
    left: 24,
    paddingTop: 16,
    display: "flex",
    alignItems: "center",
    height: 54,
    zIndex: 998,
  },
  mainContainer: {
    height: "calc(100vh - 116px)",
    minHeight: "calc(100vh - 116px)",
    // minHeight: 'calc(100vh - 116px)'
    // minHeight: "100vh"
  },
  icon_title: {
    width: "16px",
    height: "16px",
  },
  mainContainerMobile: {
    position: "absolute",
    width: "100vw",
    minWidth: "100%",
    top: 0,
    left: 0,
  },
  hide: {
    left: -255,
  },
  show: {
    left: 0,
  },
  containerSidebar: {
    backgroundColor: theme.columnColor,
    width: 255,
    padding: "15px 5px",
    height: "100%",
    overflowY: "scroll",
  },
  containerSidebarMobile: {
    transition: "left 0.5s, right 0.5s",
    position: "absolute",
    width: 255,
    height: "100%",
    zIndex: 901,
  },
  outsideLayer: {
    position: "absolute",
    width: "100vw",
    minWidth: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.50)",
    zIndex: 900,
  },
  menuItemList: {
    marginTop: 10,
  },
  containerItem: {
    height: 50,
    cursor: "pointer",
    paddingLeft: 5,
    paddingRight: 5,
    "& :hover": {
      // opacity: 1
    },
  },
  activeContainer: {
    // backgroundColor: "rgba(221,226,255, 0.08)",
    background: "rgba(221,226,255, 0.08)",
  },
  activeBar: {
    height: 56,
    width: 3,
    backgroundColor: "#DDE2FF",
    position: "absolute",
    left: 0,
  },
  activeTitle: {
    color: "#DDE2FF",
  },
  titleItem: {
    fontSize: 15,
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#fff",
    marginLeft: 20,
  },
  sidebarNavigation: {
    marginTop: 42,
    background: theme.columnColor,
    height: "calc(100% - 42px)",
  },
  sidebar: ` 
    background: #373737;
  `,
  title: {
    display: "block",
  },
  [`@media screen and (min-width: 321px) and (max-width: 768px)`]: {
    icon_title: {
      display: "block",
      width: "24px",
      height: "24px",
    },
    title: {
      display: "none",
    },
  },
});
