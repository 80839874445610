import * as types from '../../constants';

const initialState = {};

export default function pelanggan(state = initialState, action) {
    switch (action.type) {
        case types.ADD_PELANGGAN:
            return {
                ...state,
                [action.key]: action.value
            }
        case types.RESET:
            return initialState
        default:
            return state;
    }
}