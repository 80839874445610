export const initialState = (formatDate, moment) => {
  return {
    errorPage: null,
    visible: false,
    tabPane: "1",
    personData: [],
    modalExchangerate: false,
    modalSimpanTransaksi: false,
    modalSalinTransaksi: false,
    loadingTable_transaksi: false,
    loadingTable_barang: false,
    loadingTable_pemasok: false,
    loadingTable_salinTransaksi: false,
    loading: {
      next: false,
      saveExit: false,
      previous: false,
      pratinjau: false,
      jurnal: false,
      faktur: false,
      email: false,
    },
    validation: [],
    pesananPembelian: [],
    daftar: {
      pemasok: [],
      akun: [],
    },
    body: {
      sndata: [],
      details: [],
      itmdetails: [
        {
          apinvoiceid: 0,
          billid: 0,
          brutounitprice: 0,
          deptid: 0,
          dpused: 0,
          itemcost: 0,
          itemcostbase: 0,
          itemdiscpc: "",
          itemhistid: 0,
          itemno: "",
          groupno: "",
          groupseq: 0,
          itemovdesc: "",
          itemreserved1: "",
          itemreserved10: "",
          itemreserved11: "",
          itemreserved12: "",
          itemreserved13: "",
          itemreserved14: "",
          itemreserved15: "",
          itemreserved16: "",
          itemreserved17: "",
          itemreserved18: "",
          itemreserved19: "",
          itemreserved2: "",
          itemreserved20: "",
          itemreserved3: "",
          itemreserved4: "",
          itemreserved5: "",
          itemreserved6: "",
          itemreserved7: "",
          itemreserved8: "",
          itemreserved9: "",
          itemunit: "",
          poid: 0,
          poseq: 0,
          projectid: 0,
          qtysn: 0,
          qtyused: "",
          quantity: "",
          seq: 0,
          taxableamount1: 0,
          taxableamount2: 0,
          taxcodes: "",
          unitprice: 0,
          unitratio: 1,
          warehouseid: 0,
          detpono: "",
          reqid: "",
          reqseq: "",
          detreqno: "",
        },
      ],
      master: {
        apaccount: 0,
        apinvoiceid: 0,
        approved: false,
        approvedesc: null,
        bill: false,
        cashdiscount: 0,
        cashdiscpc: "",
        cashpurchase: false,
        checknote: false,
        chequedate: "",
        chequeno: "",
        description: "",
        discounttaken: false,
        dp: 0,
        dpaccount: "",
        dpused: 0,
        expenseamount: 0,
        expensesjournaldatetype: 0,
        expensesofbillaccnt: "",
        expensesofbillid: 0,
        expensesofbillseq: 0,
        fiscalrate: 1,
        fob: "",
        follow: "",
        followup: false,
        urgent: false,
        freight: 0,
        freightaccnt: 0,
        freighttocost: false,
        freighttovendor: false,
        getfrompo: false,
        getfromri: false,
        glhistid: 0,
        glperiod: 0,
        glyear: 0,
        idnext: 0,
        idprev: 0,
        imported: false,
        inclusivetax: false,
        invfrompr: false,
        invoiceamount: 0,
        invoicedate: moment().format(formatDate),
        invoiceistaxable: false,
        invoiceno: "",
        invoiceseqno: 0,
        invoicetype: 0,
        invtaxno1: "",
        invtaxno2: "",
        isdp: 0,
        itemamount: 0,
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
        locked_by: "",
        locked_time: "",
        noform: "",
        noteid: 0,
        owing: 0,
        owingdc: 0,
        paidamount: 0,
        paidfrom: 0,
        parentarinvid: 0,
        payment: 0,
        posted: false,
        printed: false,
        purchaseorderno: "",
        rate: 1,
        reconciled: false,
        reconcileid: 0,
        reportedtax1: false,
        reportedtax2: false,
        returnamount: 0,
        roundedtax1amount: 0,
        roundedtax2amount: 0,
        sequenceno: "",
        shipdate: null,
        shipped: false,
        shipvendid: 0,
        shipvia: null,
        snok: false,
        sspdate: "",
        tax1amount: 0,
        tax1code: "",
        tax1id: 0,
        tax1rate: 0,
        tax2amount: 0,
        tax2code: "",
        tax2id: 0,
        tax2rate: 0,
        taxableamount1: 0,
        taxableamount2: 0,
        taxableamount3: 0,
        taxableamtcode1: "",
        taxableamtcode2: "",
        taxableamtcode3: "",
        taxdate: "",
        taxformcode: "",
        taxformnumber: "",
        templateid: 30,
        termdiscount: 0,
        termid: 0,
        transactionid: 0,
        userid: 0,
        usesn: false,
        usetax1: false,
        usetax2: false,
        vendorid: "",
        warehouseid: 1,
        detpono: "",
        reqid: "",
        reqseq: "",
        detreqno: "",
        lastid: 0,
        currencyname: "",
      },
      templatedetails: [],
      attachment: [],
      downpayments: [],
      included: {},
    },
    dataTemplate: [],
    formatDate: formatDate,
    insert: true,
    judul: "Data Baru",
    modalTarikTransaksi: false,
    modalPemasok: false,
    hasChange: false,
  };
};
