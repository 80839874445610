export const initialState = (formatDate, moment, wipaccount) => {
  return {
    loadingTable_salinTransaksi: false,
    NotifAlert: {
      visible: false,
      title: null,
      description: null,
      cancelText: null,
      okText: null,
    },
    daftar: {
      akunJobCost: [],
      template: [],
    },
    body: {
      sndata: [],
      details: [
        {
          cost: 0,
          deptid: 1,
          description: "",
          itemid: 0,
          itemhistid: 0,
          itemno: "",
          itemtype: 0,
          itemovdesc: "",
          itemunit: "",
          mfdate: "",
          mfid: 0,
          projectid: 1,
          qtyold: 0,
          quantity: 0,
          seq: 0,
          snok: true,
          unitratio: 1,
          warehouseid: 0,
          qtysaatini: "",
        },
      ],
      gldetails: [
        {
          deptid: 1,
          glaccount: "",
          glamount: 0,
          gldate: "",
          mfid: 0,
          notes: "",
          projectid: 1,
          seq: 0,
        },
      ],
      master: {
        overbudget: true,
        accountamount: 0,
        approved: false,
        approvedesc: null,
        checknote: false,
        deptid: 0,
        description: "",
        finished: false,
        follow: "",
        followup: false,
        urgent: false,
        glhistid: 0,
        glperiod: 0,
        glyear: 0,
        itemamount: 0,
        idprev: 0,
        idnext: 0,
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
        lastid: 0,
        locked_by: "",
        locked_time: "",
        mfid: 0,
        mfno: "",
        noteid: 0,
        projectid: 0,
        sheetdate: moment().format(formatDate),
        templateid: 18,
        userid: 0,
        usesn: false,
        warehouseid: 0,
        wipaccount: wipaccount,
        wipamount: 0,
        recdetid: 0,
      },
      templatedetails: [],
      modal_finishing: false,
      validation: [],
    },
    insert: true,
    judul: "Data Baru",
    hasChange: false,
  };
};
