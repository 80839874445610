export const initialState = (formatDate, moment, key) => {
  return {
    modalExchangerate: false,
    fromFaktur: false,
    errorPage: null,
    loadingTable_transaksi: false,
    loadingTable_barang: false,
    loadingTable_pemasok: false,
    loading_save: false,
    fetching_pemasok: false,
    fetching_bank: false,
    loading: {
      saveExit: false,
      next: false,
      previous: false,
      jurnal: false,
      pratinjau: false,
    },
    validation: [],
    daftar: {
      pemasok: [],
      akun: [],
    },
    dataTarikan: [],
    tipeTarikan: "xx",
    total: {
      subtotal: 0,
    },
    modalTarikTransaksi: false,
    modalPemasok: false,
    modalSimpanTransaksi: false,
    modalSalinTransaksi: false,
    getBank: [],
    body: {
      datadisc: [
        {
          accountname: "",
          chequeid: "",
          deptid: "",
          description: "",
          deptname: "",
          discaccount: "",
          discount: "",
          glaccount: "",
          invchqid: "",
          projectid: "",
          projectname: "",
          seq: "",
        },
      ],
      details: [
        {
          apinvoiceid: "",
          chequeid: "",
          deptid: 1,
          discaccount: 1,
          discount: "",
          paymentamount: "",
          pph23amount: "",
          pph23fiscalrate: "",
          pph23number: "",
          pph23rate: "",
          projectid: 1,
          seq: "",
          taxpph23: "",
          bayar: false,
        },
      ],
      master: {
        saldo: 300000000,
        approved: false,
        approvedesc: null,
        bankaccnt: "",
        bankbalance: 0,
        checknote: true,
        cheqno: "",
        chequeamount: "",
        nilaicek: 0,
        chequedate: moment().format(formatDate),
        chequeid: 0,
        chequeno: "",
        deptid: 1,
        description: "",
        discountamount: 0,
        fiscalpmt: false,
        fiscalpmt_disabled: true,
        follow: "",
        followup: false,
        urgent: false,
        glhistid: 0,
        glperiod: 0,
        glyear: 0,
        idnext: 0,
        idprev: 0,
        imported: 0,
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
        locked_by: "",
        locked_time: "",
        noform: "",
        noteid: 0,
        overbudget: true,
        payee: "",
        paymentdate: moment().format(formatDate),
        posted: true,
        projectid: 1,
        rate: 1,
        reconciled: true,
        reconcileid: 0,
        sequenceno: "",
        templateid: 20,
        userid: 0,
        vendorid: "",
        voided: false,
        lastid: 0,
      },
      templatedetails: [],
      attachment: [],
    },
    insert: true,
    judul: "Data Baru",
    personData: {},
    idkey: key,
    dataTemplate: [],
    data: {
      project: [],
      department: [],
    },
    hasChange: false,
  };
};
