export const initialState = (formatDate, moment) => {
  return {
    NotifAlert: {
      visible: false,
      title: null,
      description: null,
      cancelText: null,
      okText: null,
    },
    NotifAlertPiutang: {
      visible: false,
      title: null,
      description: null,
      cancelText: null,
      okText: null,
    },
    ConfirmTarik: {
      visible: false,
      title: null,
      description: null,
      cancelText: null,
      okText: null,
    },
    visible: false,
    tabPane: "1",
    modalExchangerate: false,
    modalSimpanTransaksi: false,
    modalSalinTransaksi: false,
    errorPage: null,
    validation: [],
    insert: true,
    loadingTable_salinTransaksi: false,
    loading: {
      loadingFetching_pelanggan: false,
      loadingTable_transaksi: false,
      saveExit: false,
      next: false,
      previous: false,
    },
    penawaranPenjualan: [],
    modalPelanggan: false,
    modalTarikTransaksi: false,
    personData: {},
    daftar: {
      pelanggan: [],
      penjual: [],
      fob: [
        { id: 1, name: "Shipping Point" },
        { id: 2, name: "Destination" },
      ],
      terms: [],
      shipment: [],
    },
    total: {
      subtotal: 0,
      diskon: 0,
      ppn: 0.1,
      pajak2: 0,
      freight: 0,
      jumlah: 0,
    },
    body: {
      details: [
        {
          brutounitprice: 0,
          closed: false,
          deptid: 1,
          detquoteno: "",
          detsono: "",
          itemdiscpc: "",
          groupno: "",
          groupseq: 0,
          itemno: "",
          itemtype: 0,
          itemovdesc: "",
          itemreserved1: "",
          itemreserved10: "",
          itemreserved11: "",
          itemreserved12: "",
          itemreserved13: "",
          itemreserved14: "",
          itemreserved15: "",
          itemreserved16: "",
          itemreserved17: "",
          itemreserved18: "",
          itemreserved19: "",
          itemreserved2: "",
          itemreserved20: "",
          itemreserved3: "",
          itemreserved4: "",
          itemreserved5: "",
          itemreserved6: "",
          itemreserved7: "",
          itemreserved8: "",
          itemreserved9: "",
          itemunit: "",
          jumlah: "",
          projectid: 1,
          qtyshipped: 0,
          quantity: "",
          quoteid: 0,
          quoteseq: 0,
          seq: 1,
          soid: 0,
          taxableamount1: 0,
          taxableamount2: 0,
          taxcodes: "",
          unitprice: 0,
          unitratio: 1,
          qtysaatini: "",
        },
      ],
      master: {
        approved: false,
        approvedesc: null,
        cashdiscount: 0,
        cashdiscpc: "",
        checknote: false,
        chequedate: "",
        chequeno: "",
        closed: false,
        customerid: null,
        customeristaxable: false,
        depositto: 0,
        description: "",
        dp: 0,
        dpaccount: 0,
        dpused: 0,
        estshipdate: null,
        fiscalrate: 0,
        fob: "",
        follow: "",
        urgent: false,
        followup: false,
        freight: 0,
        freightaccnt: 0,
        getfromsq: true,
        glhist: 0,
        glperiod: 0,
        glyear: 0,
        idnext: 0,
        idprev: 0,
        imported: 0,
        invamount: 0,
        link01: "",
        link02: "",
        link03: "",
        link04: "",
        link05: "",
        link06: "",
        link07: "",
        link08: "",
        link09: "",
        link10: "",
        link11: "",
        link12: "",
        link13: "",
        link14: "",
        link15: "",
        link16: "",
        link17: "",
        link18: "",
        link19: "",
        link20: "",
        locked_by: "",
        locked_time: "",
        noteid: 0,
        parentsoid: 0,
        pono: "",
        proceed: false,
        qtyrcv: 0,
        quatation: true,
        rate: 0,
        reconcileid: 0,
        roundedtax1amount: 0,
        roundedtax2amount: 0,
        salesmanid: null,
        shipid: null,
        shipto1: "",
        shipto2: "",
        shipto3: "",
        shipto4: "",
        shipto5: "",
        sodate: moment().format(formatDate),
        soid: 0,
        sono: "",
        status: "",
        tax1amount: 0,
        tax1code: "",
        tax1id: 0,
        tax1rate: 0,
        tax2amount: 0,
        tax2code: "",
        tax2id: 0,
        tax2rate: 0,
        taxableamount1: 0,
        taxableamount2: 0,
        taxableamount3: 0,
        taxableamtcode1: "",
        taxableamtcode2: "",
        taxableamtcode3: "",
        inclusivetax: false,
        templateid: 21,
        termid: null,
        transactionid: 0,
        userid: 0,
        lastid: 0,
        newdp: "",
        newdpused: "",
        currencyname: "",
      },
      templatedetails: [],
      attachment: [],
    },
    dataTemplate: [],
    hasChange: false,
  };
};
