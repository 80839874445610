export const DaftarAkun = (startDate) => {
  return {
    insert: true,
    detail: {
      balance: {
        value: "",
      },
      accountname: {
        value: "",
      },
      accountname2: {
        value: "",
      },
      accounttype: {
        value: "",
      },
      currencyid: {
        value: 1,
      },
      glaccount: {
        value: "",
      },
      memo: {
        value: "",
      },
      subaccount: {
        value: false,
      },
      isalloctoprod: {
        value: false,
      },
      parentaccount: {
        value: "",
      },
      suspended: {
        value: false,
      },
      glid: {
        value: 0,
      },
      obdate: {
        value: startDate,
      },
      openingbal: {
        value: 0,
      },
      detailuser: {
        value: [],
      },
      detailbranch: {
        value: [],
      },
      allusers: {
        value: true,
      },
      tipeAkun: [],
      currency: [],
      listSubAkun: [],
    },
    judul: "Data Baru",
    startdate: startDate,
  };
};
